export default {
  header: {
    hosting: "Hébergement",
    myTrip: "Mon voyage",
    inbox: "Boîte de réception",
    addExperience: "Ajouter expérience",
    blog:"Blog"
  },
  account: {
    verifyEmail:
      "Veuillez vérifier votre adresse e-mail ou demander un nouveau lien de vérification",
  },
  form:{
    invalidPrice:"prix invalide"
  },
  general:{
    traveler:"Voyageur",
    guest:"Invité",
    cityAmbassador:"Ambassadeur",
    host:"Hôte",
    required:"Requis",
    uploadCover:"Upload Cover",
    uploadLogo:"Upload Logo",
    Contact:"Contact",
    support:"Support",
    copywrites:"COPYRIGHT © 2021 TOUS DROITS RÉSERVÉS",
    reservedInformation:'Confidential information, will not be publicly visible',
    dragAndDrop:"Drag and drop some images here or click",
    "Ready to join us and share your passion?":"Prêt à nous rejoindre et à partager votre passion?",
    "Subscribe to our newsletter":"Abonnez-vous à notre newsletter"
  },
  helpCenter:{
    help_center:"CENTRE D'AIDE",
    welcome_to:"Bienvenue dans le centre d'aide de Tabaani",
    choose_category:"Please choose one category",
    HighlightedArticles:"Articles en surbrillance",
    "What are Tabaani experience standards and requirements?":"Quelles sont les normes et exigences relatives à l'expérience Tabaani?",
    good_morning:"Bonjour, en quoi pouvons-nous vous aider aujourd'hui ?"
  },
  tourtypes:{
    "Food Tour":"Visite gastronomique",
    "Walking Tour":"Visite à pied",
    "Day trips":"Excursions d'une journée",
    "Family Friendly":"Conviviale et familiale",
    "Night Tour":"Visite nocturne",
    "Bike Tour":"Tour à vélo",
  },
  experience: {
    chooseTourtype:"Veuillez choisir le type qui décrit le mieux votre expérience",
    pageTitle: "Experiences",
    duration:"Durée",
    price:"Prix",
    maxLoad:"Charge max",
    minAge:"âge min",
    title: "Titre",
    country:"pays",
    address: "Addresse",
    region: "Région",
    meetingPoint:"Point de rencontre",
    city: "Ville",
    admins: "Admins",
    description: "Description",
    goLive: "Passez en direct!",
    edit: "Éditer",
    linkFacebook: "Link to Facebook page",
    linkInstagram: "Link to Instagram page",
    website: "Website",
    phone: "Phone number",
    email: "Adresse e-mail",
    followUs: "Follow us on social networks",
    musicStyles: "Music styles",
    direction: "Get Direction",
    noClubs: "No clubs",
    noClubsFound: "No clubs found",
    verified: "Expérience certifiée",
    reservedInformation:
      "Confidential information, will not be publicly visible",
    importConsent: {
      "import-events": "Import events",
      "import-albums": "Import albums",
    },
    importConsentLabel:
      "By activating the automatic import functions, you consent to tilllate.world to automatically import events and / or photos from your facebook page to increase the range of free advertising for your events.",
    home: "Home",
    nextEvents: "Next Events",
    pastEvents: "Past Events",
    search: "Search for Clubs",
    nameVenue: "Nome club...",
    register: "Register your club now!",
  },
  home: "Accueil",
  what_is: "C'est quoi",
  free_platform:
    " c'est une plateforme gratuite qui responsabilise les locaux passionnés ",
  by_hosting:
    "en organisant une visite privée ou une expérience dans leur ville.",
  hosting: "Hébergement",
  our_mission_is: "notre mission est",
  connect_people: "connecter les gens aux cultures en abattant les barrières",
  connect_host: "Connectez-vous à certains des hôtes les mieux notés",
  between_travelers_locals:
    "entre les voyageurs et les habitants du monde entier",
  with_this_in_mind:
    "Dans cet esprit, la plate-forme Tabaani a été créée comme une place de marché qui facilite la connexion entre les Ambasadors locaux et les voyageurs du monde entier!",
    our_experiences: "Nos experiences",
    are_always:"sont toujours",
      Private_Personal: "Privé  & Personnel",
  private_description:
    " Pas d'étrangers, juste toi. Personnalisez entièrement votre voyage selon vos besoins.",
  with_a_Pasionate_Local: "Avec un local passionné",
  pasionate_description:
    "Des faits historiques aux réglementations sanitaires les plus récentes.",
  without_crowds: "Sans foule",
  crowds_description:
    "Des expériences conçues pour vous éloigner de la foule vers des lieux locaux authentiques.",
  discover: "Découvrez de nouvelles Destinations et villes avec",
  explore: "Explorez avec",
  shop: "Shop",
  collection: "Collection",
  pages: "Des pages",
  cart: "Chariot",
  checkout: "Checkout",
  wishlist: "souhaits",
  help: "Aide",
  help_center: "Centre d'aide",
  terms_conditions: "Termes et conditions",
  communauté: "Communauté",
  support: "Support",
  compare: "Comparer",
  about: "À propos",
  about_us: "À propos de nous",
  become_a_partner: "Devenir un partenaire",
  sign_in: "Connexion",
  sign_up: "Inscrivez-vous",
  Forgot_password: "Mot de passe oublié?",
  no_account: "Vous n'avez pas de compte?",
  login: "S'identifier",
  my_account: "Mon compte",
  dashbord: "tableau de bord",
  edit_profile: "Editer le profil",
  login_register: "Connexion / S'inscrire",
  contact_us: "Contactez nous",
  "404_page": "404 Page",
  blog: "Blog",
  book_authentic: "Réservez des expériences ",
  unique_experiences: "Uniques & Authentiques",
  children: "Enfants",
  people: "Personnes",
  adults: "Adultes",
  search: "Chercher",
  with: "Avec",
  IsOurPriority: "est notre priorité",
  yourSafety: "Votre sécurité",
  privateToursAndActivitiesWithLocals:
    "Visites privées et activités avec les locaux",
  where_are_you_going: "Où allez-vous !",
  meeting_point: "point de rencontre",
  contact_me: "Contactez moi",
  what_is_waiting_for_you: "Ce qui t'attend",
  active_experiences: "Experiences Actives",
  add_photos: "Ajouter des photos",
  make_money: "Gagnez de l'argent en faisant ce que vous aimez",
  meet_our_ambassadors: "Rencontrez nos ambassadeurs",
  all_of_our_experiences_are: "Toutes nos expériences sont",
  authentic: "Authentique",
  personalized: "Personnalisé",
  with_the_local_you_choose: "Avec le local que vous choisissez",
  tabaani_description:
    "Les vacances sont super et être touriste, c'est bien. Mais que se passe-t-il si nous voulons aller plus loin? Et si nous nous débarrassions des limites du tourisme de détail et expérimentions un lieu comme le font les locaux? Comment voyager quelque part et se laisser absorber le plus possible par la culture, tout en étant poli et respectueux?",
  finding_friendly_locals: "Trouver des locaux sympathiques",
  finding_friendly_locals_description:
    "La meilleure façon d'apprendre ce qu'il faut faire et voir dans un lieu est de se connecter avec les habitants. ils ont une bien meilleure idée de ce qui est amusant à faire, de ce qu'est un piège à touristes, où il y a de la bonne nourriture, et plus encore. Ils partageront des informations sur leur culture d'une manière qui ne deviendra peut-être pas apparente si vous visitez simplement un lieu, surtout si vous voyagez avec eux.",
  good_news: "Bonnes nouvelles!",
  friendly_locals: "Il y a des habitants sympathiques partout avec Tabaani",
  tabaani_descp: [
    "Expériences de toutes sortes, comme la cuisine, le croquis, le camping et plus encore. ",
    "Il n'y a pas de limite à la passion et à ce que vous pouvez faire. ",
    "Explorez ces catégories en vedette",
  ],
  activities: [
    "Culture & histoire",
    "Nourriture & boisson",
    "Nature & plein air",
  ],
  activities_descp: [
    "Partagez l'histoire des monuments célèbres de votre ville.",
    "Organisez une visite gastronomique, un cours de cuisine, une expérience culinaire et plus encore.",
    "Dirigez des randonnées dans la nature, des sports nautiques, des activités de montagne et plus encore.",
  ],
  _commennt: "becomeHostPage",
  experience_descp:
    "C’est une activité qui va au-delà de la visite ou du cours typique, conçue et animée par des habitants comme vous. Montrez votre ville, votre artisanat, votre cause ou votre culture en organisant une expérience.",
  share: [
    "Partagez ce que vous savez",
    "Des expériences de toutes sortes, comme la cuisine, le croquis, le camping, etc. Il n'y a pas de limite à la passion et à ce que vous pouvez faire. Explorez ces catégories en vedette.",
  ],
  benefits_title: "Avantages d'être un ambassadeur de la ville",
  benefits: [
    "Réseauter avec des gens comme vous",
    "Vivez votre passion et partagez-la!",
  ],
  benefits_descp: [
    "Gagnez de l'argent en faisant ce que vous aimez",
    "Si vous aimez votre ville, pourquoi ne pas la partager avec le reste du monde? Mieux encore, pourquoi ne pas gagner de l'argent en le faisant. Ici, vous définissez votre propre prix.",
    "Rencontrez des gens du monde entier qui partagent les mêmes passions et intérêts que vous. Créez des souvenirs pour une vie et de nouvelles connexions.",
    "Créez une expérience unique, d'un cours de cuisine à une visite hors des sentiers battus ou à un atelier de danse. Le choix t'appartient!",
  ],
  host: "Organisez une expérience",
  host_descp:
    "D'un service en ligne à une expérience hors ligne unique, conçue par vous. se déconnecter du numérique et se reconnecter aux voyageurs via votre ville",
  start: "Commençons!",
  start_now: "Commençons maintenant!",
  passion_share: "Partagez votre passion et votre amour pour votre ville avec",
  create_steps: [
    "Créez votre compte Tabaani",
    "Répondez à un questionnaire simple",
    "Nous serons en contact",
  ],
  create_descp: [
    "Inscrivez-vous et configurez votre profil. Vous avez déjà un compte? Commençons ensuite avec votre application hôte.",
    "Nous voulons en savoir plus sur vous. Cela nous aidera à avoir une idée de ce qui vous passionne vraiment.",
    "Envoyez votre questionnaire et c'est tout! Une fois votre candidature examinée, notre équipe vous contactera",
  ],
  users: "Utilisateurs",
  more_orders: "Voir plus de commandes",
  how_to_be_partner: "Comment être partenaire",
  forbidden: "interdit",
  access_denied: "Oh oh! Vous ne pouvez pas accéder à cette page.",
  not_found: ["Nous n'arrivons pas à trouver la page", "que vous recherchez."],
  instead: "Voici quelques liens utiles à la place:",
  error: "Error",
  sorry: "Désolé! Nous venons de rencontrer une erreur inattendue.",
  travelling_with: "Voyager avec",
  hosting_in: "Hébergement activé",
  privacy_policy: "Politique de confidentialité",
  privacy_descp: [
    "Votre vie privée est importante pour nous. La politique de Tabaani est de respecter votre vie privée en ce qui concerne les informations que nous pouvons collecter auprès de vous sur notre site Web, http://www.tabaani.com, et sur les autres sites que nous possédons et exploitons.",
    "Nous ne demandons des informations personnelles que lorsque nous en avons vraiment besoin pour vous fournir un service. Nous les collectons par des moyens équitables et licites, à votre connaissance et avec votre consentement. Nous vous faisons également savoir pourquoi nous les collectons et comment ils seront utilisés. ",
    "Nous ne conservons les informations collectées que le temps nécessaire pour vous fournir le service demandé. Les données que nous stockons, nous les protégerons selon des moyens commercialement acceptables pour empêcher la perte et le vol, ainsi que l'accès non autorisé, la divulgation, la copie, l'utilisation ou modification.",
    "Nous ne partageons aucune information d'identification personnelle publiquement ou avec des tiers, sauf lorsque la loi l'exige.",
    "Notre site Web peut créer des liens vers des sites externes que nous ne gérons pas. Veuillez noter que nous n'avons aucun contrôle sur le contenu et les pratiques de ces sites et que nous ne pouvons accepter la responsabilité de leurs politiques de confidentialité respectives.",
    "Vous êtes libre de refuser notre demande d'informations personnelles, étant entendu que nous pourrions ne pas être en mesure de vous fournir certains des services souhaités.",
    "Votre utilisation continue de notre site Web sera considérée comme une acceptation de nos pratiques en matière de confidentialité et d'informations personnelles. Si vous avez des questions sur la manière dont nous traitons les données utilisateur et les informations personnelles, n'hésitez pas à nous contacter.",
    "Cette politique est effective à compter du 1er janvier 2021",
    "En accédant au site Web à l'adresse http://www.tabaani.com, vous acceptez d'être lié par ces conditions d'utilisation, toutes les lois et réglementations applicables, et acceptez que vous êtes responsable du respect des lois locales applicables. Si vous n'acceptez aucune de ces conditions, il vous est interdit d'utiliser ou d'accéder à ce site. Les éléments contenus dans ce site sont protégés par le droit d'auteur et le droit des marques applicables. ",
    "La permission est accordée de télécharger temporairement une copie du matériel (informations ou logiciel) sur le site Web de Tabaani à des fins personnelles et non commerciales de visualisation transitoire uniquement. Il s'agit de l'octroi d'une licence, pas d'un transfert de titre, et sous cette licence, vous ne pouvez pas: ",
    "modifier ou copier le matériel",
    "utiliser le matériel à des fins commerciales ou pour tout affichage public (commercial ou non commercial)",
    "tenter de décompiler ou de désosser tout logiciel contenu sur le site Web de Tabaani",
    "supprimer tout droit d'auteur ou toute autre mention de propriété du matériel; ou transférer le matériel à une autre personne ou dupliquer le matériel sur tout autre serveur.",
    "Cette licence sera automatiquement résiliée si vous violez l'une de ces restrictions et peut être résiliée par Tabaani à tout moment. À la fin de votre visualisation de ces documents ou à la résiliation de cette licence, vous devez détruire tout matériel téléchargé en votre possession que ce soit sous forme électronique ou format imprimé. ",
    "Le matériel sur le site Web de Tabaani est fourni comme si sur une base. Tabaani ne donne aucune garantie, expresse ou implicite, et par la présente rejette et annule toutes les autres garanties, y compris, sans limitation, les garanties implicites ou conditions de qualité marchande, d'adéquation à un usage particulier ou de non-violation de la propriété intellectuelle ou autre violation des droits. ",
    "En outre, Tabaani ne garantit ni ne fait aucune représentation concernant l'exactitude, les résultats probables ou la fiabilité de l'utilisation des matériaux sur son site Web ou autrement liés à ces matériaux ou sur tout site lié à ce site.",
    "En aucun cas Tabaani ou ses fournisseurs ne seront responsables de tout dommage (y compris, sans limitation, des dommages pour perte de données ou de profit, ou en raison d'une interruption d'activité) résultant de l'utilisation ou de l'incapacité d'utiliser les matériaux sur le site Web de Tabaani, même si Tabaani ou un représentant autorisé de Tabaani a été informé oralement ou par écrit de la possibilité de tels dommages. Étant donné que certaines juridictions n'autorisent pas les limitations de garanties implicites ou les limitations de responsabilité pour les dommages indirects ou accessoires, ces limitations peuvent ne pas s'appliquer à vous. ",
    "Les documents apparaissant sur le site Web de Tabaani peuvent inclure des erreurs techniques, typographiques ou photographiques. Tabaani ne garantit pas que les documents de son site Web sont exacts, complets ou à jour. Tabaani peut apporter des modifications aux matériaux contenus sur son site Web à tout moment. sans préavis. Cependant, Tabaani ne prend aucun engagement de mettre à jour le matériel. ",
    "Tabaani n'a pas examiné tous les sites liés à son site Web et n'est pas responsable du contenu de ces sites liés. L'inclusion de tout lien n'implique pas l'approbation par Tabaani du site. L'utilisation d'un tel site Web lié se fait au aux risques et périls de l'utilisateur. ",
    "Tabaani peut réviser ces conditions d'utilisation de son site Web à tout moment sans préavis. En utilisant ce site Web, vous acceptez d'être lié par la version alors en vigueur de ces conditions d'utilisation.",
    "Ces termes et conditions sont régis et interprétés conformément aux lois tunisiennes et vous vous soumettez irrévocablement à la compétence exclusive des tribunaux de cet État ou de cet endroit.",
  ],
  terms: " Conditions",
  and: " et ",
  you: "Toi",
  interpretation: "Interprétation",
  definitions: "Définitions",
  affiliate: "Affilié",
  country: "Pays",
  company: "Entreprise",
  device: "Dispositif",
  service: "Service",
  generator: "Générateur",
  refers_to: "fait référence à:",
  third_party: "Service de médias sociaux tiers",
  website: "Site Web",
  accessible_from: "accessible depuis ",
  acknowledgment: "Reconnaissance",
  tunisia: "Tunisie",
  termination: "Résiliation",
  limitation_liability: "Limitation de responsabilité",
  as_is: "'TEL QUEL' et 'TEL QUE DISPONIBLE' Clause de non-responsabilité",
  terms_descp: [
    "Dernière mise à jour: 12 janvier 2021",
    "Veuillez lire attentivement ces termes et conditions avant d'utiliser notre service.",
    "Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes. Les définitions suivantes ont la même signification, qu'elles apparaissent au singulier ou au pluriel.",
    "Aux fins des présentes conditions générales:",
    "désigne une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une partie, où 'contrôle' signifie la détention de 50% ou plus des actions, participations ou autres titres habilités à voter pour l'élection des administrateurs ou d'une autre autorité de gestion.",
    "(dénommée 'la société', 'nous', 'notre' ou 'notre' dans le présent contrat)",
    "désigne tout appareil pouvant accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique.",
    "(également dénommées «Conditions») désignent les présentes Conditions générales qui forment l'intégralité de l'accord entre Vous et la Société concernant l'utilisation du Service. Cet accord de conditions générales a été créé avec l'aide du ",
    "désigne tout service ou contenu (y compris les données, informations, produits ou services) fournis par un tiers qui peuvent être affichés, inclus ou mis à disposition par le Service.",
    "désigne la personne accédant ou utilisant le Service, ou la société, ou toute autre entité juridique au nom de laquelle cette personne accède ou utilise le Service, selon le cas.",
    "Ce sont les termes et conditions régissant l'utilisation de ce service et l'accord qui fonctionne entre vous et la société. Les présentes conditions générales définissent les droits et obligations de tous les utilisateurs concernant l'utilisation du service.",
    "Votre accès et votre utilisation du service sont conditionnés par votre acceptation et votre respect des présentes conditions générales. Les présentes conditions générales s'appliquent à tous les visiteurs, utilisateurs et autres personnes qui accèdent ou utilisent le service. ",
    "En accédant ou en utilisant le service, vous acceptez d'être lié par ces conditions générales. Si vous n'êtes pas d'accord avec une partie de ces conditions générales, vous ne pouvez pas accéder au service.",
    "Vous déclarez que vous avez plus de 18 ans. La Société ne permet pas aux moins de 18 ans d'utiliser le Service.",
    "Votre accès et votre utilisation du service sont également conditionnés par votre acceptation et votre respect de la politique de confidentialité de la société. Notre politique de confidentialité décrit nos politiques et procédures sur la collecte, l'utilisation et la divulgation de vos informations personnelles lorsque vous utilisez l'application ou le site Web et vous informe de vos droits à la confidentialité et de la manière dont la loi vous protège. Veuillez lire attentivement notre politique de confidentialité avant d'utiliser notre service.",
    "Liens vers d'autres sites Web",
    "Notre service peut contenir des liens vers des sites Web tiers ou des services qui ne sont pas détenus ou contrôlés par la société.",
    "La Société n'a aucun contrôle sur, et n'assume aucune responsabilité pour, le contenu, les politiques de confidentialité ou les pratiques de tout site Web ou service de tiers. Vous reconnaissez et acceptez en outre que la Société ne sera pas responsable, directement ou indirectement, pour tout dommage ou perte causé ou prétendument causé par ou en relation avec l'utilisation ou la confiance en ces contenus, biens ou services disponibles sur ou via ces sites Web ou services. ",
    "Nous vous conseillons vivement de lire les conditions générales et les politiques de confidentialité de tout site Web ou service tiers que vous visitez.",
    "Nous pouvons résilier ou suspendre votre accès immédiatement, sans préavis ni responsabilité, pour quelque raison que ce soit, y compris, sans limitation, si vous enfreignez les présentes conditions générales.",
    "En cas de résiliation, votre droit d'utiliser le Service cessera immédiatement.",
    "Sans aucun dommage que Vous pourriez subir, l'entière responsabilité de la Société et de l'un de ses fournisseurs en vertu de toute disposition des présentes Conditions et Votre recours exclusif pour tout ce qui précède sera limité au montant réellement payé par Vous via le Service ou 100 USD si vous n'avez rien acheté via le service. ",
    "Dans la mesure maximale autorisée par la loi applicable, en aucun cas la Société ou ses fournisseurs seront-ils responsables de tout dommages accessoires, indirects ou consécutifs de quelque nature que ce soit (y compris, mais sans s'y limiter, les dommages-intérêts pour perte de profits, perte de données ou d'autres informations, pour interruption d'activité, pour blessures personnelles, perte de la vie privée découlant de ou de quelque manière que ce soit lié à l'utilisation ou à l'incapacité d'utiliser le Service, un tiers logiciel et / ou matériel tiers utilisé avec le Service, ou autrement dans le cadre de toute disposition des présentes Conditions), même si la Société ou tout fournisseur a été informé de la possibilité de tels dommages et même si le recours échoue à son objectif essentiel.",
    "Certains états n'autorisent pas l'exclusion des garanties implicites ou la limitation de responsabilité pour les dommages accessoires ou consécutifs, ce qui signifie que certaines des limitations ci-dessus peuvent ne pas s'appliquer. Dans ces États, la responsabilité de chaque partie sera limitée dans la plus grande mesure permise par la loi.",
    "Le Service vous est fourni «TEL QUEL» et «TEL QUE DISPONIBLE» et avec tous les défauts et défauts sans garantie d'aucune sorte. Dans la mesure maximale autorisée par la loi applicable, la Société, en son nom propre et au nom de ses affiliés et de ses concédants de licence et fournisseurs de services respectifs, décline expressément toute garantie, expresse, implicite, statutaire ou autre, en ce qui concerne le Service, y compris toutes les garanties implicites de qualité marchande, d'adéquation à un usage particulier, de titre et de non-contrefaçon, et les garanties pouvant découler de la négociation, du cours des performances, de l'utilisation ou des pratiques commerciales. Sans limitation de ce qui précède, la Société ne fournit aucune garantie ou engagement, et ne fait aucune représentation de quelque nature que ce soit que le Service répondra à vos exigences, atteindra les résultats escomptés, sera compatible ou fonctionnera avec tout autre logiciel, application, système ou service, fonctionnera sans interruption, répondre à toutes les normes de performance ou de fiabilité ou être sans erreur ou que toute erreur ou défaut peut ou sera corrigé.",
    "Sans limiter ce qui précède, ni la société ni aucun des fournisseurs de la société ne font de représentation ou de garantie de quelque nature que ce soit, expresse ou implicite: (i) quant au fonctionnement ou à la disponibilité du service, ou aux informations, contenus, matériels ou produits inclus dessus; (ii) que le Service sera ininterrompu ou sans erreur; (iii) quant à l'exactitude, la fiabilité ou l'actualité de toute information ou contenu fourni via le Service; ou (iv) que le Service, ses serveurs, le contenu ou les e-mails envoyés par ou au nom de la Société sont exempts de virus, scripts, chevaux de Troie, vers, logiciels malveillants, bombes à retardement ou autres composants nuisibles.",
    "Certaines juridictions n'autorisent pas l'exclusion de certains types de garanties ou de limitations des droits statutaires applicables d'un consommateur, de sorte que certaines ou toutes les exclusions et limitations ci-dessus peuvent ne pas s'appliquer à Vous. Mais dans un tel cas, les exclusions et limitations énoncées dans cette section seront appliquées dans la plus grande mesure possible en vertu de la loi applicable.",
    "Les lois du pays, à l'exclusion de ses règles de conflit de lois, régiront les présentes conditions et votre utilisation du service. Votre utilisation de l'Application peut également être soumise à d'autres lois locales, régionales, nationales ou internationales.",
    "Si vous avez des inquiétudes ou des différends concernant le service, vous acceptez d'essayer d'abord de résoudre le différend de manière informelle en contactant la société.",
    "Si vous êtes un consommateur de l'Union européenne, vous bénéficierez de toutes les dispositions obligatoires de la loi du pays dans lequel vous résidez.",
    "Sie versichern und garantieren, dass (i) Sie sich nicht in einem Land befinden, das dem Embargo der US-Regierung unterliegt oder das von der US-Regierung als 'terroristisch unterstützendes' Land ausgewiesen wurde, und (ii) Sie nicht aufgeführt auf einer Liste der verbotenen oder eingeschränkten Parteien der US-Regierung.",
    "Si une disposition de ces Conditions est jugée inapplicable ou invalide, cette disposition sera modifiée et interprétée pour atteindre les objectifs de cette disposition dans la mesure du possible en vertu de la loi applicable et les dispositions restantes resteront pleinement en vigueur.",
    "Sofern hierin nichts anderes bestimmt ist, hat das Versäumnis, ein Recht auszuüben oder die Erfüllung einer Verpflichtung gemäß diesen Bedingungen zu verlangen, keine Auswirkungen auf die Fähigkeit einer Partei, dieses Recht auszuüben oder diese Erfüllung zu einem späteren Zeitpunkt zu verlangen, und der Verzicht auf einen Verstoß stellt keinen Verzicht dar eines späteren Verstoßes.",
    "Ces termes et conditions peuvent avoir été traduits si nous les avons mis à votre disposition sur notre service. Vous acceptez que le texte anglais original prévaudra en cas de litige.",
    "Nous nous réservons le droit, à notre seule discrétion, de modifier ou de remplacer ces conditions à tout moment. Si une révision est importante, nous ferons des efforts raisonnables pour fournir un préavis d'au moins 30 jours avant l'entrée en vigueur de toute nouvelle condition. Ce qui constitue un changement important sera déterminé à notre seule discrétion",
    "En continuant à accéder ou à utiliser notre service après l'entrée en vigueur de ces révisions, vous acceptez d'être lié par les conditions révisées. Si vous n'acceptez pas les nouvelles conditions, en tout ou en partie, veuillez cesser d'utiliser le site Web et le service.",
    "Si vous avez des questions sur ces Termes et Conditions, vous pouvez nous contacter:",
  ],
  use_license: "Utiliser la licence",
  disclaimer: "Avertissement",
  limitations: "Limites",
  accuracy_materials: "Précision des matériaux",
  links: "Liens",
  modifications: "Modifications",
  governing_law: "Loi applicable",
  disputes: "Résolution des litiges",
  european_union: "Pour les utilisateurs de l'Union européenne (UE)",
  united_states: "United States Legal Compliance",
  severability: "Divisibilité",
  waiver: "Renonciation",
  translation_interp: "Interprétation de la traduction",
  changes_to_these: "Modifications de ces ",
  by_email: "Par email: ",
  become_a_host: "Devenez hôte",
  please_apply: "Veuillez d'abord postuler pour devenir hôte",
  click: "Cliquez ici pour postuler",
  more_details: "Plus de détails:",
  max_load: "Charge maximale:",
  welcome: "Bienvenue",
  price_person: "Prix par personne",
  back: "Retour",
  finish: "Terminer",
  add_more: "Ajouter plus",
  next: "Suivant",
  steps_completed: "Toutes les étapes sont terminées - Vous avez terminé",
  reset: "Réinitialiser",
  please_choose:
    "Veuillez choisir le type qui décrit le mieux votre expérience",
  food_tour: "Visites gastronomiques",
  walking_tour: "Visite à pied",
  day_trips: "Excursions d'une journée",
  family_friendly: "Familial",
  night_tour: "Visite nocturne",
  bike_tour: "Tour à vélo",
  add_reasons: "Ajoutez au moins 3 raisons de réserver cette expérience",
  included: "Qu'est-ce qui est inclus dans l'expérience?",
  upload_cover_photo: "Télécharger la photo de couverture",
  accept_terms: "Accepter les conditions",
  create_exp: "Créer une expérience",
  when_using: "Lors de l'utilisation de",
  you_accept: "Vous acceptez notre",
  apply_host: "Veuillez d'abord postuler pour devenir hôte",
  apply: "cliquez ici pour postuler",
  i_live: "Je vis à",
  i_speak: "Je parle",
  verified: "Vérifié",
  local_host: "Hôte local",
  response_time: "Temps de réponse",
  send_message: "Envoyer un message",
  cancel: "Annuler",
  send: "Envoyer",
  upload_video: "Télécharger ou modifier votre vidéo de couverture",
  save: "Enregistrer",
  book_offer: "Réservez l'une de mes offres",
  Travellers: "Voyageurs",
  orders: "Ordres",
  add_experience: "Ajouter Expérience",
  edit_your_description: "Modifiez votre description",
  Explore_with_a_local_of_your_choice: "Explorez avec un local de votre choix",
  "Food tours": "Visite gastronomiques",
  "Walking tours": "Visite à pied",
  "Road trip": "Visite sur Route",
  "Family Friendly": "Visite Familiale",
  "Bike tour": "Tour à vélo",
  "Night life": "Vie nocturne",
  "Share the story behind famous landmarks in your":
    "Partagez l'histoire de monuments célèbres dans votre",
  city: "ville",
  "Host a food tour, cooking class, dining":
    "Organisez une visite gastronomique, un cours de cuisine, un dîner",
  "experience, and more.": "expérience, et plus encore.",
  "Lead nature hikes, water sports, mountain":
    "Mener des randonnées nature, sports nautiques, montagne",
  "activities, and more.": "activités, et plus encore.",
  "Become a Host Now": "Devenez hôte maintenant",
  "Make money sharing": "Gagnez de l'argent en",
  "your passion": "partageant ta passion",
  countries:{
    Algeria : "Algérie",
    Angola : "Angola",
    "Saint Helena":"Saint Helena",
    Anguilla:"Anguilla",
    'Alland Islands':"Alland Islands",
    Aruba:"Aruba",
    Benin : "Bénin",
    Bermuda:"Bermuda",
    'Brunei Darussalam':"Brunei Darussalam",
    Botswana : "Botswana",
    'Bouvet Island':"Île Bouvet",
    'Burkina Faso' : "Burkina Faso",
    Burundi : "Burundi",
    Cameroon : "Cameroun",
    'Cape Verde' : "Cap vert",
    'Central African Republic' : "République centrafricaine",
    Chad : "Tchad",
    'Cocos (Keeling) Islands':"Îles Cocos (Keeling)",
    Comoros : "Comores",
    'Cook Islands':"les Îles Cook",
    Curacao:"Curacao",
    'Christmas Island':"L'île de noël",
    'Congo, Democratic Republic' : "Congo, République démocratique",
    'Congo, Republic' : "Congo, République",
    Djibouti : "Djibouti",
    Egypt : "Egypte",
    'Equatorial Guinea' : "Guinée équatoriale",
    Eritrea : "Erythree",
    Ethiopia : "Ethiopie",
    'Faroe Islands':"Îles Féroé",
    Gabon : "Gabon",
    Guernsey:"Guernsey",
    Gambia : "Gambie",
    Ghana : "Ghana",
    Guinea : "Guinée",
    Gibraltar:"Gibraltar",
    Greenland:"Greenland",
    Guadeloupe:"Guadeloupe",
    Guam:"Guam",
    'Hong Kong':"Hong Kong",
    'Heard Island and McDonald Islands':"Heard Island and McDonald Islands",
    'Isle of Man':"Isle of Man",
    'South Georgia and the South Sandwich Islands':"Géorgie du Sud et îles Sandwich du Sud",
    'French Guiana':"French Guiana",
    'Guinea-Bissau' : "Guinée-Bissau",
    'Ivory Coast' : "Côte d’Ivoire",
    Kenya : "Kenya",
    Lesotho : "Lesotho",
    Liberia : "Liberia",
    Libya : "Libye",
    Madagascar : "Madagascar",
    Malawi : "Malawi",
    Mali : "Mali",
    Mauritania : "Mauritanie",
    Mauritius : "Ile Maurice",
    Morocco : "Maroc",
    Mozambique : "Mozambique",
    Namibia : "Namibie",
    Niger : "Niger",
    Nigeria : "Nigeria",
    Rwanda : "Rwanda",
    'Sao Tome and Principe' : "Sao Tomé-et-Principe",
    Senegal : "Sénégal",
    Seychelles : "Seychelles",
    'Sierra Leone': "Sierra Leone",
    Somalia : "Somalie",
    'South Africa': "Afrique du Sud",
    'South Sudan': "Soudan du Sud",
    Sudan : "Soudan",
    Swaziland : "Swaziland",
    Tanzania : "Tanzanie",
    Togo : "Togo",
    Tunisia : "Tunisie",
    Uganda : "Ouganda",
    Zambia : "Zambie",
    Zimbabwe : "Zimbabwe",
    Albania :" Albanie",
Andorra :" Andorre",
Armenia :" Arménie",
Austria :" Autriche",
Azerbaijan :" Azerbaijan",
Belarus :" Biélorussie",
Belgium :" Belgique",
'Bosnia and Herzegovina' :" Bosnie-Herzégovine",
Bulgaria :" Bulgarie",
Croatia :" Croatie",
Cyprus :" Chypre",
'Czech Republic':" République Tchèque",
'Western Sahara':"Sahara occidental",
'Falkland Islands (Malvinas)':"Îles Falkland (Malvinas)",
Denmark :" Danemark",
Estonia :" Estonie",
Finland :" Finlande",
France :" France",
Georgia :" Georgie",
Germany :" Allemagne",
Greece :" Grèce",
Hungary :" Hongrie",
Iceland :" Islande",
Ireland :" Irelande",
Italy :" Italie",
Latvia :" Lettonie",
Liechtenstein :" Liechtenstein",
Lithuania :" Lituanie",
Luxembourg :" Luxembourg",
Macedonia :" Macédoine",
Malta :" Malte",
Moldova :" Moldavie",
Monaco :" Monaco",
Montenegro :" Montenegro",
Netherlands :" Pays-Bas",
Norway :" Norvège",
Poland :" Pologne",
Portugal :" Portugal",
Romania :" Roumanie",
'San Marino' :" Saint-Marin",
Serbia :" Serbie",
Slovakia :" Slovaquie",
Slovenia :" Slovénie",
Spain :" Espagne",
'Saint Martin (French part)':"Saint Martin (partie française)",
Sweden :" Suède",
Switzerland :" Suisse",
Ukraine :" Ukraine",
'United Kingdom' :" Royaume-Uni",
'Vatican City':" Vatican",
'Antigua and Barbuda' :" Antigua-et-barbuda",
Bahamas :" Bahamas",
Barbados :" La Barbade",
'Saint Barthelemy':"Saint Barthelemy",
Belize :" Belize",
Canada :" Canada",
'Costa Rica':" Costa Rica",
Cuba :" Cuba",
Dominica :" Dominique",
'Dominican Republic' :" République dominicaine",
'El Salvador':" El Salvador",
Grenada :" Grenade",
Guatemala :" Guatemala",
Haiti :" Haiti",
Honduras :" Honduras",
Jamaica :" Jamaique",
Mexico :" Mexique",
Nicaragua :" Nicaragua",
Panama :" Paname",
'Saint Kitts and Nevis':" Saint-Christophe et Niévès",
'Saint Lucia':" Sainte Lucie",
'Saint Vincent and the Grenadines':" Saint Vincent et les Grenadines",
'Trinidad and Tobago':" Trinidad et Tobago",
'United States':" Les Etats Unis d'Amérique",
Argentina :" Argentine",
Bolivia :" Bolivie",
Brazil :" Brésil",
'British Indian Ocean Territory':"British Indian Ocean Territory",
Chile :" Chili",
Colombia :" Colombie",
Ecuador :" Equateur",
Guyana :" Guyane",
Paraguay :" Paraguay",
Peru :" Perou",
Suriname :" Suriname",
Uruguay :" Uruguay",
Venezuela :" Venezuela",
Afghanistan :" Afghanistan",
Bahrain :" Bahrein",
Bangladesh :" Bangladesh",
"Turks and Caicos Islands":"Turks and Caicos Islands",
"French Southern Territories":"French Southern Territories",
Tokelau:"Tokelau",
"Timor-Leste":"Timor-Leste",
"Taiwan, Province of China":"Taiwan, Province of China",
"United Republic of Tanzania":"United Republic of Tanzania",
"Holy See (Vatican City State)":"Holy See (Vatican City State)",
"British Virgin Islands":"British Virgin Islands",
"US Virgin Islands":"US Virgin Islands",
"Wallis and Futuna":"Wallis and Futuna",
"Kosovo":"Kosovo",
"Mayotte":"Mayotte",
Bhutan :" Bhoutan",
Brunei :" Brunei",
'Burma (Myanmar)':" Birmanie (Myanmar)",
Myanmar:"Myanmar",
Macao:"Macao",
'Northern Mariana Islands':"Northern Mariana Islands",
Martinique:"Martinique",
Montserrat:"Montserrat",
"New Caledonia":"New Caledonia",
"Norfolk Island":"Norfolk Island",
Niue:"Niue",
"French Polynesia":"French Polynesia",
"Saint Pierre and Miquelon":"Saint Pierre and Miquelon",
Pitcairn:"Pitcairn",
"Puerto Rico":"Puerto Rico",
Cambodia :" Cambodge",
China :" Chine",
'East Timor':" Timor oriental",
India :" Inde",
Indonesia :" Indonésie",
Iran :" Iran",
Iraq :" Irak",
Japan :" Japon",
Jersey:"Jersey",
Jordan :" Jordanie",
Kazakhstan :" Kazakhstan",
'Korea, North' :" Corée du Nord",
'Korea, South' :" Corée du Sud",
Kuwait :" Koweit",
Kyrgyzstan :" Kyrgyzstan",
"Korea, Democratic People's Republic of":"République populaire démocratique de Corée",
"Korea, Republic of":"Corée, République de",
'Cayman Islands':"Cayman Islands",
"Lao People's Democratic Republic":"Lao People's Democratic Republic",
Laos :" Laos",
Lebanon :" Liban",
Malaysia :" Malaysie",
Maldives :" Maldives",
Mongolia :" Mongolie",
Nepal :" Népal",
Oman :" Oman",
Pakistan :" Pakistan",
Palestine :"Palestine",
Philippines :" Philippines",
Qatar :" Quatar",
'Russian Federation':" Fédération Russe",
'Saudi Arabia':" Arabie Saoudite",
Singapore :" Singapour",
"Svalbard and Jan Mayen":"Svalbard and Jan Mayen",
"Sint Maarten (Dutch part)":"Sint Maarten (Dutch part)",
'Sri Lanka':" Sri Lanka",
Syria :" Syrie",
Tajikistan :" Tajikistan",
Thailand :" Thailande",
Turkey :" Turquie",
Turkmenistan :" Turkmenistan",
'United Arab Emirates' :" Emirats Arabes Unis",
Uzbekistan :" Ouzbekistan",
Vietnam :" Vietnam",
Yemen :" Yemen",
Australia :" Australie",
Fiji :" Fiji",
Kiribati :" République de Kiribati",
'Marshall Islands':" Iles Marshall",
Micronesia :" Micronésie",
Nauru :" Nauru",
'New Zealand':" Nouvelle Zélande",
Palau :" Palau",
'Papua New Guinea':" Papouasie Nouvelle Guinée",
Samoa :" Iles Samoa",
'Solomon Islands':" Iles Salomon",
Tonga :" Tonga",
Tuvalu :" Tuvalu",
Vanuatu :" Vanuatu",
      }
};
