import { alpha, makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { useContext } from 'react';
import { GlobalStyleContext } from '../../App';

export const useGlobalStyle = () =>
  makeStyles(theme => ({
    safeAreaBottom: {
      paddingBottom: 'env(safe-area-inset-bottom)',
    },
    safeAreaTop: {
      paddingTop: 'env(safe-area-inset-top)',
    },
    secondaryLight: {
      color: theme.palette.secondary.light,
    },
    relative: {
      position: 'relative',
    },
    absolute: {
      position: 'absolute',
    },
    overflowHidden: {
      overflow: 'hidden',
    },
    clickable: {
      cursor: 'pointer',
    },
    flex: {
      display: 'flex',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexStart: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    flexGrow: {
      flexGrow: 1,
    },
    flexCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    unbox: {
      marginRight: -16,
      marginLeft: -16,
      width: 'calc(100% + 32px)!important',
      maxWidth: 'calc(100% + 32px)!important',
    },
    textSmall: {
      fontSize: 12,
    },
    textLink: {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    preWrap: {
      whiteSpace: 'pre-wrap',
    },
    bold: {
      fontWeight: 800,
    },
    iconButton: {
      maxWidth: 35,
    },
    fullWidth: {
      width: '100%',
    },
    max100: {
      maxWidth: '100%',
    },
    size100: {
      width: '100%',
      height: '100%',
    },
    size80: {
      width: '80%',
      height: '80%',
    },
    size50: {
      width: '50%',
      height: '50%',
    },
    toTop: {
      zIndex: 99,
    },
    square: {
      height: 0,
      paddingTop: '50%',
      paddingBottom: '50%',
    },
    borderPrimary: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
    },
    bgLightOpaque: {
      backgroundColor: alpha(theme.palette.secondary.light, 0.7),
    },
    bgPrimary: {
      backgroundColor: theme.palette.primary.main,
    },
    bgSecondary: {
      backgroundColor: theme.palette.secondary.main,
      '--background': theme.palette.secondary.main,
    },
    bgSecondaryDark: {
      backgroundColor: theme.palette.secondary.dark,
      '--background': theme.palette.secondary.dark,
    },
    bgSecondaryLight: {
      backgroundColor: theme.palette.secondary.light,
      '--background': theme.palette.secondary.light,
    },
    rounded: {
      borderRadius: theme.spacing(0.5),
    },
    notRounded: {
      borderRadius: theme.spacing(0),
    },
    hidden: {
      display: 'none',
    },
    danger: {
      color: red[600],
    },
    ph2: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    m1: {
      margin: theme.spacing(1),
    },
    m2: {
      margin: theme.spacing(2),
    },
    m3: {
      margin: theme.spacing(3),
    },
    mv1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mv2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    mv3: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    mh1: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    mh2: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    mh3: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    mr1: {
      marginRight: theme.spacing(1),
    },
    mr2: {
      marginRight: theme.spacing(2),
    },
    mr3: {
      marginRight: theme.spacing(3),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    mt1: {
      marginTop: theme.spacing(1),
    },
    mt2: {
      marginTop: theme.spacing(2),
    },
    mt3: {
      marginTop: theme.spacing(3),
    },
    trans550: {
      transform: 'translateY(550px)',
    },
    capitalize:{
      textTransform:"capitalize"
    }
  }))();

export const useGlobalStyles = () => {
  const { globalStyle } = useContext(GlobalStyleContext);
  return globalStyle;
};
