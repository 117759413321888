import React from 'react';
import { List, WindowScroller } from 'react-virtualized';

type Props = {
  data: any[];
  renderItem: (item: any) => JSX.Element;
};

const VirtualizedList: React.FC<Props> = ({ data, renderItem }) => {
  const rowRenderer = ({ key, index, style }: any) => {
    return (
      <div style={style} key={key}>
        {renderItem(data[index])}
      </div>
    );
  };
  return (
    // @ts-ignore
    <WindowScroller>
      {({ isScrolling, onChildScroll, scrollTop }) => {
        return (
          // @ts-ignore
          <List
            autoHeight
            width={1}
            autoContainerWidth
            height={data.length * 200}
            rowCount={data.length}
            rowHeight={200}
            rowRenderer={rowRenderer}
            isScrolling={isScrolling}
            onScroll={onChildScroll}
            scrollTop={scrollTop}
            containerStyle={{
              width: '100%',
              maxWidth: '100%',
            }}
            style={{ width: '100%', outline: 'none' }}
          />
        );
      }}
    </WindowScroller>
  );
};

VirtualizedList.displayName = 'VirtualizedList';

export default React.memo(VirtualizedList);
