import {
  Box,
  Divider,
  alpha,
  makeStyles,
  Typography,
  TypographyVariant,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalStyles } from '../../../hooks';
import { Spacer } from '../../atoms';

type Props = {
  title: string;
  subtitle?: string;
  linkLabel?: string;
  linkUrl?: string;
  endIcon?: JSX.Element;
  variant?: 'light' | 'dark';
  subtitleVariant?: TypographyVariant;
  titleVariant?: TypographyVariant;
};

const useStyle = makeStyles(theme => ({
  title: {
    color: ({ variant }: any) => theme.palette.secondary[variant],
  },
  subtitle: {
    color: ({ variant }: any) => theme.palette.secondary[variant],
  },
  divider: {
    backgroundColor: ({ variant }: any) =>
    alpha(theme.palette.secondary[variant], 0.4),
  },
}));

const SectionHeader: React.FC<Props> = ({
  title,
  linkLabel,
  linkUrl,
  endIcon,
  subtitle,
  variant = 'dark',
  subtitleVariant = 'caption',
  titleVariant = 'h5',
}) => {
  const style = useStyle({ variant });
  const globalStyles = useGlobalStyles();
  return (
    <>
      <Box
        className={globalStyles.flex}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box>
          <Typography variant={titleVariant} className={style.title}>
            <b>{title}</b>
          </Typography>
        </Box>
        {linkUrl && (
          <Link to={linkUrl}>
            <Typography variant="body2" className={globalStyles.textLink}>
              {linkLabel || 'shared.showAll'}
            </Typography>
          </Link>
        )}
        {endIcon && endIcon}
      </Box>
      <Spacer height="8px" />
      <Divider className={style.divider} />
      <Spacer height="8px" />
      {subtitle && (
        <Typography variant={subtitleVariant} className={style.subtitle}>
          {subtitle}
        </Typography>
      )}
      <Spacer height="16px" />
    </>
  );
};

SectionHeader.displayName = 'SectionHeader';

export default React.memo(SectionHeader);
