import React, { useCallback, useMemo, useRef } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { Camera, Done, Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useAuthentification, useGlobalStyles, useMedia } from '../../../hooks';
import useStyles from './styles';

type Props = {
  visible: boolean;
  file?: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
};

const UpdateProfilePicture: React.FC<Props> = ({ visible, file, setFile }) => {
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { userId, updateProfile, profilePictures } = useAuthentification();
  const { uploadMedia, getMediaData } = useMedia();
  const classes = useStyles({ visible });

  const changeImage = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const confirmImage = useCallback(async () => {
    if (file) {
      const mediaRef = await uploadMedia({
        path: `/images/users/${userId}`,
        file,
      });
      const media = await getMediaData(mediaRef.path);

      if (media && media.url) {
        updateProfile({
          fbProfilePicture: media.url,
          profilePictures: [...profilePictures, mediaRef.id],
        });
      }
    }
    setFile(null);
    if (inputRef.current && inputRef.current.value) {
      inputRef.current.value = '';
    }
  }, [
    file,
    getMediaData,
    profilePictures,
    setFile,
    updateProfile,
    uploadMedia,
    userId,
  ]);

  const cancelImage = useCallback(() => {
    setFile(null);
    if (inputRef.current && inputRef.current.value) {
      inputRef.current.value = '';
    }
  }, [setFile]);

  const renderCta = useMemo(() => {
    if (!file) {
      return (
        <Button
          onClick={changeImage}
          fullWidth
          className={[globalStyle.absolute, classes.button].join(' ')}
          variant="contained"
          color="secondary"
          size="large"
        >
          <Camera /> {t('shared.edit')}
        </Button>
      );
    }
    return (
      <ButtonGroup
        variant="contained"
        className={[globalStyle.absolute, classes.buttonsGroup].join(' ')}
        size="large"
      >
        <Button
          onClick={confirmImage}
          fullWidth
          className={classes.confirm}
          variant="contained"
          color="secondary"
          size="large"
        >
          <Done /> {t('shared.confirm')}
        </Button>
        <Button
          onClick={cancelImage}
          fullWidth
          className={classes.cancel}
          variant="contained"
          color="secondary"
          size="large"
        >
          <Close /> {t('shared.cancel')}
        </Button>
      </ButtonGroup>
    );
  }, [
    cancelImage,
    changeImage,
    classes.button,
    classes.buttonsGroup,
    classes.cancel,
    classes.confirm,
    confirmImage,
    file,
    globalStyle.absolute,
    t,
  ]);
  return (
    <>
      {renderCta}
      <input
        type="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFile(e && e.target && e.target.files && e.target.files[0])
        }
        ref={inputRef}
        className={globalStyle.hidden}
      />
    </>
  );
};

UpdateProfilePicture.displayName = 'UpdateProfilePicture';

export default React.memo(UpdateProfilePicture);
