import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlogs } from 'hooks';
import { useExperiences } from 'hooks';
import defaultLogo from 'assets/img/logo.png';
import { sortObjectByValues, textShortener } from '../../utils';
import { HeaderSearchResult, SearchResult } from 'types';
import { useHosts } from 'hooks';

type UseSearch = {
  searchFunction: (
    text: string,
    collection: string,
  ) => { results: SearchResult[]; loaded: boolean };
  headerSearch: (text: string) => HeaderSearchResult[];
};

export const useSearch = (): UseSearch => {
  const { searchedExperiences, loaded: loadedExperiences } = useExperiences();
  const { searchedBlogs, loaded: loadedBlogs } = useBlogs();
  const { searchedHosts, loaded: loadedHosts } = useHosts();

  const { t } = useTranslation();

  const searchAll = useCallback(
    (text: string) => {
      const results: SearchResult[] = [
        ...mapSearchResults(searchedExperiences(text), 'experience', t('general.experience')),
        ...mapSearchResults(searchedBlogs(text), 'blog', t('general.blog')),
        ...mapSearchResults(searchedHosts(text),'host',t('general.host'),),
      ];

      return sortObjectByValues(results, 'name');
    },
    [
      searchedExperiences,
      searchedBlogs,
      searchedHosts,
      t,
    ],
  );


  const searchFunction = useCallback(
    (text: string, collection: string) => {
      const collectionList = {
        experience: searchedExperiences,
        blog: searchedBlogs,
        host: searchedHosts,
        all: searchAll,
      };
      const loadedStates = {
        experience: loadedExperiences,
        host: loadedHosts,
        blog:loadedBlogs,
        all:
        loadedBlogs &&
          loadedExperiences &&
          loadedHosts,
      };
      if (!collectionList[collection]) {
        return {
          results: [],
          loaded: true,
        };
      }
      return {
        results: collectionList[collection](text),
        loaded: loadedStates[collection],
      };
    },
    [
      searchedBlogs,
      searchedHosts,
      searchedExperiences,
      searchAll,
      loadedExperiences,
      loadedBlogs,
      loadedHosts,
    ],
  );

  const headerSearch = useCallback(
    (text: string) => {
      const results: HeaderSearchResult[] = [
        ...mapHeaderSearchResults(
          searchedExperiences(text),
          'experiences',
          t('general.experience'),
        ),
        ...mapHeaderSearchResults(
          searchedBlogs(text),
          'blogs',
          t('general.blog'),
        ),
        ...mapHeaderSearchResults(
          searchedHosts(text),
          'hosts',
          t('general.host'),
        ),
      ];

      let sortedResults = sortObjectByValues(results, 'name');
      if (sortedResults.length > 6) {
        sortedResults = sortedResults.slice(0, 7);
      }
      return sortedResults;
    },
    [searchedExperiences, searchedBlogs, searchedHosts, t],
  );

  return {
    headerSearch,
    searchFunction,
  };
};

const mapSearchResults = <
  T extends {
    name?: string;
    title?: string;
    slug: string;
    id?: string;
    description: string;
    logoUrl?: string;
    coverUrl?: string;
  }
>(
  results: T[],
  collection: string,
  type: string,
): SearchResult[] => {
  return results.map(result => {
    return {
      name: result?.name || result?.title || '',
      type,
      collection,
      ...result,
    };
  });
};

const mapHeaderSearchResults = <
  T extends {
    name: string;
    title: string;
    fullName: string;
    slug: string;
    id: string;
    logoUrl?: string;
    coverUrl?: string;
    picture?:string;
  }
>(
  results: T[],
  collection: string,
  type: string,
): HeaderSearchResult[] => {
  return results.map(result => {
    return {
      name: textShortener(result.name || result.title || result.fullName, 20),
      slug: result.id,
      logoUrl: result?.logoUrl
        ? result.logoUrl
        : result?.coverUrl
          ? result.coverUrl
          : result?.picture 
           ?result.picture[result.picture.length-1]
          :defaultLogo
          ,
      type,
      collection,
    };
  });
};
