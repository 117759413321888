import { useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
export const useHost = (props = {}) => {
  const { slugOrId } = props;
  const host = useSelector((state) => {
    return (
      slugOrId &&
      state.firestore?.ordered?.user &&
      state.firestore?.ordered?.user[0]
    );
  });
  const hostId = host?.id;
  useFirestoreConnect({ collection: "users", storeAs: "user", doc: slugOrId });
  return {
    host,
    hostId,
  };
};
