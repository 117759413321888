import { useFirestore } from "react-redux-firebase";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import firebase from "firebase/app";
import { useAuthentification } from "../auth";
import { RootState } from "redux/reducers/rootReducer";
import { Experience } from "types";
import { City } from "types/cities";
type Props ={
  name?:string;
}
export const useCities = (props:Props = {}) => {
  const firestore = useFirestore();
  const { userId } = useAuthentification();
  const { name } = props;
  const cities = useSelector<RootState>((state) => state.firestore?.ordered?.cities) as City[];
  const experiences =useSelector<RootState>((state) => state.firestore?.ordered?.experiencesbycity) as Experience[];

  const city = useSelector<RootState>(
    (state) =>
      name &&
      state.firestore?.ordered?.cities?.find(
        (city) => city.name.toLowerCase() === name.toLowerCase()
      )
  );
  const addCity = useCallback(
    async (city:any, photos:any) => {
      const already = cities?.filter(
        (_city) => _city?.name.toLowerCase() === city.toLowerCase()
      )[0]?.id;
      if (already) {
        firestore
          .collection("/cities")
          .doc(already)
          .update({
            photos: firebase.firestore.FieldValue.arrayUnion(...photos),
          });
      } else {
        const cityRef = firestore.collection("/cities").doc();
        return cityRef.set({
          id: cityRef.id,
          userIds: firebase.firestore.FieldValue.arrayUnion(userId),
          name: city,
          photos,
        });
      }
    },
    [firestore, cities, userId]
  );
  const deletePhotoFromCity = useCallback(
    async (image:any, name:any) => {
      firestore
        .collection("cities")
        .doc(cities?.filter((city) => city.name === name)[0].id)
        .update({
          photos: firebase.firestore.FieldValue.arrayRemove(image),
        });
    },
    [firestore,cities]
  );
  const addPhotosToCity = useCallback(
    async ( photos:any,name:any) => {
      if(photos.length > 0){
        await firestore
          .collection("/cities")
          .doc(cities?.filter((city) => city.name === name)[0].id)
          .update({
            photos: firebase.firestore.FieldValue.arrayUnion(...photos),
          });
      }
    
    },
    [firestore,cities]
  );
  const loaded = isLoaded(cities);
  useFirestoreConnect({
    collection: "cities",
  });

  let data = {};
  // getting data from fake collection to prevent permission warning whenever the user is not logged in because we can't use hooks conditionally
  if (name) {
    data = {
      collection: "experiences",
      storeAs: "experiencesbycity",
      where: [
        ["city", "==", name.toLowerCase()],
        ["approved", "==", true],
      ],
    };
  } else {
    data = { collection: "experiencess", storeAs: "experiencess" };
  }
  useFirestoreConnect([data]);

  return { cities, loaded, city, addCity, experiences,deletePhotoFromCity,addPhotosToCity };
};
