const defaultCover = require("../assets/img/defaultCover.jpg");
export {defaultCover};
export const defaultAvatar =  "/images/profile_pics/placeholder.svg"
export const extractAvatars = (users) =>{
    let avatars = []
    users.map((user)=>{
        avatars.push({
            src:user.hostPhoto,
            assistiveText:user.hostName,
            title:user.hostName
        })
      return null
    })
    return avatars;
}
export const randomFromArray = (array) =>{
    let randomNumber = Math.floor(Math.random() * array?.length);
    return array[randomNumber];
}
export function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
  }
  export function loadCss(src, position, id) {
    if (!position) {
      return;
    }
    const link = document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("id", id);
    link.href = src
    position.appendChild(link);
  }

  export function loadScriptAsync(src,id,position,callback)
{
  var s,
      r;
  r = false;
  s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = src;
  s.setAttribute("id", id);
  position.appendChild(s);
  s.onload = s.onreadystatechange = function() {
    if ( !r && (!this.readyState || this.readyState === 'complete') )
    {
      r = true;
      callback();
    }
  };
}


export const paths = [
    'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z',
    'M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z',
    'M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z',
    'M7 2v11h3v9l7-12h-4l4-8z',
    'M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z',
  ]

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  
  export const todayDate = yyyy + '/' + mm + '/' + dd;  

  export const experiencesByInterest = [
    {
      title: "Foodies",
      description: "Cultural Heritage - Castles - Myths & Legends...",
      backgroundImage: "/images/interests/1.png",
    },
    {
      title: "History Buff",
      description: "Cultural Heritage - Castles - Myths & Legends...",
      backgroundImage: "/images/interests/2.png",
    },
    {
      title: "Style Icons",
      description: "Personal Shopping - THrift Markets - Traditional...",
      backgroundImage: "/images/interests/3.png",
    },
    {
      title: "Adventures",
      description: "Hiking - Wildlife & NatureCamping- Day Trips...",
      backgroundImage: "/images/interests/4.png",
    },
    {
      title: "Art Lovers",
      description: "Architecture - Street Art- Galleries -Arisanal ...",
      backgroundImage: "/images/interests/5.png",
    },
    {
      title: "Nightlifes",
      description: "Local Streets -Tastings-Must sees- Oldd city ...",
      backgroundImage: "/images/interests/6.png",
    }
  ];

  export const filterExperiencesByForAdmin = [
    {
      title: "dateCreated",
      description: "Date Created"
    },
    {
      title: "dateApproved",
      description: "Date Approved"
    },
    {
      title: "declined",
      description: "Declined"
    },
    {
      title: "approved",
      description: "Approved"
    },
  ]

  export const toFirebaseDate = (date) => {
    return new Date(date?.seconds * 1000).toLocaleDateString();
  }