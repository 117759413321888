import React, { useCallback } from "react";
import { Button, Chip } from "@material-ui/core";
import { ShareOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Experience } from "types";
import { CardListWidget, 
  // DateWidget,
   ShareButton } from "..";
import {
  //  useExperiences,
   useGlobalStyles } from "../../../hooks";

type Props = {
  experience: Experience;
  label?: string;
};

const Item: React.FC<Props> = ({ label, experience }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalStyle = useGlobalStyles();

  // const { experiences } = useExperiences();
  const { id } = experience || {};
  const goTo = useCallback(() => history.push(`/experiences/${id}`), [
    history,
    id,
  ]);
  return (
    <CardListWidget
      label={label}
      onClick={goTo}
      coverUrl={experience.coverUrl}
      title={experience.name}
      subtitle={`
                ${experience?.country}, ${experience?.city}`}
      cardActions={[
        {
          type: "primary",
          label: t("experience.viewexperience"),
          onClick: goTo,
        },
        {
          type: "secondary",
          label: t("experience.viewVenue"),
          onClick: () =>
            history.push(`/venues/${experience[experience?.userId].slug}`),
        },
        {
          render: () => (
            <ShareButton
              url={`https://tabaani.co/experiences/${experience.id}`}
              renderButton={(openPopOver) => (
                <Button
                  className={globalStyle.iconButton}
                  size="small"
                  color="primary"
                  disableElevation
                  onClick={(e) => openPopOver(e)}
                >
                  <ShareOutlined fontSize="small" />
                </Button>
              )}
            />
          ),
        },
      ]}
      // renderTopIcons={
      //   allGuestListsList.some(gl => gl.experienceId === experience.id) && (
      //     <CardListWidget.Icon
      //       icon={<HowToReg />}
      //       label={t('experience.registerToGuestList')}
      //       activeLabel={t('experience.registeredToGuestList')}
      //       active={true}
      //     />
      //   )
      // }
    >
      {experience.category?.length ? (
        <>
          {experience.category.slice(0, 2).map((music) => (
            <Chip
              component="span"
              key={`${experience.id}-${music}`}
              label={t(`shared.musicStyles.${music}`)}
              variant="outlined"
              size="small"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(" ")}
            />
          ))}
          {experience.category.length > 2 && (
            <Chip
              variant="outlined"
              size="small"
              component="span"
              className={[
                globalStyle.mr1,
                globalStyle.mb1,
                globalStyle.chipMobile,
              ].join(" ")}
              label={`+ ${experience.category.length - 2}`}
            />
          )}
        </>
      ) : (
        <Chip
          component="span"
          label={t("shared.musicStyles.noStyle")}
          variant="outlined"
          size="small"
          className={[
            globalStyle.mr1,
            globalStyle.mb1,
            globalStyle.chipMobile,
          ].join(" ")}
        />
      )}
    </CardListWidget>
  );
};

Item.displayName = "Item";

export default React.memo(Item);
