export function getNameInitials(fullName: string): string {
    if (!fullName) {
      return '';
    }
    const contactNames = fullName.trim().split(' ');
    const firstInitial = contactNames[0].substr(0, 1);
    const lastInitial =
      contactNames.length > 1
        ? contactNames[contactNames.length - 1].substr(0, 1)
        : '';
    const initials = `${firstInitial}${lastInitial}`;
    return initials;
  }
  