import { createAction } from 'typesafe-actions';

type SelectRegion =
  | {
      id: string;
      name?:string;
      photos?:string[];
      userIds?:string[]
      coords?: string;
      img?: string;
    }
  | null
  | undefined;

export const selectRegion = createAction('action/SELECT_REGION')<
  SelectRegion
>();
