import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  error?: string | null;
  label: string;
  defaultOptions?: string[];
  control: any;
  name: string;
  required?: boolean;
  options?: { id: string; value: string }[];
  langDomain?: string;
  defaultValue?: string;
  fieldClassName?: string;
};

const SelectField: React.FC<Props> = ({
  error,
  label,
  control,
  name,
  options,
  langDomain,
  required,
  defaultValue,
  fieldClassName,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl
      required={required}
      error={!!error}
      variant="outlined"
      size="small"
      fullWidth
      className={fieldClassName}
    >
      <InputLabel id={`select-for-${name}`}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        required={required}
        defaultValue={defaultValue}
        as={
          <Select labelWidth={60} labelId={`select-for-${name}`}>
            {options?.map(option => (
              <MenuItem key={option?.id} value={option?.id}>
                {langDomain
                  ? t(`${langDomain}.${option?.value}`)
                  : option?.value}
              </MenuItem>
            ))}
          </Select>
        }
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

SelectField.displayName = 'SelectField';

export default React.memo(SelectField);
