import React, { useState } from "react";
import {
  useAuthentification,
  useCompressedImage,
  useGlobalStyles,
  // useVerification,
} from "../../../hooks";
import { useHistory } from "react-router";
import PageCoverAndLogo from "../PageCoverAndLogo";
import VenueForm from "../VenueForm";
import { Spacer } from "../../atoms";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useExperience } from "hooks/experience";
import { Preloader } from "components/molecules";
import Layout from "components/organs/Layout/Layout";
import { useToasts } from "react-toast-notifications";
const RegisterVenue: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [customErrors, setCustomErrors] = useState({});
  const [cover,setCompressCover] = useCompressedImage();
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const { isAuthenticated, isHost } = useAuthentification();
  const { registerExperience, loading } = useExperience();
  const onSubmit = async (values: any) => {
    let errorsNumber = 0;
    if (!(cover.length > 0)) {
      window.document.body.scrollTo(0, 0);
      addToast("Please add a cover photo for your experience", {
        appearance: "error",
      });
      setCustomErrors((prev: any) => ({
        ...prev,
        coverUrl: "Cover photo required",
      }));
      errorsNumber += 1;
      return;
    } else {
      setCustomErrors((prev: any) => {
        const newData = { ...prev };
        delete newData.coverUrl;
        return newData;
      });
      errorsNumber = 0;
    }
    if (values.images.length < 1) {
      errorsNumber += 1;
      window.document.body.scrollTo(
        0,
        document?.getElementById("drag-and-drop")!.getBoundingClientRect().top -
          100
      );
      setCustomErrors((prev: any) => ({
        ...prev,
        images: "please pick some photos for your trip",
      }));
      addToast("Please add at least one image", {
        appearance: "error",
      });
      return;
    } else {
      setCustomErrors((prev: any) => {
        const newData = { ...prev };
        delete newData.images;
        return newData;
      });
      errorsNumber = 0;
    }
    if (values.steps[2].title === "") {
      errorsNumber += 1;
      window.document.body.scrollTo(
        0,
        document?.getElementById("stepper")!.getBoundingClientRect().top - 100
      );
      addToast("Please make sure to fill all the steps required", {
        appearance: "error",
      });
      setCustomErrors((prev: any) => ({
        ...prev,
        steps: "please add Steps to your experience",
      }));
      return;
    } else {
      setCustomErrors((prev: any) => {
        const newData = { ...prev };
        delete newData.steps;
        return newData;
      });
      errorsNumber = 0;
    }
    if (values.availableDays.length < 1) {
      errorsNumber += 1;
      window.document.body.scrollTo(
        0,
        document?.getElementById("available-days")!.getBoundingClientRect()
          .top - 100
      );
      setCustomErrors((prev: any) => ({
        ...prev,
        availableDays: "please choose your available Days",
      }));
      addToast("Please Choose When you can host travelers with Tabaani", {
        appearance: "error",
      });
      return;
    } else {
      setCustomErrors((prev: any) => {
        const newData = { ...prev };
        delete newData.availableDays;
        return newData;
      });
      errorsNumber = 0;
    }
    // if (!values.meetingPoint) {
    //   errorsNumber += 1;
    //   window.document.body.scrollTo(
    //     0,
    //     document?.getElementById("google-map-demo")!.getBoundingClientRect()
    //       .top - 100
    //   );
    //   addToast("Please Make sure to add the meeting point ", {
    //     appearance: "error",
    //   });
    //   setCustomErrors((prev: any) => ({
    //     ...prev,
    //     meetingPoint: "Select a valid address",
    //   }));
    //   return;
    // } else {
    //   setCustomErrors((prev: any) => {
    //     const newData = { ...prev };
    //     delete newData.meetingPoint;
    //     return newData;
    //   });
    //   errorsNumber = 0;
    // }
    console.log({ errorsNumber });
    if (errorsNumber === 0) {
      const { id } = await registerExperience({
        coverImage: cover,
        images: values.images,
        city: values.city?.toLowerCase(),
        ...values,
      });
      history.push(`/experiences/${id}`);
    }
  };
  if (loading) {
    return <Preloader text="Creating your experience ..." />;
  }
  return (
    <Layout headerColor="black">
      <div className={globalStyles.relative}>
        <Spacer height="20px" />
        {!isAuthenticated || !isHost ? (
          <Typography variant="h5" color="error" align="center">
            {t("please_apply")} <Link to="/becomeHost">{t("click")} </Link>
          </Typography>
        ) : (
          <>
            <PageCoverAndLogo.Form cover={cover} setCover={setCompressCover} />
            <VenueForm onSubmit={onSubmit} customErrors={customErrors} />
          </>
        )}
      </div>
    </Layout>
  );
};

RegisterVenue.displayName = "RegisterVenue";

export default React.memo(RegisterVenue);
