import { useCallback } from 'react';
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useAuthentification } from '../auth';
import { useSendEmail } from '../email';
import { useSelector } from 'react-redux';
// import { RootState } from 'redux/reducers/rootReducer';
// type Subscription = {
//     email?: string;
//     isRegistered?: boolean;
//   };
export const useSubscriptions = () => {
  const firestore = useFirestore();
  const { userId } = useAuthentification();
  const { sendEmail } = useSendEmail();
  
//   const subscriptions = useSelector<RootState>((state) => state.firestore?.data?.subscriptions);
  const subscriptionsList = useSelector((state) => state.firestore?.ordered?.subscriptions || []);

  
  const addSubscription = useCallback(
    
    async (email) => {
        const html = `
        Hi, thanks for registering to Tabaani's newsletter ,</br>
        <a href="https://tabaani.co" >Tabaani.co </a>
      `;
        const already =  (subscriptionsList ).find((sub ) => sub.email === email)
        if(already===undefined){
            await firestore
            .collection("/subscriptions")
            .add({
                email,
                isRegistered:userId ? true : false
             });
    // @ts-ignore
          sendEmail({
            to: email,
            subject: 'Thanks for subscribing',
            html,
          });
          return "Thank you for registering in the newsletter";
        }else{
            return "already registered in newsletter"
        }
       
    },
    [sendEmail,firestore,subscriptionsList,userId],
  );
  useFirestoreConnect([{ collection: "subscriptions" }]);
  return { addSubscription };
};
