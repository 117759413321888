import { createTheme } from "@material-ui/core/styles";
// https://next.material-ui.com/customization/default-theme/#main-content
export const theme = createTheme({
  palette: {
    primary: {
      main: "#FFAF20",
      light: "#fff",
      dark: "rgb(92, 150, 196)",
    },
    info:{
      light:"#4dd0e1",
      main:"#00bcd4",
      dark:"#0097a7",
    },
    success:{
      light:"#81c784",
      main:"#4caf50",
      dark:"#388e3c",
    },
    secondary: {
      main: "#f44336",
    },
  },

});
