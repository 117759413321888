import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Apps } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import CustomDropdown from "components/atoms/CustomDropdown/CustomDropdown.js";
import { useAuthentification } from "hooks/auth";
import i18next from "i18next";
import { useOrder } from "hooks";
import useStyle from "./styles";
// import NotificationSection from "components/molecules/NotificationSection";
import ContentLoader from 'react-content-loader'

type Props = {
  currency: any;
  newMessageCounter?: any;
  dispatch?: any;
  setCurrency?: any;
  mobileOpen?: any;
};
const HeaderLinks: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { currentOrder } = useOrder();
  const {
    logout,
    profilePicture,
    withRole,
    profile,
    userId,
    loaded,
    isHost,
  } = useAuthentification();

  const { currency } = props;
  const getLanguage = () => {
    switch (i18next.language) {
      case "fr":
        return "French";
      case "en":
        return "English";
      case "de":
        return "Deutsch";
      default:
        return "English";
    }
  };
  const changeLanguageTrigger = (e: any) => {
    const languageCode = e.target.value;
    i18next.changeLanguage(languageCode, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      t("key"); // -> same as i18next.t
    });
    // props.dispatch(changeLanguage(languageCode));
  };

  const setCurrencyTrigger = (e: any) => {
    const currencyName = e.target.value;
    props.setCurrency(currencyName);
  };
  const classes = useStyle();
  if (userId) {
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={t("header.hosting")}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={[
              <Link
                to={`/my-host`}
                color="transparent"
                className={classes.navLink}
              >
                {t("header.hosting_profile")}
              </Link>,
              <Link to="/addExperience" className={classes.navLink}>
                {t("header.addExperience")}
              </Link>,
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={getLanguage()}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={[
              // <option
              //   href="#"
              //   value="fr"
              //   className={classes.dropdownLink}
              //   onClick={(e) => changeLanguageTrigger(e)}
              // >
              //   {" "}
              //   French
              // </option>,
              <option
                value="en"
                className={classes.dropdownLink}
                onClick={(e) => changeLanguageTrigger(e)}
              >
                {" "}
                English
              </option>,
              // <option
              //   href="#"
              //   value="de"
              //   className={classes.dropdownLink}
              //   onClick={(e) => changeLanguageTrigger(e)}
              // >
              //   {" "}
              //   Deutsch
              // </option>,
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={currency.currencyName ? currency.currencyName : "EUR"}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={[
              <option
                value="USD"
                className={classes.dropdownLink}
                onClick={(e) => setCurrencyTrigger(e)}
              >
                {" "}
                USD
              </option>,
              <option
                value="EUR"
                className={classes.dropdownLink}
                onClick={(e) => setCurrencyTrigger(e)}
              >
                {" "}
                EUR
              </option>,
              <option
                value="GBP"
                className={classes.dropdownLink}
                onClick={(e) => setCurrencyTrigger(e)}
              >
                {" "}
                GBP
              </option>,
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          {currentOrder && (
            <Link to="/escrow" color="transparent" className={classes.navLink}>
              {t("header.myTrip")}
            </Link>
          )}
          <Link to="/blog" color="transparent" className={classes.navLink}>
            {t("header.blog")}
          </Link>
          {/* <Link
            to="/chats"
            color="transparent"
            className={classes.navLink}
          >
            {t("header.inbox")}
            <Badge
              color="secondary"
              badgeContent={props.newMessageCounter}
              className="someTotheRight"
            ></Badge>
          </Link> */}

          {/* <NotificationSection /> */}
        </ListItem>
        {!loaded ? (
          <ContentLoader viewBox="0 0 100 100" preserveAspectRatio="none" animate={true} gradientRatio={2} height="40px" width="40px">
            <circle cx="50" cy="40" r="40" />

          </ContentLoader>
        
        ) : (
          <ListItem className={classes.listItem}>
            <CustomDropdown
              left
              caret={false}
              hoverColor="black"
              dropdownHeader={
                <>
                  <span>{profile.fullName}</span>
                  <span style={{ color: "green" }}> +{profile.points}</span>
                </>
              }
              buttonText={
                <div>
                  <img
                    src={profilePicture}
                    className={classes.img}
                    alt="profile"
                  />
                </div>
              }
              buttonProps={{
                size: "sm",
                className:
                  classes.navLink + props.mobileOpen ||
                  props.mobileOpen === undefined
                    ? ""
                    : classes.imageDropdownButton,
                color: "transparent",
              }}
              dropdownList={[
                <Link to="/profile" className="menuLink">
                  <div className="row">
                    <div className="col-8">
                      <span className="profileEditSpan">
                        {t("edit_profile")}
                      </span>
                    </div>
                    <div className="col-4">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 70 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M35 11.6666C22.1375 11.6666 11.6667 22.1375 11.6667 35C11.6667 40.3083 13.475 45.1791 16.45 49.0875C20.6208 44.0125 30.7417 42.2916 35 42.2916C39.2583 42.2916 49.3792 44.0125 53.55 49.0875C56.525 45.1791 58.3333 40.3083 58.3333 35C58.3333 22.1375 47.8625 11.6666 35 11.6666ZM35 37.9166C29.3417 37.9166 24.7917 33.3666 24.7917 27.7083C24.7917 22.05 29.3417 17.5 35 17.5C40.6583 17.5 45.2083 22.05 45.2083 27.7083C45.2083 33.3666 40.6583 37.9166 35 37.9166Z"
                          fill="black"
                        />
                        <path
                          d="M35 5.83337C18.9 5.83337 5.83334 18.9 5.83334 35C5.83334 51.1 18.9 64.1667 35 64.1667C51.1 64.1667 64.1667 51.1 64.1667 35C64.1667 18.9 51.1 5.83337 35 5.83337ZM20.6208 53.3167C21.875 50.6917 29.5167 48.125 35 48.125C40.4833 48.125 48.1542 50.6917 49.3792 53.3167C45.4125 56.4667 40.425 58.3334 35 58.3334C29.575 58.3334 24.5875 56.4667 20.6208 53.3167ZM53.55 49.0875C49.3792 44.0125 39.2583 42.2917 35 42.2917C30.7417 42.2917 20.6208 44.0125 16.45 49.0875C13.475 45.1792 11.6667 40.3084 11.6667 35C11.6667 22.1375 22.1375 11.6667 35 11.6667C47.8625 11.6667 58.3333 22.1375 58.3333 35C58.3333 40.3084 56.525 45.1792 53.55 49.0875ZM35 17.5C29.3417 17.5 24.7917 22.05 24.7917 27.7084C24.7917 33.3667 29.3417 37.9167 35 37.9167C40.6583 37.9167 45.2083 33.3667 45.2083 27.7084C45.2083 22.05 40.6583 17.5 35 17.5ZM35 32.0834C32.5792 32.0834 30.625 30.1292 30.625 27.7084C30.625 25.2875 32.5792 23.3334 35 23.3334C37.4208 23.3334 39.375 25.2875 39.375 27.7084C39.375 30.1292 37.4208 32.0834 35 32.0834Z"
                          fill="#FFAF20"
                        />
                      </svg>{" "}
                    </div>
                  </div>
                </Link>,
                isHost === false ? (
                  <Link to="/becomeHost" className="menuLink">
                    <div className="row">
                      <div className="col-8">
                        <span className="profileEditSpan">
                          {t("become_a_host")}
                        </span>
                      </div>
                      <div className="col-4">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 70 70"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M37.9167 32.2873L43.75 28.7873V11.6665H37.9167V32.2873Z"
                            fill="white"
                          />
                          <path
                            opacity="0.3"
                            d="M26.25 11.6665V28.7873L32.0833 32.2873V11.6665H26.25Z"
                            fill="#E24624"
                          />
                          <path
                            d="M49.5833 30.4208V5.83325H20.4167V30.4208C20.4167 31.4416 20.9417 32.4041 21.8458 32.9291L34.0375 40.2499L31.15 47.0749L21.2042 47.9208L28.7583 54.4541L26.4542 64.1666L35 59.0041L43.5458 64.1666L41.2708 54.4541L48.825 47.9208L38.8792 47.0749L35.9917 40.2499L48.1833 32.9291C49.0583 32.4041 49.5833 31.4708 49.5833 30.4208ZM32.0833 32.2874L26.25 28.7874V11.6666H32.0833V32.2874ZM43.75 28.7874L37.9167 32.2874V11.6666H43.75V28.7874Z"
                            fill="#FFAF20"
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link to="/Host-Dashboard" className="menuLink">
                    <div className="row">
                      <div className="col-8">
                        <span className="profileEditSpan">{t("dashbord")}</span>
                      </div>
                      <div className="col-4">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 70 70"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M37.9167 32.2873L43.75 28.7873V11.6665H37.9167V32.2873Z"
                            fill="white"
                          />
                          <path
                            opacity="0.3"
                            d="M26.25 11.6665V28.7873L32.0833 32.2873V11.6665H26.25Z"
                            fill="#E24624"
                          />
                          <path
                            d="M49.5833 30.4208V5.83325H20.4167V30.4208C20.4167 31.4416 20.9417 32.4041 21.8458 32.9291L34.0375 40.2499L31.15 47.0749L21.2042 47.9208L28.7583 54.4541L26.4542 64.1666L35 59.0041L43.5458 64.1666L41.2708 54.4541L48.825 47.9208L38.8792 47.0749L35.9917 40.2499L48.1833 32.9291C49.0583 32.4041 49.5833 31.4708 49.5833 30.4208ZM32.0833 32.2874L26.25 28.7874V11.6666H32.0833V32.2874ZM43.75 28.7874L37.9167 32.2874V11.6666H43.75V28.7874Z"
                            fill="#FFAF20"
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                ),
                withRole(["supervisor", "admin"]) ? (
                  <Link to="/admin4565" className="menuLink">
                    <div className="row">
                      <div className="col-8">
                        <span className="profileEditSpan">{t("Admin")}</span>
                      </div>
                      <div className="col-4">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 70 70"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M37.9167 32.2873L43.75 28.7873V11.6665H37.9167V32.2873Z"
                            fill="white"
                          />
                          <path
                            opacity="0.3"
                            d="M26.25 11.6665V28.7873L32.0833 32.2873V11.6665H26.25Z"
                            fill="#E24624"
                          />
                          <path
                            d="M49.5833 30.4208V5.83325H20.4167V30.4208C20.4167 31.4416 20.9417 32.4041 21.8458 32.9291L34.0375 40.2499L31.15 47.0749L21.2042 47.9208L28.7583 54.4541L26.4542 64.1666L35 59.0041L43.5458 64.1666L41.2708 54.4541L48.825 47.9208L38.8792 47.0749L35.9917 40.2499L48.1833 32.9291C49.0583 32.4041 49.5833 31.4708 49.5833 30.4208ZM32.0833 32.2874L26.25 28.7874V11.6666H32.0833V32.2874ZM43.75 28.7874L37.9167 32.2874V11.6666H43.75V28.7874Z"
                            fill="#FFAF20"
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                ) : <Link to="/Wishlist" className="menuLink">
                <div className="row">
                  <div className="col-8">
                    <span className="profileEditSpan">{t("wishlist")}</span>
                  </div>
                  <div className="col-4">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.8112 2.94702C26.3315 1.04659 24.2788 0 22.0312 0C18.8702 0 16.869 2.13695 15.7468 3.92967C15.4556 4.39486 15.2079 4.86132 15 5.30025C14.7921 4.86132 14.5444 4.39486 14.2532 3.92967C13.131 2.13695 11.1298 0 7.96875 0C5.72121 0 3.6685 1.04665 2.18877 2.94709C0.777363 4.75998 0 7.18795 0 9.78372C0 12.6093 0.97459 15.2374 3.06709 18.0543C4.93723 20.5719 7.62768 23.167 10.7432 26.1721C11.9041 27.2919 13.1046 28.4499 14.3827 29.7157L14.4211 29.7537C14.5868 29.918 14.7934 30 15 30C15.2066 30 15.4132 29.9179 15.5789 29.7537L15.6173 29.7157C16.8954 28.4499 18.0959 27.2919 19.257 26.1719C22.3723 23.1671 25.0628 20.572 26.9329 18.0543C29.0254 15.2373 30 12.6093 30 9.78372C30 7.18795 29.2226 4.75997 27.8112 2.94702ZM18.1168 24.6576C17.116 25.6229 16.086 26.6164 15 27.685C13.914 26.6165 12.884 25.623 11.883 24.6575C5.78584 18.7764 1.75781 14.8911 1.75781 9.78372C1.75781 7.67264 2.3792 5.71185 3.50754 4.26262C4.64883 2.79693 6.23314 1.98971 7.96875 1.98971C10.3787 1.98971 11.9365 3.67021 12.8189 5.07999C13.6104 6.34438 14.0235 7.61899 14.1643 8.10825C14.2822 8.5178 14.6195 8.79497 15 8.79497C15.3805 8.79497 15.7178 8.5178 15.8357 8.10825C15.9765 7.61899 16.3896 6.34438 17.1811 5.07992C18.0635 3.67021 19.6213 1.98971 22.0312 1.98971C23.7669 1.98971 25.3512 2.79693 26.4924 4.26262C27.6208 5.71185 28.2422 7.67264 28.2422 9.78372C28.2422 14.8911 24.2142 18.7764 18.1168 24.6576Z"
                        fill="#FCB22C"
                      />
                    </svg>
                  </div>
                </div>
              </Link>,
                
                <Link to="/help" className="menuLink">
                  <div className="row">
                    <div className="col-8">
                      <span className="profileEditSpan">{t("help")}</span>
                    </div>
                    <div className="col-4">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 70 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M30.0417 21.35L26.6292 13.2417C20.475 15.6042 15.6042 20.475 13.2417 26.6292L21.35 29.9834C22.8375 25.9584 26.0167 22.8084 30.0417 21.35Z"
                          fill="#FFAF20"
                        />
                        <path
                          opacity="0.3"
                          d="M21.35 40.0166L13.2417 43.3708C15.6042 49.4958 20.475 54.3958 26.6292 56.7583L30.0417 48.6499C25.9875 47.1916 22.8375 44.0124 21.35 40.0166Z"
                          fill="#FFAF20"
                        />
                        <path
                          opacity="0.3"
                          d="M48.6208 29.9542L56.7292 26.6C54.3667 20.475 49.4958 15.6042 43.3708 13.2417L40.0167 21.35C44.0125 22.8375 47.1625 25.9584 48.6208 29.9542Z"
                          fill="#FFAF20"
                        />
                        <path
                          opacity="0.3"
                          d="M48.65 39.9874C47.1917 43.9833 44.0417 47.1624 40.0459 48.6499L43.4 56.7583C49.525 54.3958 54.3959 49.5249 56.7584 43.3999L48.65 39.9874Z"
                          fill="#FFAF20"
                        />
                        <path
                          d="M35 5.83331C18.9 5.83331 5.83334 18.9 5.83334 35C5.83334 51.1 18.9 64.1667 35 64.1667C51.1 64.1667 64.1667 51.1 64.1667 35C64.1667 18.9 51.1 5.83331 35 5.83331ZM43.3708 13.2416C49.4958 15.6041 54.3667 20.475 56.7292 26.6L48.6208 29.9542C47.1333 25.9875 44.0125 22.8375 40.0167 21.3791L43.3708 13.2416ZM26.6292 13.2416L30.0417 21.35C26.0167 22.8083 22.8375 25.9875 21.35 30.0125L13.2417 26.6291C15.6042 20.475 20.475 15.6041 26.6292 13.2416ZM26.6292 56.7583C20.5042 54.3958 15.6042 49.525 13.2417 43.3708L21.35 40.0167C22.8375 44.0417 25.9875 47.1917 30.0125 48.65L26.6292 56.7583ZM26.25 35C26.25 30.1583 30.1583 26.25 35 26.25C39.8417 26.25 43.75 30.1583 43.75 35C43.75 39.8416 39.8417 43.75 35 43.75C30.1583 43.75 26.25 39.8416 26.25 35ZM43.4 56.7583L40.0458 48.65C44.0417 47.1625 47.1917 44.0125 48.65 39.9875L56.7583 43.4C54.3958 49.525 49.525 54.3958 43.4 56.7583Z"
                          fill="#FFAF20"
                        />
                      </svg>
                    </div>
                  </div>
                </Link>,
                <Link to="#" className="menuLink">
                  <div className="row">
                    <div className="col-8">
                      <span className="profileEditSpan">
                        {currency.currencyName ? currency.currencyName : "EUR"}
                      </span>
                    </div>
                    <div className="col-4">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 70 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M11.6667 17.5H58.3333V23.3333H11.6667V17.5ZM11.6667 35H58.3333V52.5H11.6667V35Z"
                          fill="#030104"
                        />
                        <path
                          d="M58.3333 11.6667H11.6667C8.42918 11.6667 5.86251 14.2625 5.86251 17.5L5.83334 52.5C5.83334 55.7375 8.42918 58.3334 11.6667 58.3334H58.3333C61.5708 58.3334 64.1667 55.7375 64.1667 52.5V17.5C64.1667 14.2625 61.5708 11.6667 58.3333 11.6667ZM58.3333 52.5H11.6667V35H58.3333V52.5ZM58.3333 23.3334H11.6667V17.5H58.3333V23.3334Z"
                          fill="#FFAF20"
                        />
                      </svg>
                    </div>
                  </div>
                </Link>,
                <Link to="#" className="menuLink">
                  <div className="row">
                    <div className="col-8">
                      <span className="profileEditSpan">{getLanguage()}</span>
                    </div>
                    <div className="col-4">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 70 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M14.8167 23.3333H23.4208C24.3542 19.6874 25.6958 16.1874 27.4458 12.9499C22.0792 14.7874 17.6167 18.4916 14.8167 23.3333ZM21.875 34.9999C21.875 33.0166 22.05 31.0916 22.2833 29.1666H12.425C11.9583 31.0333 11.6667 32.9874 11.6667 34.9999C11.6667 37.0124 11.9583 38.9666 12.425 40.8333H22.2833C22.05 38.9083 21.875 36.9833 21.875 34.9999ZM14.8167 46.6666C17.6167 51.5083 22.0792 55.2124 27.4458 57.0499C25.6958 53.8124 24.3542 50.3124 23.4208 46.6666H14.8167ZM35 11.7833C32.5792 15.2833 30.6833 19.1624 29.4292 23.3333H40.5708C39.3167 19.1624 37.4208 15.2833 35 11.7833ZM55.1833 23.3333C52.3833 18.5208 47.9208 14.7874 42.5542 12.9499C44.3042 16.1874 45.6458 19.6874 46.5792 23.3333H55.1833ZM35 58.2166C37.4208 54.7166 39.3167 50.8374 40.5708 46.6666H29.4292C30.6833 50.8374 32.5792 54.7166 35 58.2166ZM42.5542 57.0499C47.9208 55.2124 52.3833 51.4791 55.1833 46.6666H46.5792C45.6458 50.3124 44.3042 53.8124 42.5542 57.0499ZM57.575 29.1666H47.7167C47.95 31.0916 48.125 33.0166 48.125 34.9999C48.125 36.9833 47.95 38.9083 47.7167 40.8333H57.575C58.0417 38.9666 58.3333 37.0124 58.3333 34.9999C58.3333 32.9874 58.0417 31.0333 57.575 29.1666ZM28.175 29.1666C27.9125 31.0624 27.7083 33.0166 27.7083 34.9999C27.7083 36.9833 27.9125 38.9083 28.175 40.8333H41.825C42.0875 38.9083 42.2917 36.9833 42.2917 34.9999C42.2917 33.0166 42.0875 31.0624 41.825 29.1666H28.175Z"
                          fill="#FFAF20"
                        />
                        <path
                          d="M34.9708 5.83331C18.8708 5.83331 5.83334 18.9 5.83334 35C5.83334 51.1 18.8708 64.1667 34.9708 64.1667C51.1 64.1667 64.1667 51.1 64.1667 35C64.1667 18.9 51.1 5.83331 34.9708 5.83331ZM55.1833 23.3333H46.5792C45.6458 19.6875 44.3042 16.1875 42.5542 12.95C47.9208 14.7875 52.3834 18.5208 55.1833 23.3333ZM35 11.7833C37.4208 15.2833 39.3167 19.1625 40.5708 23.3333H29.4292C30.6833 19.1625 32.5792 15.2833 35 11.7833ZM12.425 40.8333C11.9583 38.9666 11.6667 37.0125 11.6667 35C11.6667 32.9875 11.9583 31.0333 12.425 29.1666H22.2833C22.05 31.0917 21.875 33.0166 21.875 35C21.875 36.9833 22.05 38.9083 22.2833 40.8333H12.425ZM14.8167 46.6666H23.4208C24.3542 50.3125 25.6958 53.8125 27.4458 57.05C22.0792 55.2125 17.6167 51.5083 14.8167 46.6666ZM23.4208 23.3333H14.8167C17.6167 18.4916 22.0792 14.7875 27.4458 12.95C25.6958 16.1875 24.3542 19.6875 23.4208 23.3333ZM35 58.2166C32.5792 54.7166 30.6833 50.8375 29.4292 46.6666H40.5708C39.3167 50.8375 37.4208 54.7166 35 58.2166ZM41.825 40.8333H28.175C27.9125 38.9083 27.7083 36.9833 27.7083 35C27.7083 33.0166 27.9125 31.0625 28.175 29.1666H41.825C42.0875 31.0625 42.2917 33.0166 42.2917 35C42.2917 36.9833 42.0875 38.9083 41.825 40.8333ZM42.5542 57.05C44.3042 53.8125 45.6458 50.3125 46.5792 46.6666H55.1833C52.3834 51.4792 47.9208 55.2125 42.5542 57.05ZM47.7167 40.8333C47.95 38.9083 48.125 36.9833 48.125 35C48.125 33.0166 47.95 31.0917 47.7167 29.1666H57.575C58.0417 31.0333 58.3334 32.9875 58.3334 35C58.3334 37.0125 58.0417 38.9666 57.575 40.8333H47.7167Z"
                          fill="#FFAF20"
                        />
                      </svg>
                    </div>
                  </div>
                </Link>,
                <Link
                  to="/"
                  className="menuLink"
                  onClick={() => {
                    logout();
                  }}
                >
                  <div className="row">
                    <div className="col-8">
                      <span className="profileEditSpan">Log out</span>
                    </div>
                    <div className="col-4">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 70 70"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M32.0833 20.4167L28 24.5L35.5833 32.0833H5.83334V37.9167H35.5833L28 45.5L32.0833 49.5833L46.6667 35L32.0833 20.4167ZM58.3334 55.4167H35V61.25H58.3334C61.5417 61.25 64.1667 58.625 64.1667 55.4167V14.5833C64.1667 11.375 61.5417 8.75 58.3334 8.75H35V14.5833H58.3334V55.4167Z"
                          fill="#FFAF20"
                        />
                      </svg>
                    </div>
                  </div>
                </Link>,
              ]}
            />
          </ListItem>
        )}{" "}
      </List>
    );
  } else {
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Link to="/about" color="transparent" className={classes.navLink}>
            {t("about_us")}
          </Link>
          <Link
            to="/becomeHost"
            color="transparent"
            className={classes.navLink}
          >
            {t("become_a_host")}
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={getLanguage()}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={[
              // <option
              //   href="#"
              //   value="fr"
              //   className={classes.dropdownLink}
              //   onClick={(e) => changeLanguageTrigger(e)}
              // >
              //   {" "}
              //   French
              // </option>,
              <option
                value="en"
                className={classes.dropdownLink}
                onClick={(e) => changeLanguageTrigger(e)}
              >
                {" "}
                English
              </option>,
              // <option
              //   href="#"
              //   value="de"
              //   className={classes.dropdownLink}
              //   onClick={(e) => changeLanguageTrigger(e)}
              // >
              //   {" "}
              //   Deutsch
              // </option>,
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={currency.currencyName ? currency.currencyName : "EUR"}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={[
              <option
                value="USD"
                className={classes.dropdownLink}
                onClick={(e) => setCurrencyTrigger(e)}
              >
                {" "}
                USD
              </option>,
              <option
                value="EUR"
                className={classes.dropdownLink}
                onClick={(e) => setCurrencyTrigger(e)}
              >
                {" "}
                EUR
              </option>,
              <option
                value="GBP"
                className={classes.dropdownLink}
                onClick={(e) => setCurrencyTrigger(e)}
              >
                {" "}
                GBP
              </option>,
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/signin" color="transparent" className={classes.navLink}>
            {t("login")}
          </Link>
          <Link
            to="/signup"
            color="transparent"
            className={classes.navLink}
            style={{ border: "1px solid #FFF", borderRadius: "50px" }}
          >
            {t("sign_up")}
          </Link>
        </ListItem>
      </List>
    );
  }
};

const mapStateToProps = (state: any) => {
  return {
    newMessageCounter: state.newMessageCounter.newMessageCounter,
  };
};

export default connect(mapStateToProps)(HeaderLinks);
