import { makeStyles } from "@material-ui/core";

export default makeStyles({
  button: {
    background: "#ffaf20",
    borderRadius: "8px",
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "-0.231818px",
    color: "#ffffff",
    height: "50px",
    border: "none",
    "&:hover": {
      background: "#FFAF6B",
    },
  },
  stepBtn:{
    color:"white",
    margin:"5px"
  },
  interest:{
    display:"flex",
    flexDirection:"column"
  }
});
