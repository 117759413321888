import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IonHeader, IonToolbar } from "@ionic/react";
import { Box, IconButton } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
import { ArrowBackIos } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Flex,
  //  Logo
} from "../../atoms";
import { useGlobalStyles, useMediaQuery } from "../../../hooks";
// import logoBig from "../../../assets/img/logo_w.png";
// import logoSmall from "../../../assets/img/logo_small_w.png";
import Menu from "../Menu";
import { HeaderSearchBar } from "../../molecules";
import useStyle from "./style";
import { RootState } from "redux/reducers/rootReducer";
import {
  addToInAppHistory,
  updateInAppHistory,
} from "redux/actions/configurations/inAppHistory";
// import logoHomeActive from "../../../assets/icons/tabaani_black.png";

type Props = {
  logo?: string;
  hideMenu?: boolean;
};

const DashboardHeader: React.FC<Props> = ({ logo, hideMenu }) => {
  const { isMobile } = useMediaQuery();
  const inAppHistory: any = useSelector<RootState>(
    (state) => state.configuration.inAppHistory
  );
  const dispatch = useDispatch();
  const [added, setAdded] = useState(false);
  const { flex, flexGrow, mh2, bgPrimary } = useGlobalStyles();
  const style = useStyle({ isMobile });
  // const logoImg = logo || isMobile ? logoSmall : logoBig;
  // const logoWidth = isMobile ? "40px" : "250px";
  const history = useHistory();
  const location = useLocation();
  const goBack = useCallback(() => {
    const newHistory = [...inAppHistory];
    newHistory.splice(-2);
    dispatch(updateInAppHistory(newHistory));
    history.goBack();
  }, [dispatch, history, inAppHistory]);

  const canGoBack = useMemo(() => {
    return inAppHistory.length > 1 && location.pathname !== "/home" && isMobile;
  }, [inAppHistory.length, isMobile, location.pathname]);

  useEffect(() => {
    if (location.pathname && !added) {
      setAdded(true);
      dispatch(addToInAppHistory(location.pathname));
    }
  }, [location, dispatch, added]);

  const renderToolbarContent = useMemo(() => {
    return (
      <Box paddingX={1}>
        <Flex justifyContent="space-between">
          {!canGoBack ? (
            // <Logo logo={logoImg} logoActive={logoHomeActive} isLogoHeaderMenu={true} width={logoWidth} to="/home" />
            <></>
          ) : (
            <IconButton size="small" onClick={goBack}>
              <ArrowBackIos style={{ color: "black" }} />
            </IconButton>
          )}

          <div className={[mh2, flex, flexGrow].join(" ")}>
            <HeaderSearchBar />
          </div>
          {!hideMenu && <Menu />}
        </Flex>
      </Box>
    );
  }, [canGoBack, goBack, mh2, flex, flexGrow, hideMenu]);
  return (
    <Box className={style.wrapper}>
      <IonHeader className={style.mobileHeader}>
        <IonToolbar className={bgPrimary}>
          <>{renderToolbarContent}</>
        </IonToolbar>
      </IonHeader>
    </Box>
  );
};

DashboardHeader.displayName = "DashboardHeader";

export default React.memo(DashboardHeader);
