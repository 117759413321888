import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { HeaderSearchResult } from 'types';

const useStyle = makeStyles({
  logo: {
    width: 25,
    height: 25,
    objectFit: 'contain',
    marginRight: 10,
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    padding: 0,
    margin: 0,
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  category: {
    fontSize: 10,
    margin: 0,
    padding: 2,
  },
});

type SearchResultProps = {
  data: HeaderSearchResult;
};

const SearchResult: React.FC<SearchResultProps> = ({ data }) => {
  const styles = useStyle();
  const history = useHistory();
  const goTo = useCallback(
    (collection: string, slug: any) =>{
      if(collection==="blogs"){
        history.push(`/blog/article/${slug}`)
      }else if (collection==="hosts"){
        history.push(`/host/${slug}`)

      }
      else{
        history.push(`/${collection}/${slug}`)
      }
    },
    [history],
  );

  return (
    <div
      className={styles.wrapper}
      onClick={() => goTo(data.collection, data?.slug)}
    >
      <div className={styles.info}>
        <img src={data.logoUrl} className={styles.logo} alt={data.name} />
        <p>{data.name} </p>
      </div>
      <Chip
        color="secondary"
        variant="outlined"
        size="small"
        className={styles.category}
        label={data.type}
      />
    </div>
  );
};

SearchResult.displayName = 'SearchResult';

export default React.memo(SearchResult);
