import React, { useMemo } from 'react';
import { Box, makeStyles, alpha } from '@material-ui/core';
import { IonContent, IonPage } from '@ionic/react';
import { DashboardFooter, DashboardHeader } from '../../molecules';
import {SectionHeader} from '../../molecules';
import { useMediaQuery } from '../../../hooks';
import Header from "../Header/Header";
 

type Props = {
  title?: string;
  subtitle?: string;
  headerColor?: string;
  children?: any;
  boxed?: boolean;
  withoutHeader?: boolean;
};

const useStyle = makeStyles(theme => ({
  wrapper: {
    boxShadow: `-100px -100px 0px 100px inset ${alpha(
      theme.palette.secondary.dark,
      0.8,
    )}`,
  },
}));

const Layout: React.FC<Props> = ({
  title,
  subtitle,
  children,
  boxed = true,
  headerColor,
  withoutHeader,
}) => {
  const style = useStyle();
  const { isMobile } = useMediaQuery();
  const someProps = {
    fixed: true,
    color: headerColor ? "black" : "transparent",
    changeColorOnScroll: {
      height: 0,
      color: "white",
    },
  };

  const renderContent = useMemo(
    () => (
      <Box paddingBottom={4}>
        {title && (
          <Box className={style.wrapper} padding={2} paddingBottom={4}>
            <SectionHeader
              title={title}
              variant="light"
              subtitle={subtitle}
              subtitleVariant="body2"
            />
          </Box>
        )}
        {children}
      </Box>
    ),
    [children, style.wrapper, subtitle, title],
  );

  if (isMobile) {
    return (
      <IonPage>
        <DashboardHeader />
        {/* content hidden fix it */}

        <IonContent scrollEvents >
          {renderContent}
        </IonContent>
        <DashboardFooter />
      </IonPage>
    );
  } else if(withoutHeader){
    return (
      <IonPage>
        <IonContent scrollEvents>
          {renderContent}
        </IonContent>
      </IonPage>
    );
  } else {
    return (
      <IonPage>
        <IonContent scrollEvents >
            <Header {...someProps} />
            {renderContent}
            <DashboardFooter />
            
        </IonContent>
      </IonPage>
    );
  }
};

Layout.displayName = 'Layout';
export default React.memo(Layout);
