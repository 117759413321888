import firebase from 'firebase/app';
import 'firebase//auth';
import 'firebase//firestore';
import 'firebase//analytics';
import 'firebase//storage';
import 'firebase//functions';
import { createFirestoreInstance } from 'redux-firestore';
import store from './store';

// Firebase Init
const {
  REACT_APP_FIREBASE_API_KEY,REACT_APP_AUTHDOMAIN , 
  REACT_APP_PROJECTID ,
  REACT_APP_STORAGEBUCKET ,
  REACT_APP_MESSAGINGSENDERID,
  REACT_APP_FIREBASE_APP_ID ,
  REACT_APP_MEASUREMENTID} = process.env
const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: REACT_APP_AUTHDOMAIN,
    projectId: REACT_APP_PROJECTID,
    storageBucket: REACT_APP_STORAGEBUCKET,
    messagingSenderId: REACT_APP_MESSAGINGSENDERID,
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_MEASUREMENTID
};

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims:true,
};
export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.analytics();
if (process.env.NODE_ENV === 'development') {
  firebase.functions().useFunctionsEmulator("http://localhost:5001");
  // firebase.auth().useEmulator("http://localhost:9099")
}
else{
  firebase.functions();
}
export const storage = firebase.storage();
export const functions = firebase.functions();
export const Firestore = firebase.firestore();
