// @ts-nocheck
import React from 'react';
import { Card, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: 'center',
    padding: 5,
    borderTop: `1px solid white`,
    fontWeight: 'bold',
    fontSize: 17,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(240,240,240)',
    },
  },
}));

type Props = {
  searchedText: string;
  deleteOptions: () => void;
  deleteValue: () => void;
  showMore: boolean;
  children?: React.ReactNode;
};

const AutocompletePopper: React.FC<Props> = React.memo(
  ({ children, searchedText, deleteOptions, deleteValue, showMore }, props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => {
      history.push(`/search/all?q=${searchedText}`);
      deleteOptions();
      deleteValue();
    };
    return (
      <Card {...props}>
        {children}
        {showMore && (
          <div
            onMouseDown={event => {
              event.preventDefault();
            }}
            className={classes.text}
            onClick={handleClick}
          >
            {t('general.allResults')}
          </div>
        )}
      </Card>
    );
  },
);

AutocompletePopper.displayName = 'AutocompletePopper';

export default AutocompletePopper;
