import { alpha, makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  tabBarWrapper: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    zIndex: 100,
  },
  divider: {
    backgroundColor: alpha(theme.palette.secondary.light, 0.4),
  },
  whiteBackground:{
    backgroundColor:"white"
  }
}));
