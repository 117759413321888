import { makeStyles } from '@material-ui/core';

export default makeStyles({
  label: {
    boxShadow: '0px -12px 0px inset white',
    fontWeight: 900,
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(10px, -50%)',
    paddingLeft: 10,
    paddingRight: 10,
  },
});
