
import { LinearProgress } from "@material-ui/core";
import React, { ReactNode } from "react";
import useStyle from "./style";

type Props = {
  text?:ReactNode;
};

const Preloader: React.FC<Props> = ({text}) => {
  const style = useStyle();
  if(text){
    return (
      <div className={style.wrapper}>
        <>
          <img src="/images/logo.png" className={style.logo} alt="Tabaani" />
          <LinearProgress className={style.bar} />
          {text &&<h4 className={style.text}>{text}</h4> }
        </>
      </div>
    );
  }else{
    return(
      <div className="flone-preloader-wrapper">
      <div className="flone-preloader">
        <span></span>
        <span></span>
      </div>
    </div>
    )
  }
};

Preloader.displayName = "Preloader";

export default React.memo(Preloader);
