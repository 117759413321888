import React from 'react';
import { Box} from '@material-ui/core';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import Menu from '../Menu';
// import FollowUs from '../FollowUs';
import useStyle from './style';
import Footer from "views/Components/Footer";

type Props = {};
const DashboardFooter: React.FC<Props> = () => {
  const globalStyle = useGlobalStyles();
  const style = useStyle();
  const { isMobile } = useMediaQuery();

  if (isMobile) {
    return (
      <Box
        className={[
          style.whiteBackground,
          globalStyle.safeAreaBottom,
          style.tabBarWrapper,
        ].join(' ')}
      >
        <Menu tabBar />
      </Box>
    );
  }
  return <Footer/>;
};

DashboardFooter.displayName = 'DashboardFooter';

export default React.memo(DashboardFooter);
