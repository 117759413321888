import React from 'react';

type Props = {
  children: string;
  className?: string;
};

const HtmlDecode: React.FC<Props> = ({ children, className }) => {
  return (
    <span
      dangerouslySetInnerHTML={{ __html: children }}
      className={className ? className : ''}
    />
  );
};

HtmlDecode.displayName = 'HtmlDecode';

export default React.memo(HtmlDecode);
