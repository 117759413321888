import { makeStyles } from '@material-ui/core';
import { blue, red } from '@material-ui/core/colors';

export default makeStyles({
  facebook: {
    color: 'white',
    backgroundColor: blue[800],
    '&:hover': {
      backgroundColor: blue[900],
    },
  },
  twitter: {
    color: 'white',
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  google: {
    color: 'white',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[800],
    },
  },
});
