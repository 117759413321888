import { makeStyles, Theme } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';

type StyleProps = {
  visible: boolean;
};

export default makeStyles<Theme, StyleProps>({
  button: {
    bottom: ({ visible }) => (visible ? 0 : -70),
    transition: '.5s all ease-in-out',
    borderRadius: 0,
    opacity: 0.8,
  },
  buttonsGroup: {
    width: '100%',
    bottom: 0,
    opacity: 0.8,
  },
  confirm: {
    borderRadius: 0,
    width: '100%',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  cancel: {
    borderRadius: 0,
    width: '100%',
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
});
