import React from "react";
import useStyle from "./styles";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Swiper from "react-id-swiper";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import { Fragment } from "react";

import MyGallery from "./MyGallery";
require("lightgallery.js/dist/css/lightgallery.css");

type Props = {
  album?: any;
  experienceId?: any;
  isMine?: any;
  city?: any;
};
const Gallery: React.FC<Props> = ({ album, experienceId, isMine, city }) => {
  const classes = useStyle();
  const swiperRef = React.useRef<any>();
  const [currentIndex, updateCurrentIndex] = React.useState(0);

  const updateIndex = React.useCallback(
    () => updateCurrentIndex(swiperRef?.current.swiper.realIndex),
    []
  );
  React.useEffect(() => {
    const swiperInstance = swiperRef?.current?.swiper;
    if (swiperInstance) {
      swiperInstance.on("slideChange", () => {
        updateIndex();
      });
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateIndex);
      }
    };
  }, [updateIndex]);

  const gallerySwiperParams = {
    spaceBetween: 10,
    loopedSlides: 4,
    // rebuildOnUpdate:true,
    observer: true,
    observeParents: true,
    shouldSwiperUpdate: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
  };
  return (
    <Fragment>
      <div className={classes.productLargeImageWrapper}>
        <LightgalleryProvider>
          <Swiper {...gallerySwiperParams} ref={swiperRef}>
            {album?.map((single: any, key: any) => {
              return (
                <div key={key}>
                  <LightgalleryItem group="first" src={single}>
                    <button type="button" value="Cancel" style={{ cursor:"pointer"}}>
                      <SettingsOverscanIcon fontSize="large" color="primary" />
                    </button>
                  </LightgalleryItem>
                  {isMine && (
                   <MyGallery experienceId={experienceId} city={city} photo={single} />
                  )}

                  <div className={classes.singleImage}>
                    <img src={single} className="img-fluid" alt="" />
                    <div>
                      {album &&
                        album.map((single: any, key: any) => {
                          return (
                            <div
                              key={key}
                              className={
                                currentIndex === key
                                  ? "bullet active"
                                  : currentIndex < key
                                  ? "bullet small"
                                  : "bullet"
                              }
                            ></div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
          </Swiper>
        </LightgalleryProvider>
      </div>
    </Fragment>
  );
};

export default React.memo(Gallery);
