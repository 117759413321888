import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { List, WindowScroller } from 'react-virtualized';

type Props = {
  data: any[];
  renderItem: (item: any) => JSX.Element;
  loadMore: () => void;
  totalResults: number;
  page: number;
};

const InfiniteScroller: React.FC<Props> = ({
  data,
  renderItem,
  loadMore,
  totalResults,
  page,
}) => {
  const [observedItem, setObservedItem] = useState('');
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    if (page === 1) {
      setObservedItem('');
      setItems([]);
      setItems(data);
      return;
    }
    if (
      JSON.stringify(items[items.length - 1]) !==
      JSON.stringify(data[data.length - 1])
    ) {
      setItems([...items, ...data]);
    }
  }, [data, items, page, totalResults]);

  const observeElement = useCallback(
    (element: HTMLElement | null) => {
      if (!element) {
        return;
      }
      if (totalResults <= items.length) {
        return;
      }
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting === true) {
            if (totalResults > items.length) {
              loadMore();
            }
            observer.unobserve(element); // always unobserve the bottom element when we reach them
          }
        },
        { threshold: 1 },
      ); // threshold 1 - we track the bottom
      observer.observe(element);
    },
    [items, loadMore, totalResults],
  );

  useEffect(() => {
    if (!items.length) {
      return;
    }
    const id = (items.length - 1).toString();
    if (id !== observedItem) {
      // if this id has not changed, we know that we reached the bottom of all the Items
      setObservedItem(id);
      observeElement(document.getElementById(id));
    }
  }, [items, observeElement, observedItem]);

  const rowRenderer = ({ key, index, style }: any) => {
    return items[index] ? (
      <div style={style} key={key} id={index}>
        {renderItem(items[index])}
      </div>
    ) : null;
  };

  return (
    <>
    {/* @ts-ignore */}
      <WindowScroller>
        {({ isScrolling, onChildScroll, scrollTop }) => {
          return (
            // @ts-ignore
            <List
              autoHeight
              width={1}
              autoContainerWidth
              height={items.length * 200}
              rowCount={items.length}
              rowHeight={200}
              rowRenderer={rowRenderer}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              containerStyle={{
                width: '100%',
                maxWidth: '100%',
              }}
              style={{ width: '100%', outline: 'none' }}
            />
          );
        }}
      </WindowScroller>
      {totalResults !== items.length && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ padding: '30px' }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  );
};

InfiniteScroller.displayName = 'InfiniteScroller';

export default React.memo(InfiniteScroller);
