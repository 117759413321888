import {
  Grid,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  FormControl,
  FormLabel,
  TextField,
  FormHelperText,
  Paper,
  Checkbox,
  Chip,
  InputAdornment,
  Box,
} from "@material-ui/core";
// import Geocode from "react-geocode";
import useStyle from "./styles";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuthentification } from "hooks/auth";
import i18next from "i18next";
import { Experience } from "../../../types";
import { Spacer } from "../../atoms";
import { FormField } from "../../molecules";
import { countries } from "constants/countries";
import { WeekDayPicker, Select } from "react-rainbow-components";
import Badge from "@material-ui/core/Badge";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import { TourTypesArray } from "constants/TourTypes";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import TermsAndConditionsDialog from "../TermsAndConditionsDialog/TermsAndConditionsDialog";
import { DropzoneArea } from "material-ui-dropzone";
// import GooglePlaces from "components/molecules/Find/GooglePlaces";
import { useToasts } from "react-toast-notifications";
import Gallery from "components/molecules/Gallery";
export type Form = Experience;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
type Props = {
  experience?: Form;
  onSubmit: (values: any) => void;
  customErrors?: Partial<Form>;
  addPhotos?: (filesToUpload: any, photos: any) => Promise<any>;
};
const options = [
  { value: "", label: "Default" },
  { value: "es-MX", label: "Spanish" },
  { value: "en-US", label: "English" },
  { value: "ru-RU", label: "Russian" },
  { value: "ja-JP", label: "Japanese" },
];
const includingsList = [
  "drinks",
  "food tastings",
  "Entrance tickets",
  "Food & Drinks",
  "local drinks",
  "Wine",
  "coffee with me",
  "Museum Entrance",
  "Dinner",
  "Lunch",
  "Breakfast",
];
const interestList = [
  {
    title: "History Buffs",
    description: ["Cultural Heritage", "Castles", "Myths & Legends"],
  },
  {
    title: "Medina Doulicha",
    description: ["Local Products", "Tastings", "Must sees", "Souks"],
  },
  {
    title: "Style Icons",
    description: ["Personal Shopping", "THrift Markets", "Traditional"],
  },
  {
    title: "Adventures",
    description: ["Hiking", "Wildlife & NatureCamping", "Day Trips"],
  },
  {
    title: "Art Lovers",
    description: ["Architecture", "Street Art", "Galleries", "Arisanal"],
  },
];
const VenueForm: React.FC<Props> = ({
  onSubmit,
  customErrors,
  experience,
  addPhotos,
}) => {
  const { profile, userId } = useAuthentification();
  const classes = useStyle();
  const { handleSubmit, register, errors, control, setValue } = useForm<Form>({
    defaultValues: {
      ...experience,
      meetingPoint:experience?.meetingPoint,
      minAge: 18,
      country: experience ? experience.country : profile.country,
      city: experience ? experience.city : profile.city,
      localization: "English",
      maxLoad: experience ? experience.maxLoad : 5,
    },
  });
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [images, setImages] = React.useState<any>([]);
  // const [meetingPoint, setMeetingPoint] = React.useState<any>(experience?.meetingPoint);
  const [includings, setIncludings] = React.useState<any>([]);
  const [openTerms, setOpen] = React.useState<boolean>(false);
  type paperType = "paper" | "body";
  const [scrollTerms, setScroll] = React.useState<paperType>("paper");
  const handleClickOpen = (scrollType: any) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleCloseTerms = () => {
    setOpen(false);
  };

  const [locale, setLocale] = useState("en-US");
  const [days, setDays] = useState([]);
  const handleOnChange = (weekDays: any) => {
    setDays(weekDays);
  };
  const [getReasons, setReasons] = React.useState(
    experience?.getReasons || [
      "Connect with a passionate local from ...",
      "Get answers to your questions about ...",
      "Can be 100% personalized to your wishes ...",
    ]
  );
  const handleChangeReasons = (e: any) => {
    const index = parseInt(e.target.getAttribute("number"));
    let reasons = getReasons.slice();
    reasons[index] = e.target.value;
    setReasons([...reasons]);
  };
  const addMoreToReasons = () => {
    setReasons([...getReasons, ""]);
  };
  const [count, setCount] = React.useState(5);

  /**
   * Steps
   */
  const helperText = "fill in a step";
  const [steps, setSteps] = React.useState(
    experience?.steps || [
      {
        id: 1,
        title: "",
        description: "",
      },
      {
        id: 2,
        title: "",
        description: "",
      },
      {
        id: 3,
        title: "",
        description: "",
      },
      {
        id: 4,
        title: "",
        description: "",
      },
      {
        id: 5,
        title: "",
        description: "",
      },
    ]
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [errorr, setError] = React.useState(false);
  const handleNext = () => {
    if (
      steps[activeStep].title === "" ||
      steps[activeStep].description === ""
    ) {
      setError(true);
    } else {
      setError(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleAddOne = () => {
    setSteps([
      ...steps,
      {
        id: steps.length + 1,
        title: "",
        description: "",
      },
    ]);
  };
  const handleReset = () => {
    setSteps([
      {
        id: 1,
        title: "",
        description: "",
      },
      {
        id: 2,
        title: "",
        description: "",
      },
      {
        id: 3,
        title: "",
        description: "",
      },
      {
        id: 4,
        title: "",
        description: "",
      },
      {
        id: 5,
        title: "",
        description: "",
      },
    ]);
    setActiveStep(0);
  };
  // const parentCallback = (data: any) => {
  //   if (data !== null) {
  //     Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API || "");
  //     Geocode.fromAddress(data.description).then(
  //       (response) => {
  //         const { lat, lng } = response.results[0].geometry.location;
  //         setMeetingPoint({
  //           meetingPoint: data.description,
  //           lat,
  //           lng,
  //         });
  //       },
  //       (error) => {}
  //     );
  //   }
  // };
  const filter = createFilterOptions();
  const [interest, setInterest] = React.useState<any>(null);

  return (
    <Box m={4}>
      <form
        onSubmit={handleSubmit((v) => {
       if (experience) {
            onSubmit({
              ...v,
              getReasons,
              // meetingPoint,
              steps,
            });
          } else {
            onSubmit({
              ...v,
              interests: interest,
              // meetingPoint,
              getIncludings: includings,
              getReasons,
              availableDays: days,
              steps,
              images,
            });
          }
        })}
      >
        <Grid container spacing={2} alignContent="center" justifyContent="center">
          <Grid item xs={12}>
            {customErrors?.coverUrl && (
              <p style={{ color: "red" }}>{customErrors.coverUrl}</p>
            )}

            <FormField
              name="name"
              type="title"
              label={t("experience.title")}
              register={register}
              required
              error={errors.name?.message}
            />
            <Spacer height="10px" />
          </Grid>
          <Grid item xs={6}>
            <FormField
              name="country"
              type="select"
              control={control}
              langDomain={i18next.language === "fr" ? "countries" : ""}
              options={countries.map((r) => r.label)}
              label={t("experience.country")}
              register={register}
              required
              error={errors.country?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              name="city"
              type="city"
              control={control}
              setValue={setValue}
              label={t("experience.city")}
              register={register}
              required
              error={errors.city?.message}
            />
          </Grid>
          <Grid item xs={12}>
            {/* {
              experience &&  <TextField
              name="meetingPointText"
              disabled
              type="text"
              value={experience.meetingPoint?.meetingPoint}
            />
            } */}
            {/* <GooglePlaces
              parentCallback={parentCallback}
              isMeetingPoint="true"
              id="15"
            /> */}
                 <FormField
              name="meetingPoint"
              label={t("experience.meetingPoint")}
              register={register}
              required
              error={errors.meetingPoint?.message}
            />
            {/* {customErrors?.meetingPoint && (
              <p style={{ color: "red" }}>{customErrors.meetingPoint}</p>
            )} */}
            {/* <FormField
              type="hidden"
              name="meetingPoint"
              label=""
              register={register}
            /> */}
            <FormField
              type="hidden"
              name="maxLoad"
              label=""
              register={register}
            />
            <FormField
              type="hidden"
              name="localization"
              label=""
              register={register}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormField
              name="duration"
              label={t("experience.duration")}
              register={register}
              required
              error={errors.duration?.message}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormField
              name="price"
              type="price"
              required
              endAdornment={<InputAdornment position="start">$</InputAdornment>}
              label={t("experience.price")}
              register={register}
              error={errors.price?.message}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <span className="tabaaniWord">{t("experience.maxLoad")} </span>
            <span style={{ margin: "0px 20px" }}>
              <Badge color="secondary" badgeContent={count}>
                <AccessibilityIcon />{" "}
              </Badge>{" "}
            </span>
            <ButtonGroup>
              <Button
                aria-label="reduce"
                onClick={() => {
                  setCount(Math.max(count - 1, 0));
                  setValue("maxLoad", Math.max(count - 1, 0));
                }}
              >
                <RemoveIcon fontSize="small" />
              </Button>
              <Button
                aria-label="increase"
                onClick={() => {
                  if (count < 12) {
                    setCount(count + 1);
                    setValue("maxLoad", count + 1);
                  }
                }}
              >
                <AddIcon fontSize="small" />
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormField
              name="minAge"
              type="number"
              label={t("experience.minAge")}
              register={register}
              required
              error={errors.minAge?.message}
            />
          </Grid>
          <Grid item md={12}>
            <FormField
              name="category"
              label={t("experience.chooseTourtype")}
              type="checkbox"
              required
              langDomain="tourtypes"
              options={TourTypesArray}
              defaultOptions={experience?.category}
              register={register}
            />
          </Grid>
          <Spacer height="10px" />
          {!experience && (
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={interestList}
                disableCloseOnSelect
                onChange={(event, newValue: any) => {
                  if (typeof newValue === "string") {
                    setInterest({
                      title: newValue,
                    });
                  } else {
                    setInterest(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return filtered;
                }}
                getOptionLabel={(option: any) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title + option.description.join();
                }}
                renderOption={(option, { selected }) => {
                  return (
                    <div className={classes.interest}>
                      <div>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.title}
                      </div>
                      {option.description.map((desc: string) => {
                        return (
                          <div style={{ marginLeft: 15 }}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={true}
                              disabled
                            />
                            {desc}
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={
                        <Typography style={{ whiteSpace: "normal" }}>
                          {option.title}
                        </Typography>
                      }
                      {...getTagProps({ index })}
                      // disabled={index === 0}
                      style={{ height: "100%" }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Please add at least one Interest"
                    placeholder="Interests"
                  />
                )}
              />
            </Grid>
          )}

          <Spacer height="10px" />

          {!experience && (
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={includingsList}
                disableCloseOnSelect
                onChange={(event, newValue: any) => {
                  if (typeof newValue === "string") {
                    setIncludings({
                      title: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setIncludings(newValue.inputValue);
                  } else {
                    setIncludings(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push(params.inputValue);
                  }

                  return filtered;
                }}
                getOptionLabel={(option: any) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option;
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </React.Fragment>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={
                        <Typography style={{ whiteSpace: "normal" }}>
                          {option}
                        </Typography>
                      }
                      {...getTagProps({ index })}
                      // disabled={index === 0}
                      style={{ height: "100%" }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="What's included in the experience ?"
                    placeholder="Start typing ..."
                  />
                )}
              />
            </Grid>
          )}
          {!experience && (
            <Grid item xs={12} lg={6}>
              <div className="rainbow-m-vertical_x-large rainbow-flex rainbow-justify_end">
                <Typography variant="body2">localization</Typography>
                <Select
                  value={locale}
                  options={options}
                  onChange={(event: any) => {
                    setLocale(event.target.value);
                    setValue("localization", event.target.value);
                  }}
                />
              </div>
              <div className="rainbow-m-vertical_x-large weekday-picker ">
                {customErrors?.availableDays && (
                  <p style={{ color: "red" }}>{customErrors.availableDays}</p>
                )}

                <Typography variant="body2" id="available-days">
                  Select your available days
                </Typography>
                <WeekDayPicker
                  id="weekday-picker-5"
                  value={days}
                  locale={locale}
                  multiple
                  onChange={handleOnChange}
                />
              </div>
            </Grid>
          )}
          {/* ***************Stepper************** */}
          <Grid item xs={12} className="center">
            {customErrors?.steps && (
              <p style={{ color: "red" }}>{customErrors.steps}</p>
            )}
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              style={{ width: "100%" }}
            >
              {steps.map((step: any) => (
                <Step key={step.id}>
                  <StepLabel>{"Step " + step.id}</StepLabel>
                  <StepContent>
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                      <FormLabel component="legend" id="stepper">
                        What is your plan!
                      </FormLabel>
                      <TextField
                        error={errorr ? true : false}
                        id="standard-error-helper-text"
                        label="Title"
                        value={step.title}
                        onChange={(e) => {
                          const updatedItem = {
                            id: step.id,
                            title: e.target.value,
                            description: step.description,
                          };
                          setSteps(
                            steps.map((item: any) => {
                              return item.id === step.id ? updatedItem : item;
                            })
                          );
                        }}
                        style={{ width: "100%" }}
                        helperText={errorr ? "Incorrect entry." : "step ..."}
                      />
                      <TextField
                        variant="outlined"
                        label="description"
                        type="textarea"
                        multiline
                        value={step.description}
                        onChange={(e) => {
                          const updatedItem = {
                            id: step.id,
                            title: step.title,
                            description: e.target.value,
                          };
                          setSteps(
                            steps.map((item: any) => {
                              return item.id === step.id ? updatedItem : item;
                            })
                          );
                        }}
                        error={errorr ? true : false}
                        helperText={errorr ? "Incorrect entry." : "step ..."}
                        rows={6}
                        autoComplete="off"
                        fullWidth
                      />
                      <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>
                    <div>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          // className={classes.button}
                        >
                          {t("back")}
                        </Button>
                        <span>
                          {activeStep === steps.length - 1 ? (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.stepBtn}
                              >
                                {t("finish")}
                              </Button>

                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddOne}
                                className={classes.stepBtn}
                              >
                                {t("add_more")}
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              className={classes.stepBtn}
                            >
                              {t("next")}
                            </Button>
                          )}
                        </span>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper
                square
                elevation={0}
                //  className={classes.resetContainer}
              >
                <Typography>{t("steps_completed")}</Typography>
                <Button onClick={handleReset}>{t("reset")}</Button>
              </Paper>
            )}
          </Grid>

          <Spacer height="10px" />

          <Grid item xs={12}>
            <Typography style={{ width: "100%" }} variant="h5" gutterBottom>
              {t("add_reasons")}
            </Typography>
            {getReasons.map((placeholder, index) => {
              const inputProps = {
                number: index,
              };
              return (
                <TextField
                  inputProps={inputProps}
                  key={index}
                  style={{ width: "100%", margin: "5px 0px" }}
                  placeholder={placeholder}
                  onChange={handleChangeReasons}
                />
              );
            })}
            <Button
              color="primary"
              // className={classes.button}
              onClick={addMoreToReasons}
            >
              {t("add_more")}
            </Button>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormField
              name="description"
              label={t("experience.description")}
              type="textarea"
              required
              control={control}
              setValue={setValue}
              initialValue={experience?.description}
              register={register}
              error={customErrors?.description}
            />
            <Spacer height="10px" />
          </Grid>
          <Grid item xs={12}>
            {customErrors?.images && (
              <p style={{ color: "red" }}>{customErrors.images}</p>
            )}
            <span id="drag-and-drop"></span>
            <DropzoneArea
              filesLimit={10}
              acceptedFiles={["image/*"]}
              dropzoneText={t("experience.dragAndDrop")}
              onChange={(files) => {
                setImages(files);
              }}
            />
         
          </Grid>
          {experience && (
              <Grid item>
                <Button
                  fullWidth
                  className={classes.button}
                  variant="outlined"
                  onClick={() => {
                    if (images.length > 0) {
                      addPhotos &&
                        addPhotos(images, experience.photos)
                    } else {
                      addToast("Please add at least one image", {
                        appearance: "error",
                      });
                    }
                  }}
                >
                  {t("add_photos")}
                </Button>
              </Grid>
            )}
          {experience?.photos && (
            <Grid item xs={12}>
              <Gallery
                album={experience?.photos}
                isMine={experience.userId === userId}
                experienceId={experience?.id}
                city={experience?.city}
              />
            </Grid>
          )}

          <Grid item xs={12} className="center">
            <p style={{ fontSize: "14px", textAlign: "left" }}>
              {t("when_using")}
              <span
                className="tabaaniWord"
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "14px",
                }}
              >
                Tabaani
              </span>{" "}
              {t("you_accept")}
              <br />{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#feae20",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={handleClickOpen("paper")}
              >
                {t("terms_conditions")}
              </span>{" "}
              {t("and")}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#feae20",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={handleClickOpen("paper")}
              >
                {t("privacy_policy")}
              </span>
              .
            </p>
          </Grid>

          <TermsAndConditionsDialog
            openTerms={openTerms}
            handleCloseTerms={handleCloseTerms}
            scrollTerms={scrollTerms}
          />
          <Grid item>
            <Button
              type="submit"
              variant="outlined"
              fullWidth
              className={classes.button}
            >
              {experience ? "Submit Edit Experience" : "Submit"}
            </Button>
          </Grid>
        </Grid>
        <Spacer height="30px" />
      </form>
    </Box>
  );
};

VenueForm.displayName = "VenueForm";

export default React.memo(VenueForm);
