import { Avatar } from '@material-ui/core';
import React from 'react';
import { useAuthentification } from '../../../hooks';
import useStyle from './styles';

type Props = {
  showPicture?: boolean;
  size?: number;
};

const Small: React.FC<Props> = ({ showPicture, size }) => {
  const style = useStyle({ size });
  const { initials,profilePicture } = useAuthentification();
  return (
    <Avatar
      src={showPicture ? profilePicture : undefined}
      className={style.avatarSmall}
    >
            {initials[0]}

    </Avatar>
  );
};

Small.displayName = 'UserAvatarSmall';

export default React.memo(Small);
