import { createSlice } from "@reduxjs/toolkit" ;
export const chatsSlice = createSlice({
    name:"user",
    initialState: {
        chatId:null,
        chatName :null
    },
    reducers: {
        setChat: (state,action) =>{
            state.chatId = action.payload.chatId;
            state.chatName = action.payload.chatName;
        },

    },
});
export const {setChat} = chatsSlice.actions;
export const selectChatName = (state) =>state.chats.chatName;
export const selectChatId = (state) =>state.chats?.chatId;
export default chatsSlice.reducer;

