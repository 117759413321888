// @ts-nocheck
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "redux/reducers/rootReducer";
import { User } from "types";

type Props = {
  id?: string;
  city?: string;
};
export const useHosts = (props: Props = {}) => {
  const { city } = props;
  const hosts = useSelector<RootState>((state) => state.firestore?.data?.hosts);
  const loaded = isLoaded(hosts);
  const hostsList = useSelector<RootState>(
    (state) =>
      state.firestore?.ordered?.hosts?.filter(
        (host) => host.firstName && host.city
      ) || []
  ) as User[];

  const searchedHosts = useCallback(
    (text:any) => {
      return (
        (hostsList as [])?.filter((host: any) => {
          if (text) {
            // return host?.fullName
            //   ?.toLowerCase()
            //   ?.startsWith(text.toLowerCase());
            return host?.fullName
                ?.toLowerCase()
                ?.includes(text.toLowerCase())
          }
          return false;
        }) ?? []
      );
    },
    [hostsList]
  );
  function compare(a: any, b: any) {
    if (a.points < b.points) {
      return -1;
    }
    if (a.points > b.points) {
      return 1;
    }
    return 0;
  }
  const getTenHosts =
    hostsList?.slice().sort(compare).reverse().slice(0, 15) || [];

  const getHostsByCity =
    hostsList?.filter(
      (_usr: any) =>
        _usr?.city?.toLowerCase() === city?.toLowerCase() ||
        city?.toLowerCase().includes(_usr?.city?.toLowerCase()) ||
        _usr?.city?.toLowerCase().includes(city?.toLowerCase())
    ) || [];

  useFirestoreConnect({
    collection: "users",
    storeAs: "hosts",
    where: [["isHost", "==", true]],
  });
  return { getHostsByCity, getTenHosts, searchedHosts, loaded };
};
