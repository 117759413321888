import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import styled from 'styled-components';

type Props = {
  className?: string;
  width?: string;
  height?: string;
  isLogoHeaderMenu?: boolean;
  style?: any;
  logo?: string;
  logoActive?: string;
  to?: string;
};

const StyledLogo = styled.img<Props>`
  width: ${({ width = '200px' }) => width};
  height: ${({ height = 'auto' }) => height};
`;

const Logo: React.FC<Props> = ({
  width,
  height,
  isLogoHeaderMenu,
  logo: logoProp,
  to = '/',
  logoActive,
  className,
}) => {
  const { pathname } = useLocation();
  const isActive = ("/home" && pathname.indexOf("/home") > -1);
  return (
    <Link to={to}>
      <StyledLogo
        className={className}
        src={isActive ? logoActive : (logoProp || "/images/logo7.png")}
        alt="Tabaani.co"
        width={(logoActive && isLogoHeaderMenu) ? "80px" : width}
        height={height}
      />
    </Link>
  );
};

Logo.displayName = 'Logo';

export default React.memo(Logo);
