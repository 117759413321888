import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import { Spacer } from '../../atoms';

import { useStyle } from './styles';

type Props = {
  cover?: string;
  logo?: string;
  renderActions?: (containerClassName?: string) => false | JSX.Element;
};

const PageCoverAndLogo: React.FC<Props> = ({ logo, cover, renderActions }) => {
  const { isMobile } = useMediaQuery();
  const globalStyles = useGlobalStyles();
  const style = useStyle();
  useEffect(() => {
    let image = document.getElementById('cover');
    let logo = document.getElementById('logo');
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    logo?.addEventListener('error', error => {
      return image?.setAttribute('src', "/images/defaultLogo.jpg");
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    image?.addEventListener('error', error => {
      return image?.setAttribute('src', "/images/defaultCover.jpg");
    });
    return () => {};
  }, []);
  return (
    <Grid item md={12} className={globalStyles.relative}>
      <img
        src={cover || "/images/defaultCover.jpg"}
        className={isMobile ? style.coverMobile : style.cover}
        alt="cover"
        id="cover"
      />
      <img
        src={logo || "/images/defaultLogo.jpg"}
        className={style.logo}
        alt="logo"
        id="logo"
      />
      {renderActions && renderActions(isMobile ? style.ctaSectionMobile : style.ctaSection)}
      <Spacer height="30px" />
    </Grid>
  );
};

PageCoverAndLogo.displayName = 'PageCoverAndLogo';

export default React.memo(PageCoverAndLogo);
