import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import Layout from 'components/organs/Layout/Layout';

type Props = {
  children?: any;
};

const PageWrapper: React.FC<Props> = ({
  children,
}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
      <Layout headerColor="black">
 
        {children}

      </Layout>
  );
};

PageWrapper.displayName = 'PageWrapper';

export default React.memo(PageWrapper);
