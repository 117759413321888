import React from "react";
import { useExperience } from "hooks";
import { Popconfirm } from "antd";
import DeleteIcon from "@material-ui/icons/Delete";
type Props = {
  experienceId?: any;
  city?: any;
  photo?: any;
};
const MyGallery: React.FC<Props> = ({ experienceId, city, photo }) => {
  const { deletePhotoFromExperience } = useExperience({ id: experienceId });
  return (
    <Popconfirm
      title="Sure to delete?"
      onConfirm={() => {
        deletePhotoFromExperience(photo, experienceId, city);
      }}
    >
      <button
        type="button"
        value="Cancel"
        style={{
          position: "absolute",
          right: "80px",
          top: "10px",
          background: "transparent",
          border: "none",
          cursor: "pointer",
        }}
      >
        <DeleteIcon fontSize="large" color="primary" />
      </button>
    </Popconfirm>
  );
};

export default MyGallery;
