// @ts-nocheck
import React from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { UserAvatar } from "..";
import { useAuthentification, useGlobalStyles, useOrder } from "../../../hooks";
import { useToggleState } from "../../../hooks/ui/useToggleState";
import { Spacer } from "../../atoms";
import CustomDropdown from "components/atoms/CustomDropdown/CustomDropdown.js";
// import LoginBox from '../LoginBox';
import useStyle from "./styles";
import { Link } from "react-router-dom";
// import ContentLoader from "react-content-loader";
import { Apps } from "@material-ui/icons";
import i18next from "i18next";
import LoginBox from "../LoginBox";

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const ProfileDrawerMenu: React.FC<Props> = ({ open, onClose, onOpen }) => {
  const style = useStyle();
  const { t } = useTranslation();
  const globalStyle = useGlobalStyles();
  const [loginVisible, toggleLogin] = useToggleState(true);
  const {
    isAuthenticated,
    profile,
    logout,
    userId,
    // loaded,
    // profilePicture,
    isHost,
    withRole,
  } = useAuthentification();
  const classes = useStyle();
  const { currentOrder } = useOrder();
  const iOS =
    (process as any).browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const getLanguage = () => {
    switch (i18next.language) {
      case "fr":
        return "French";
      case "en":
        return "English";
      case "de":
        return "Deutsch";
      default:
        return "English";
    }
  };
  const changeLanguageTrigger = (e: any) => {
    const languageCode = e.target.value;
    i18next.changeLanguage(languageCode, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      t("key"); // -> same as i18next.t
    });
    // props.dispatch(changeLanguage(languageCode));
  };
  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      onOpen={onOpen}
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
      }}
    >
      <Box
        className={[
          globalStyle.safeAreaBottom,
          globalStyle.safeAreaTop,
          globalStyle.flexColumn,
          style.wrapper,
        ].join(" ")}
        justifyContent="space-between"
      >
        <Box p={2}>
          <Box
            className={globalStyle.flex}
            alignItems="center"
            justifyContent="start"
          >
            <UserAvatar.Small showPicture={true} size={60} />
            <Box
              paddingLeft={2}
              height={60}
              className={globalStyle.flexColumn}
              justifyContent="space-between"
            >
              {isAuthenticated && (
                <>
                  <Typography variant="body1">{profile.fullName}</Typography>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    href="/profile"
                  >
                    View Profile
                  </Button>
                </>
              )}

              {!isAuthenticated && (
                <Box
                  height={60}
                  className={globalStyle.flex}
                  alignItems="center"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    // @ts-ignore
                    onClick={() => toggleLogin()}
                  >
                    Do you already have an account? Log in
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          {
          !isAuthenticated ? (
            <Collapse in={loginVisible}>
              <Box>
                <Spacer height="16px" />
                <Divider />
                <Spacer height="16px" />
                <LoginBox small onLoginCallback={toggleLogin} />
              </Box>
            </Collapse>
          ) : (
            <>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                buttonText={t("header.hosting")}
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent",
                }}
                buttonIcon={Apps}
                dropdownList={[
                  <Link
                    to={`/my-host`}
                    color="transparent"
                    className={classes.navLink}
                  >
                    {t("header.hosting_profile")}
                  </Link>,
                  <Link to="/addExperience" className={classes.navLink}>
                    {t("header.addExperience")}
                  </Link>,
                ]}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                buttonText={getLanguage()}
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent",
                }}
                buttonIcon={Apps}
                dropdownList={[
                  // <option
                  //   href="#"
                  //   value="fr"
                  //   className={classes.dropdownLink}
                  //   onClick={(e) => changeLanguageTrigger(e)}
                  // >
                  //   {" "}
                  //   French
                  // </option>,
                  <option
                    value="en"
                    className={classes.dropdownLink}
                    onClick={(e) => changeLanguageTrigger(e)}
                  >
                    {" "}
                    English
                  </option>,
                  // <option
                  //   href="#"
                  //   value="de"
                  //   className={classes.dropdownLink}
                  //   onClick={(e) => changeLanguageTrigger(e)}
                  // >
                  //   {" "}
                  //   Deutsch
                  // </option>,
                ]}
              />
            </ListItem>
            {currentOrder && (
              <ListItem className={classes.listItem}>
                <Link
                  to="/escrow"
                  color="transparent"
                  className={classes.navLink}
                >
                  {t("header.myTrip")}
                </Link>
              </ListItem>
            )}

            <ListItem className={classes.listItem}>
              <Link to="/blog" color="transparent" className={classes.navLink}>
                {t("header.blog")}
              </Link>
            </ListItem>
          </List>
          <ListItem className={classes.listItem}>
            <Link
              to="/profile"
              color="transparent"
              className={classes.navLink}
            >
              {t("edit_profile")}
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            {isHost === false ? (
              <Link to="/becomeHost" className={classes.navLink}>
                {t("become_a_host")}
              </Link>
            ) : (
              <Link to="/Host-Dashboard" className={classes.navLink}>
                {t("dashbord")}
              </Link>
            )}
          </ListItem>
          {withRole(["supervisor", "admin"]) && (
            <ListItem className={classes.listItem}>
              <Link to="/admin4565" className={classes.navLink}>
                {t("Admin")}
              </Link>
            </ListItem>
          )}
          <ListItem className={classes.listItem}>
            <Link to="/Wishlist" className={classes.navLink}>
              {t("wishlist")}
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="/help" className={classes.navLink}>
              {t("help")}
            </Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="#" className={classes.navLink}>
              {getLanguage()}
            </Link>
          </ListItem>
          <Box p={2}>
            <Button
              size="small"
              variant="outlined"
              color="default"
              fullWidth
              onClick={logout}
            >
              Log out
            </Button>
          </Box>
          </>
       )}
      </Box>
      </Box>
    </SwipeableDrawer>
  );
};

ProfileDrawerMenu.displayName = "ProfileDrawerMenu";

export default React.memo(ProfileDrawerMenu);
