// @ts-nocheck
import React, { createContext, lazy } from "react";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

import { ToastProvider } from "react-toast-notifications";
import {useGlobalStyle } from "hooks";
import { ScrollRestoration } from "./components/atoms";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import dayjs from "dayjs";
import fr from "./redux/translations/french";
import en from "./redux/translations/english1";
import de from "./redux/translations/deutch";

// import makeAdminComponentViewScreen from "views/makeAdminComponentView/makeAdminComponentView";
const EditVenue = lazy(() => import("views/EditVenue"));
const Home = lazy(() => import("views/Home"));
const TourType = lazy(() => import("views/TourType"));
const NewProfile = lazy(() => import("views/NewProfile"));
const Experience = lazy(() => import("./views/Experience"));
const RegisterVenue = lazy(() => import("./views/RegisterVenue"));
const LandingPage = lazy(() => import("./views/LandingPage/LandingPage"));
const City = lazy(() => import("./views/City"));
const ProfilePage = lazy(() => import("./views/ProfilePage/ProfilePage.js"));
const HostProfilePage = lazy(() =>
  import("./views/ProfilePage/HostProfilePage.js")
);
const MyHostProfilePage = lazy(() =>
  import("./views/ProfilePage/MyHostProfilePage.js")
);
const LoginPage = lazy(() => import("./views/LoginPage/UserSignIn.js"));
const Blogs = lazy(() => import("./views/blogs"));
const UserSignUp = lazy(() => import("./views/LoginPage/UserSignUp.js"));
const About = lazy(() => import("./views/About"));
const productsPage = lazy(() => import("./views/Components/productsPage"));
const productsPage2 = lazy(() => import("./views/Components/productsPage2"));
// const Payment = lazy(() => import("./views/Payment/PaymentPage"));
const PaymentTozeur = lazy(() => import("./views/PaymentTozeur/PaymentPage"));
const NewPayment = lazy(() => import("./views/NewPayment/PaymentPage"));
const Wishlist = lazy(() => import("./views/WishList"));
const BecomeHostForm = lazy(() => import("./views/becomeHost"));
const AdminPage = lazy(() => import("./views/AdminPage"));
const HostAdminPage = lazy(() => import("./views/HostAdminPage"));
const Forbidden = lazy(() => import("./views/Forbidden"));
const UnhandledError = lazy(() => import("./views/UnhandledError"));
// const NotFound = lazy(() => import("./views/NotFound"));
const SearchPage = lazy(() => import("./views/SearchPage"));
// require("tailwindcss/dist/base.css");
// require("antd/dist/antd.css");

require("./assets/css/antd.css");
require("./assets/css/bootstrap.min.css");
require("./assets/scss/styles.scss");
require("swiper/css/swiper.css");
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const Contact = lazy(() => import("./views/Contact"));
const Cart = lazy(() => import("./views/Cart"));
const MainChats = lazy(() => import("./chats/MainChats"));
const ExperienceChat = lazy(() => import("experienceChat/ExperienceChat"));
const PrivacyPolicy = lazy(() => import("views/privacypolicy/PrivacyPolicy"));
const Terms = lazy(() => import("views/terms/Terms"));
const Help = lazy(() => import("views/helpCenter/Help"));
const Faqs = lazy(() => import("views/helpCenter/faqs/SimpleWithSideImage"));

// import AdminRoute from "./AdminRoute"

i18next
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    // lng: "en",
    debug: false,
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      de: {
        translation: de,
      },
    },
    fallbackLng: "en",
    supportedLngs: ["en", "fr", "de"],
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lng",
    },
  });

dayjs.locale(i18next.language);

type GlobalStyleContext = {
  globalStyle: { [key: string]: any };
};
export const GlobalStyleContext = createContext<GlobalStyleContext>({
  globalStyle: {},
});
const App: React.FC = () => {
  const globalStyle = useGlobalStyle();
  return (
    <GlobalStyleContext.Provider value={{ globalStyle }}>
      <IonApp>
        <IonReactRouter>
          <ToastProvider placement="bottom-left" autoDismiss>
            <ScrollRestoration>
              <IonRouterOutlet>
              <Route path="/home" component={Home} />
                <Route path="/contact" component={Contact} />
                <PrivateRoute path="/addExperience" component={RegisterVenue} />
                <PrivateRoute
                  path="/edit-experience/:experience"
                  component={EditVenue}
                />
                <PrivateRoute path="/cart" component={Cart} />
                <Route path="/signin" component={LoginPage} />
                <Route path="/signup" component={UserSignUp} />
                <Route path="/becomeHost" component={LandingPage} />
                <Route path="/blog" component={Blogs} />
                <Route path="/search/:category" component={SearchPage} />
                <Route path="/search" component={SearchPage} />
                <PrivateRoute path="/profile" component={ProfilePage} />
                <Route path="/newProfile/:id" component={NewProfile} />
                <PrivateRoute path="/my-host" component={MyHostProfilePage} />
                <PrivateRoute path="/host/:id" component={HostProfilePage} />
                <Route path="/about" component={About} />
                <PrivateRoute
                  path="/becomeHostForm"
                  component={BecomeHostForm}
                />
                <PrivateRoute
                  roles={["admin", "supervisor"]}
                  path="/admin4565"
                  component={AdminPage}
                />
                <PrivateRoute
                  path="/Host-Dashboard"
                  component={HostAdminPage}
                />
                <Route path="/experiences/:id" component={Experience} />
                <PrivateRoute path="/saved" component={Wishlist} />
                <PrivateRoute path="/payment" component={NewPayment} />
                <Route path="/city/:name" component={City} />
                <Route path="/tourType/:tourTypeName" component={TourType} />
                <Route
                  path="/allExperiences/:city/:maxload/:date"
                  component={productsPage2}
                />
                <Route exact path="/allExperiences" component={productsPage} />
                <PrivateRoute path="/chats" component={MainChats} />
                <PrivateRoute path="/inbox" component={MainChats} />
                <PrivateRoute path="/escrow" component={ExperienceChat} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms" component={Terms} />
                <Route path="/Help" component={Help} />
                <Route path="/faqs/:type" component={Faqs} />
                {/* <Route path="/makeAdmin" component={makeAdminComponentViewScreen} /> */}


                <Route exact path="/" render={() => <Redirect to="/home" />} />
                <Route path="/forbidden" component={Forbidden} />
                <Route path="/error" component={UnhandledError} />
                <Route render={() => <Redirect to="/home" />} />
              </IonRouterOutlet>
            </ScrollRestoration>
          </ToastProvider>
        </IonReactRouter>
      </IonApp>
    </GlobalStyleContext.Provider>
  );
};

export default App;
