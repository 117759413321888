import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Experience } from 'types';
import { useAuthentification } from './useAuthentification';

type Entity = Experience;

export const useEditGuard = (entity: Entity,loadedExperience:boolean) => {
  const { userId, loaded,withRole } = useAuthentification();
  const history = useHistory();
  useEffect(() => {
    if (loadedExperience && !entity){
      history.push('/');

    }
    if (entity && loaded) {
      if (!userId) {
        history.push('/');
      }
      if (
        !(
          entity?.userId === userId || withRole(['admin','supervisor'])
        )
      ) {
        history.push('/');
      }
    }
  }, [entity, userId, loaded, history,loadedExperience,withRole]);
};
