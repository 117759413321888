import { Box, BoxProps, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useGlobalStyles } from '../../../hooks';
import useStyle from './style';

type Props = {
  label?: string;
  boxProps?: BoxProps;
  children: React.ReactNode;
};

const LabeledBox: React.FC<Props> = ({ label, children, boxProps = {} }) => {
  const globalStyle = useGlobalStyles();
  const style = useStyle();
  return (
    <Box
      className={[globalStyle.flex, globalStyle.relative].join(' ')}
      {...boxProps}
    >
      {label && <Typography className={style.label}>{label}</Typography>}
      <Paper variant="outlined" className={globalStyle.size100}>
        <Box p={2}>{children}</Box>
      </Paper>
    </Box>
  );
};

LabeledBox.displayName = 'LabeledBox';

export default React.memo(LabeledBox);
