import React, { useCallback } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { IonIcon, isPlatform } from '@ionic/react';
import { Button } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import { logoFacebook, logoGoogle, logoTwitter } from 'ionicons/icons';
import { cfaSignIn, mapUserToUserInfo } from 'capacitor-firebase-auth';
import { UserInfo } from 'firebase/app';
import { useMediaQuery } from '../../../hooks';
import '@codetrix-studio/capacitor-google-auth';
import useStyles from './styles';

type Props = {
  provider: 'facebook' | 'google' | 'twitter';
  scopes?: string[];
  children?: string | JSX.Element | JSX.Element[];
  onLoginCallback?: () => void;
};

const SocialLoginButton: React.FC<Props> = ({
  provider,
  scopes = [],
  onLoginCallback,
}) => {
  const { isMobile } = useMediaQuery();
  const firebase = useFirebase();
  // const { t } = useTranslation();
  const classes = useStyles();
  const icons = {
    facebook: <IonIcon icon={logoFacebook} />,
    twitter: <IonIcon icon={logoTwitter} />,
    google: <IonIcon icon={logoGoogle} />,
  };

  const signIn = useCallback(async () => {
    const isCapacitor = isPlatform('capacitor');
    console.log({ isCapacitor });
    if (isCapacitor && provider === 'google') {
      cfaSignIn('google.com')
        .pipe(mapUserToUserInfo())
        .subscribe((user: UserInfo) => {
          if (user && onLoginCallback) {
            onLoginCallback();
          }
        });
      return;
    }
    firebase
      .login({
        provider,
        scopes,
        type: isMobile ? 'redirect' : 'popup',
      })
      .then(() => {
        if (onLoginCallback) {
          onLoginCallback();
        }
      });
  }, [firebase, isMobile, onLoginCallback, provider, scopes]);

  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      onClick={signIn}
      endIcon={icons[provider]}
      className={classes[provider]}
      fullWidth
    >
      Login With
    </Button>
  );
};

SocialLoginButton.displayName = 'SocialLoginButton';

export default React.memo(SocialLoginButton);
