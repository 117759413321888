import React from 'react';
import { ProfileDrawerMenu, UserAvatar } from '../../organs';
import MenuItem from '../Menu/MenuItem';

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};
const AccountButton: React.FC<Props> = ({ open, onOpen, onClose }) => {
  return (
    <>
      <MenuItem label="Profile" onClick={onOpen} icon={<UserAvatar.Small />} />
      <ProfileDrawerMenu open={open} onOpen={onOpen} onClose={onClose} />
    </>
  );
};

AccountButton.displayName = 'AccountButton';

export default React.memo(AccountButton);
