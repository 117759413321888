export const sortArrayOfStrings = (data: string[]): string[] => {
  if (!data.length) {
    return data;
  }
  const sortedGuests = [...data].sort((a, b) => {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return sortedGuests;
};

export const sortObjectByValues = <T>(
  data: T[],
  sortedProperty: string,
): T[] => {
  if (!data.length) {
    return data;
  }
  const sortedRegistrations = [...data].sort((a, b) => {
    if (!a[sortedProperty] || !b[sortedProperty]) {
      return 0;
    }
    const nameA = a[sortedProperty].toUpperCase();
    const nameB = b[sortedProperty].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return sortedRegistrations;
};
