import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router";
import { useAuthentification } from "../auth";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useOrders } from "./useOrders";
import { setChat } from "redux/reducers/chatSlice";
import { useChats } from "hooks/chats";
import { useNotifications } from "hooks/notifications";
export const useOrder = (props = {}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const history = useHistory();
  const firestore = useFirestore();
  const { userId, profile } = useAuthentification();
  const { sendMessage } = useChats();
  const { myOrders,myHostOrders, myOrdersLoaded, myHostOrdersLoaded,loadingComplete } = useOrders();
  const currentOrder = myOrders?.filter((order) => order.status === "pending")[0] || myHostOrders?.filter((order) => order.status === "pending")[0];
  const lastCanceledOrder = myOrders?.filter((order) => order.status === "canceled")[0] || myHostOrders?.filter((order) => order.status === "canceled")[0];
  const { addNotification } = useNotifications();
  const experience = useSelector((state) => state.cartData[0]);
  const currency = useSelector((state) => state.currencyData);
  const cancelOrder = useCallback(
    async (orderId, chatId) => {
      setLoading(true);
      if (experience) {
        firestore.collection("orders").doc(orderId).update({
          escrowStatus: "canceled",
          status: "canceled",
          dateUpdated: new Date(),
        });
        addNotification(
          "Your last trip is canceled",
          "Your last trip is canceled",
          userId
        );
        addNotification(
          "Your last trip is canceled",
          "Your last trip is canceled",
          (await firestore.collection("orders").doc(orderId).get()).data()
            .hostId
        );
        sendMessage(chatId, "Trip canceled successfuly", "admin", "error");
        setLoading(false);
      }
    },
    [firestore, sendMessage, experience, addNotification, userId]
  );
  const payOrder = useCallback(
    async (orderId, chatId, hostId) => {
      setLoading(true);
      firestore.collection("orders").doc(orderId).update({
        escrowStatus: "paid",
        status: "paid",
      });
      addNotification(
        "Your trip has been paid successfully",
        "Your last trip has been paid successfully",
        userId
      );
      addNotification(
        "Your last trip is paid",
        "Your last trip is paid",
        hostId
      );
      sendMessage(chatId, "Trip Paid successfuly", "admin", "success");
      setLoading(false);
    },
    [firestore, sendMessage, addNotification, userId]
  );

  const addOrder = useCallback(async () => {
    setLoading(true);
    if (experience) {
      const orderRef = firestore.collection("/orders").doc();
      const ref = firestore.collection("chats").doc(orderRef.id);
      await orderRef.set({
        orderId: orderRef.id,
        userId,
        chatId: ref.id,
        hostId: experience?.userId,
        experienceId: experience?.id,
        experience,
        currency,
        totalPrice: experience.price * experience.peopleCount,
        peopleCount: experience.peopleCount,
        status: "pending",
        timestamp: new Date(),
      });
      const id = orderRef.id;
      const chat = {
        orderId: orderRef.id,
        id,
        receiverHasRead: false,
        lastMessageTimestamp: Date.now(),
        users: [userId, experience?.userId],
        names: [profile.fullName || profile.email, experience?.hostName],
        messages: [
          {
            message:
              "Hi " + experience?.hostName + " i want to book your experience",
            sender: userId,
            timestamp: Date.now(),
          },
        ],
      };
      ref.set(chat).then((res) => {
        dispatch(
          setChat({
            chatId: id,
            chatName: experience?.hostName,
          })
        );
      });
      setLoading(false);
      history.push(`/escrow`);
    }
  }, [
    firestore,
    history,
    userId,
    experience,
    dispatch,
    profile.email,
    profile.fullName,
    currency,
  ]);

  const addTozeurOrder = useCallback(
    async ({ count }) => {
      setLoading(true);
      const orderRef = firestore.collection("/orders").doc();
      await orderRef.set({
        orderId: orderRef.id,
        userId,
        experienceId: "pIeW5Msa2g5dwjUgZLa7",
        currency: "TND",
        totalPrice: 650 * count + 2,
        peopleCount: count,
        status: "pending",
        timestamp: new Date(),
      });
      const id = orderRef.id;
      setLoading(false);
      history.push(`/payment`);
    },
    [
      firestore,
      history,
      userId,
      experience,
      dispatch,
      profile.email,
      profile.fullName,
      currency,
    ]
  );

  return {
    addOrder,
    addTozeurOrder,
    cancelOrder,
    payOrder,
    experience,
    loading,
    currentOrder,
    lastCanceledOrder,
    myOrdersLoaded,
    myHostOrdersLoaded,
    loadingComplete
  };
};
