import { useSelector } from "react-redux";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useAuthentification } from "../auth";
import { fetchedChats } from "redux/reducers/chatReducer";
import { useDispatch } from "react-redux";
import { setChat } from "redux/reducers/chatSlice";
// import { RootState } from 'redux/reducers/rootReducer';
import { useCallback } from "react";
import { useHistory } from "react-router";
export const useChats = ()=> {
    const { userId,profile } = useAuthentification();
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const db = useFirestore();
    const history = useHistory();
    const fetched = useSelector(fetchedChats);



    const sendMessage = useCallback(
      async (selectedId:any,message:any,sender:any,type:any) => {
            if(type){
              db.collection("chats").doc(selectedId).update({
                messages: firebase.firestore.FieldValue.arrayUnion({
                  sender: sender || userId,
                  message: message,
                  type,
                  timestamp: Date.now(),
                }),
                receiverHasRead: false,
                lastMessageTimestamp: Date.now(),
              })
            }else{
              db.collection("chats").doc(selectedId).update({
                messages: firebase.firestore.FieldValue.arrayUnion({
                  sender: sender || userId,
                  message: message,
                  timestamp: Date.now(),
                }),
                receiverHasRead: false,
                lastMessageTimestamp: Date.now(),
              })
            }
      },
      [db,userId,firebase.firestore.FieldValue],
    )

    const createChat = useCallback(
        async (body:any,receiverId:any,hostFullName:any,hostEmail:any) => {
            const senderId = userId;
            if (
              fetched.filter((_chat:any) => _chat.users.includes(receiverId)).length > 0
            ) {
              dispatch(
                setChat({
                  chatId: fetched.filter((_chat:any) => _chat.users.includes(receiverId))[0]
                    .id,
                  chatName: hostFullName,
                })
              );
              history.push(`/chats`);
            } else {
              const ref = db.collection("chats").doc();
              const id = ref.id;
              const chat = {
                id,
                receiverHasRead: false,
                lastMessageTimestamp: Date.now(),
                users: [senderId, receiverId],
                names: [profile.fullName || profile.email, hostFullName || hostEmail],
                messages: [
                  {
                    message: body,
                    sender: senderId,
                    timestamp: Date.now(),
                  },
                ],
              };
              firebase
                .firestore()
                .collection("chats")
                .add(chat)
                .then((res) => {
                  dispatch(
                    setChat({
                      chatId: id,
                      chatName: hostFullName,
                    })
                  );
                  history.push(`/chats`);
                });
            }
        },
        [db,dispatch,fetched,firebase,history,profile.email,profile.fullName,userId],
      )


    return {
        createChat,
        sendMessage
    };

    // if (
    //   fetched.filter((_chat) => _chat.users.includes(receiverId)).length > 0
    // ) {
    //   dispatch(
    //     setChat({
    //       chatId: fetched.filter((_chat) => _chat.users.includes(receiverId))[0]
    //         .id,
    //       chatName: host.fullName,
    //     })
    //   );
    //   history.push(`/chats`);
    // } else {
    //   const ref = db.collection("chats").doc();
    //   const id = ref.id;
    //   const chat = {
    //     id,
    //     receiverHasRead: false,
    //     lastMessageTimestamp: Date.now(),
    //     users: [senderId, receiverId],
    //     names: [profile.fullName || profile.email, host.fullName || host.email],
    //     messages: [
    //       {
    //         message: messageRef.current.value,
    //         sender: senderId,
    //         timestamp: Date.now(),
    //       },
    //     ],
    //   };
    //   firebase
    //     .firestore()
    //     .collection("chats")
    //     .add(chat)
    //     .then((res) => {
    //       dispatch(
    //         setChat({
    //           chatId: id,
    //           chatName: host.fullName,
    //         })
    //       );
    //       history.push(`/chats`);
    //     });
    // }
}






