import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { SearchResult } from 'types';
import {
  CardListWidget,
  ExperiencesList,
  // NewsList,
  // OrganizationsList,
  // VenuesList,
  VirtualizedList,
} from '../../molecules';

type Props = {
  results: SearchResult[];
  loading?: boolean;
};

const SearchResultList: React.FC<Props> = ({ results, loading }) => {
  const renderItem = useCallback((result: any) => {
    const collections = {
      experiences: <ExperiencesList.Item experience={result} label={result.type} />,
      // venue: <VenuesList.Item venue={result} label={result.type} />,
      // news: <NewsList.Item newsItem={result} label={result.type} />,
      // organization: (
      //   <OrganizationsList.Item organization={result} label={result.type} />
      // ),
    };
    return collections[result.collection];
  }, []);

  if (loading) {
    return (
      <Box marginTop={3}>
        <VirtualizedList
          data={new Array(3).fill(true)}
          renderItem={() => <CardListWidget.Skeleton withLogo withActions />}
        />
      </Box>
    );
  }
  return (
    <Box marginTop={3}>
      <VirtualizedList data={results} renderItem={renderItem} />
    </Box>
  );
};

SearchResultList.displayName = 'SearchResultList';

export default React.memo(SearchResultList);
