import { useCallback, useState } from 'react';
import { functions } from '../../rrf.config';
import { useAuthentification } from '../auth';

export const useSendEmail = () => {
  const send = functions.httpsCallable('sendEmail');
  const {
    profile: { email },
  } = useAuthentification();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const sendEmail = useCallback(
    ({ to = email, subject, html, text }) => {
      setLoading(true);
      send({ to, subject, html, text })
        .then(() => setSuccess(true))
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    },
    [email, send],
  );
  return { loading, success, error, sendEmail };
};
