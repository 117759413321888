import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useAuthentification } from "../auth";
import { RootState } from "redux/reducers/rootReducer";
import { useCallback } from "react";
import { useChats } from "hooks/chats";

export const useTickets = () => {
  const { userId } = useAuthentification();
  const firestore = useFirestore();
  const { createChat } = useChats();
  const tickets = useSelector<RootState>(
    (state) => state.firestore?.ordered.tickets || []
  );
  const addTicket = useCallback(
     (email:any, fullName:any, subject:any, body:any) => {
      const ticketRef = firestore.collection("/tickets").doc();
      let ticket;
      if(!email || !fullName || !subject || !body){
        return {
          error:true,
          message:"Error while submitting your request. Please check your entries"
        }
      }
      if (userId) {
        ticket = {
          id: ticketRef.id,
          email,
          fullName,
          body,
          subject,
          userId,
        };
        createChat(subject+"   "+body,"support","Support","support@tabaani.co")
      } else {
        ticket = {
          id: ticketRef.id,
          email,
          fullName,
          body,
          subject,
        };
      }
      ticketRef.set(ticket);
      return {
        error:false,
        message: "Thank you! we received your request and we'll process it shortly"
      }
    },
    [firestore,createChat,userId]
  );
  /**
   * TODO
   * Add Ticket  callback
   * Delete Ticket callback
   */
  let data = {};
  // getting data from fake collection to prevent permission warning whenever the user is not logged in because we can't use hooks conditionally
  if (userId) {
    data = {
      collection: "tickets",
      storeAs: "myTickets",
      where: [["userId", "==", userId || null]],
    };
  } else {
    data = { collection: "tickets", storeAs: "tickets" };
  }
  useFirestoreConnect([data]);
  useFirestoreConnect([{ collection: "tickets" }]);

  return {
    tickets,
    addTicket,
  };
};
