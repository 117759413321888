import { createAction } from 'typesafe-actions';

type InAppHistory = string[];

export const updateInAppHistory = createAction('action/UPDATE_INAPP_HISTORY')<
  InAppHistory
>();

export const addToInAppHistory = createAction('action/ADD_TO_INAPP_HISTORY')<
  string
>();
