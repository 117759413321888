
    import { makeStyles } from "@material-ui/core";
    export default makeStyles((theme) => ({
      productLargeImageWrapper: {
        position: "relative",
        "& .ht-swiper-button-nav": {
          visibility: "hidden",
          opacity: 0,
          color: "#333",
          border: "none",
          background: "none",
          "& i": {
            fontSize: "40px",
          },
        },
        "& :hover": {
          "& .ht-swiper-button-nav": {
            visibility: "visible",
            opacity: 1,
          },
        },
        "& .react_lightgallery_item": {
          position: "absolute",
          top: "10px",
          right: "30px",
          zIndex: "2",
          opacity: 0.3,
          "&:hover": {
            opacity: 1,
          },
          "& button": {
            padding: 0,
            border: "none",
            background: "none",
          },
        },
        "&slider": {
          "& .ht-swiper-button-nav": {
            fontSize: "50px",
            visibility: "hidden",
            padding: 0,
    
            transition: "0.3s",
    
            opacity: 0,
            color: "#333",
            border: "none",
            background: "none",
          },
    
          "& :hover": {
            "& .ht-swiper-button-nav": {
              visibility: "visible",
              opacity: 1,
            },
          },
        },
    
        "& .single-image": {
          "& img": {
            width: "100%",
          },
        },
      },
      singleImage: {
        overflow: "hidden",
        "& img": {
          width: "100%",
          height: "350px",
          objectFit: "cover",
        },
      },
}));

    