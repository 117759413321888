import { makeStyles } from '@material-ui/core';

export default makeStyles({
  collapseInfo: {
    fontWeight: 700,
    lineHeight: 1,
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      padding: '9px',
    },
  },
  checkboxContainer: {
    marginTop: '10px',
    marginBottom: '20px',
  },
});
