import { makeStyles } from '@material-ui/core';

export default makeStyles({
    root: {
        minHeight: "382px",
        "& a":{
          color:"black !important"
        },
        position:"relative",
        "& .draft":{
          position:"absolute",
          top:0,
          zIndex:5
        }
      },
      media: {
        height: 185,
      },
      body: {
        fontSize: "14px",
        lineHeight: "1.5",
        color: "#4a4a4a",
        marginTop: "10px",
        marginBottom: "10px",
        minHeight: "152px",
        "& a":{
          color:"#4a4a4a"
        }
      },
      cardActions: {
        display: "flex",
        justifyContent: "space-between",
        
      },
      timestamp: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#718096",
        paddingLeft: "16px",
      },
      readMore: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        marginRight: "15px",
        color: "#2d3748",
        paddingRight: "24px",
        paddingTop: "8px",
      },
});
