import React, { MouseEvent } from 'react';
import { IconButton, Popover, Tooltip } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { useTranslation } from 'react-i18next';
import { Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import Share from './Share';

export type Icons =
  | 'facebook'
  | 'messenger'
  | 'email'
  | 'instapaper'
  | 'telegram'
  | 'twitter'
  | 'viber'
  | 'whatsapp'
  | 'copyUrl'
  | 'all';

type Props = {
  url?: string;
  iconSize?: number;
  icons?: Icons[];
  renderButton?: (
    openPopOver: (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void,
  ) => React.ReactElement;
};

const ShareButton: React.FC<Props> = ({
  url = window.location.href,
  iconSize,
  icons,
  renderButton,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { Share: SharePlugin } = Plugins;

  const openPopOver = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    const isCapacitor = isPlatform('capacitor');
    if (!isCapacitor) {
      setAnchorEl(e.currentTarget);
      return;
    }
    SharePlugin.share({
      url,
      title: 'Share',
      text: 'Share from Tilllate',
      dialogTitle: 'Share with buddies',
    });
  };

  const closePopOver = () => {
    setAnchorEl(null);
  };

  const openedPopOver = Boolean(anchorEl);
  const id = openedPopOver ? 'simple-popover' : undefined;

  return (
    <>
      {renderButton ? (
        renderButton(openPopOver)
      ) : (
        <Tooltip title={t('shared.share')!}>
          <IconButton onClick={e => openPopOver(e)} size="medium">
            <ShareIcon />
          </IconButton>
        </Tooltip>
      )}
      <Popover
        id={id}
        open={openedPopOver}
        anchorEl={anchorEl}
        onClose={closePopOver}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Share url={url} iconSize={iconSize} icons={icons} />
      </Popover>
    </>
  );
};

ShareButton.displayName = 'ShareButton';

export default ShareButton;
