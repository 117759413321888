const initState = {
    chats:[],
  newMessageCounter: 0,
  fetching: false,
  fetched: false,
  error: null,
};

const chatReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_CHATS": {
      return {...state, fetching: true}
    }
    case "FETCH_CHATS_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_CHATS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        chats:action.payload.chats,
        newMessageCounter: action.payload.newMessageCounter,
      }
    }
    
   default : 
   return state;
  }
};
export const fetchedChats = (state) =>state.newMessageCounter.chats;

export default chatReducer;
