import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers/rootReducer';
import { useCities } from 'hooks';
import { selectRegion } from 'redux/actions/configurations/selectRegion';

type Props = {};

const RegionSelect: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const {cities} = useCities()
  const regionInStore = useSelector(
    (state: RootState) => state?.configuration?.region,
  );
  const [selectedRegion, setSelectedRegion] = useState(
    regionInStore ? regionInStore.id : '',
  );

  useEffect(() => {
    if (!selectedRegion && regionInStore) {
      setSelectedRegion(regionInStore.id);
      return;
    }
    if (regionInStore && selectedRegion !== regionInStore.id) {
      setSelectedRegion(regionInStore.id);
    }
  }, [regionInStore, selectedRegion]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (event.target.value !== '') {
        const region = cities.find(
          r => r.id === (event.target.value as string),
        );
        if (region) {
          setSelectedRegion(region.id)
          dispatch(selectRegion(region));
        }
      } else {
        dispatch(selectRegion(null));
        setSelectedRegion('');
      }
    },
    [dispatch,cities],
  );
  return (
    <FormControl size="small" fullWidth variant="outlined">
      <Select
        value={selectedRegion}
        onChange={handleChange}
        displayEmpty
        placeholder='All Cities'
      >
        <MenuItem value="">All Cities</MenuItem>
        {cities?.map(r => (
          <MenuItem key={r.id} value={r.id}>
            {r.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

RegionSelect.displayName = 'RegionSelect';

export default React.memo(RegionSelect);
