import {
  Slide,
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Box,
  IconButton,
  TextField,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import { DividerWithSpace } from '..';
import { useMediaQuery } from '../../../hooks';
import useDebounce from '../../../hooks/ui/useDebounce';
import useStyles from './styles';
import { FilterCheckboxType } from 'types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const search = (
  options: FilterCheckboxType,
  searchFor: string,
): FilterCheckboxType => {
  if (!searchFor) {
    return options;
  }
  return Object.entries(options)
    .filter(([_, values]) => {
      return values?.name?.toLowerCase()?.includes(searchFor) || values.value;
    })
    .reduce((r, [key, values]) => ({ ...r, [key]: values }), {});
};

type Props = {
  title: string;
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  options: FilterCheckboxType;
  renderCheckBox: (key: string, label: string) => JSX.Element;
  visibleOptionsNumber: number;
};

const CheckboxDialog: React.FC<Props> = ({
  title,
  dialogOpen,
  setDialogOpen,
  options,
  renderCheckBox,
  visibleOptionsNumber,
}) => {
  const { isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const classes = useStyles();
  const [state, setState] = useState(options);
  const [searchFor, setSearchFor] = useState('');
  const debouncedSearchedValue = useDebounce(searchFor.trim(), 300);

  useEffect(() => {
    setState(search(options, debouncedSearchedValue.toLowerCase()));
  }, [debouncedSearchedValue, options]);

  const closeDIalog = () => {
    setDialogOpen(false);
    setSearchFor('');
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDIalog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {title}
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeDIalog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DividerWithSpace space="5px" />
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t('general.search')}
          className={classes.textField}
          value={searchFor}
          onChange={e => setSearchFor(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon color="inherit" />,
          }}
        />
        <Grid
          container
          justifyContent="space-between"
          className={classes.checkboxContainer}
        >
          {Object.entries(state)
            .slice(0, 20)
            .map(([key, value]: [string, { value: boolean; name: string }]) => {
              return (
                <Grid key={key + value.name} item xs={12} md={6}>
                  {renderCheckBox(key, value.name)}
                </Grid>
              );
            })}
          {Object.keys(state).length > visibleOptionsNumber && <Box>....</Box>}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

CheckboxDialog.displayName = 'CheckboxDialog';
export default CheckboxDialog;
