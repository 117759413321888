import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import DraftsIcon from "@material-ui/icons/Drafts";
import useStyles from "./style";

type Props = {
  title: string;
  img?: string;
  id?: string;
  desc?: string;
  timestamp?: string;
  isDraft?: boolean;
};
const BlogCard: React.FC<Props> = ({
  title,
  img,
  desc,
  timestamp,
  id,
  isDraft,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      {isDraft && (
        <span className="draft">
          <DraftsIcon style={{ color: "#FFAF20" }} fontSize="large" />
        </span>
      )}
      {
        <Link
          to={
            isDraft ? `/blog/create-blog/${id}` : `/blog/article/${id}`
          }
          // onClick={() => {
          //   if (isDraft) {
          //     history.push("/blog/create-blog/" + id);
          //   } else {
          //     history.push("/blog/article/" + id);
          //   }
          // }}
        >
          <CardActionArea>
            <CardMedia className={classes.media} image={img} title={title} />
            <CardContent className={classes.body}>
              <Typography gutterBottom variant="h5" component="h2">
                {title.length > 20 ? title.substr(0, 20) + "..." : title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {desc}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>
      }
      <CardActions className={classes.cardActions}>
        <span color="primary" className={classes.timestamp}>
          {timestamp}
        </span>
        <Link to={"/blog/article/" + id}>
          <span color="primary" className={classes.readMore}>
            Read more
          </span>
        </Link>
      </CardActions>
    </Card>
  );
};
export default BlogCard;
