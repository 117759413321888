import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestore,isLoaded } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { defaultAvatar } from "constants/consts";
import { getNameInitials } from 'utils/ui/getNameInitials';
export const useAuthentification = () => {

  const firebase = useFirebase();
  const functions = firebase.functions();
  const addAdminRole = functions.httpsCallable('updateExperienceIds')
  const addRole = functions.httpsCallable('addRole')
  const firestore = useFirestore();
  const history = useHistory();
  const isAuthenticated = useSelector(
    state => state.firebase.auth && !state.firebase.auth.isEmpty,
  );
  const userId = useSelector((state) => state.firebase.auth.uid);
  const profile = useSelector(state => state.firebase.profile);
  const initials = getNameInitials(profile.fullName);
  const updateProfile = useCallback(
    (entry) => {
      if (!userId) {
        return null;
      }
      return firestore.collection('/users').doc(userId).update(entry);
    },
    [userId, firestore],
  );
  // const isAdmin = process.env.NODE_ENV === 'development' ? false : (profile.token && profile.token.claims?.admin === true ? true : false)
  const userRole = profile.token && profile.token.claims?.role
  const isAdmin = profile.token && profile.token.claims?.admin === true ? true : false
  const profilePicture = (profile.picture && profile.picture[profile?.picture?.length - 1]) || profile.photoUrl  || defaultAvatar;
  const profilePictures = profile.picture || [];
  const accountCreationDate = firebase.auth().currentUser?.metadata.creationTime
  const periodWithTabaani = Math.ceil(Math.abs(new Date() - new Date(accountCreationDate)) / (1000*60*60*24))
  // const loaded = useSelector((state) => state.firebase.auth.isLoaded);
  const loaded = isLoaded(profile)
  const test = useSelector((state) => state.firebase.auth);
  const emailVerified = useSelector((state) => state.firebase.auth.emailVerified);
  const isDeactivated = profile.deactivated;
  const isHost = profile.isHost;
  const logout = useCallback(() => firebase.logout(), [firebase]);
  const withRole = useCallback((roles) => profile.token?.claims?.role && roles.indexOf(profile.token?.claims?.role) !== -1, [profile]);
  const profileCompletePercent =(100/21) * (Object.keys(profile).length - 3) 

  const sendVerificationEmail = useCallback(
    () => {
      if (!userId) {
        return null;
      }
      firebase.auth().currentUser.sendEmailVerification().then(()=>console.log("mail sent"))
    },
    [userId, firebase],
  );
  const makeAdmin = useCallback(
    (email) => {
      addAdminRole({email}).then(result =>{
        console.log(result)
      })
      
    },
    [addAdminRole],
  );

    const addRoleCb = useCallback(
    (email,role) => {
      addRole({email,role}).then(result =>{
        // console.log(result)
      })
      
    },
    [addRole],
  );

  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: '/experiences/pleW5Msa2g5dwjUgZLa7',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) =>{
          if(authResult.additionalUserInfo.isNewUser){
          const pictureArr = [authResult.user.photoURL]
          const user = {
            id:authResult.user.uid,
            fullName:authResult.user.displayName,
            email:authResult.user.email,
            picture:pictureArr,
            rate:4,
            level:"newbie",
            isHost:false,
            points:5,
            responseTime:100,
            badges:[]
          }
          history.push({ pathname: '/', state: { newUserCreated: true } });
          firestore.collection('/users').doc(user.id).set(user)
          .then(err=>{
          })
          .catch(err=>{
            history.push('/error');
          });
        }else{
          history.push('/')
        }
        }
      },
  }

  return {
    profile,
    loaded,
    userId,
    isAdmin,
    logout,
    isDeactivated,
    initials,
    updateProfile,
    sendVerificationEmail,
    accountCreationDate,
    periodWithTabaani,
    addAdminRole,
    makeAdmin,
    addRoleCb,
    withRole,
    profilePicture,
    profilePictures,
    userRole,
    isAuthenticated,
    isHost,
    profileCompletePercent,
    test,
    emailVerified,
    uiConfig
  };
};
