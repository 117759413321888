// @ts-nocheck
import React, {useMemo, useEffect } from 'react';
import { Box } from '@material-ui/core';
import {
  BlurOn,
  LibraryBooksTwoTone,
  PhotoLibraryTwoTone,
} from '@material-ui/icons';
import { useLocation } from 'react-router';
import {
  ProfileDrawerMenu,
  UserAvatar,
} from '../../organs';
import { useGlobalStyles, useMediaQuery } from '../../../hooks';
import { Logo } from '../../atoms';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import useStyle from "./style";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import SearchIcon from '@material-ui/icons/Search';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import logoHome from "../../../assets/icons/home.svg";
import logoHomeActive from "../../../assets/icons/home.png";
import AccountButton from '../AccountButton';
import { useToggleState } from '../../../hooks/ui/useToggleState';
import MenuItem from './MenuItem';

type Props = {
  tabBar?: boolean;
};



const Menu: React.FC<Props> = ({ tabBar }) => {
  const style = useStyle();
  const { isMobile } = useMediaQuery();
  const [
    openProfileDrawer,
    toggleOpenProfileDrawer,
    ,
    toggletoggleOpenProfileDrawerOff,
  ] = useToggleState(false);
  // const [openMapDialog, toggleOpenMapDialog] = useToggleState(false);
  // const [target, setTarget] = useState<HTMLElement | null>();
  const globalStyle = useGlobalStyles();
  const location = useLocation();

  const mobileMenu = useMemo(
    () => (
      <>
        <>
        {/* @ts-ignore */}
          <Box paddingY={1} onClick={toggleOpenProfileDrawer}>
            <UserAvatar.Small showPicture={true} size={38} />
          </Box>
          <ProfileDrawerMenu
            open={openProfileDrawer}
            onOpen={toggleOpenProfileDrawer}
            onClose={toggleOpenProfileDrawer}
          />
        </>
      </>
    ),
    [openProfileDrawer, toggleOpenProfileDrawer],
  );
  const desktopMenu = useMemo(
    () => (
      <>
        <MenuItem label="News" href="/news" icon={<LibraryBooksTwoTone />} />
        <MenuItem label="Events" href="/events" icon={<BlurOn />} />
        <MenuItem
          label="Photos"
          href="/photos"
          icon={<PhotoLibraryTwoTone />}
        />
        {/* <MenuItem
          label="Region"
          onClick={onOpenMapDialog}
          icon={<MapTwoTone />}
        /> */}
        <AccountButton
          open={openProfileDrawer}
          onOpen={toggleOpenProfileDrawer}
          onClose={toggleOpenProfileDrawer}
        />
      </>
    ),
    [
      // onCloseMapDialog,
      // onOpenMapDialog,
      // openMapDialog,
      openProfileDrawer,
      // target,
      toggleOpenProfileDrawer,
    ],
  );
  const tabBarMenu = useMemo(
    () => (
      <Box className={globalStyle.flex} justifyContent="space-evenly">
        <MenuItem label="Explore" href="/explore" icon={<SearchIcon style={{color:"#808080", fontSize:"32px"}} />} />
        <MenuItem label="Saved" href="/saved" icon={<FavoriteBorderIcon style={{color:"#808080", fontSize:"32px"}} />} />
        <MenuItem
          className={style.homeButton}
          label="Trips"
          icon={
            <Logo logo={logoHome} logoActive={logoHomeActive} width="32px" className={style.homeLogo} />
          }
        />
        <MenuItem
          label="inbox"
          href="/inbox"
          icon={<ChatBubbleOutlineIcon style={{color:"#808080", fontSize:"32px"}} />}
        />
        <MenuItem
          label="Profile"
          href="/profile"
          icon={<PermIdentityIcon style={{color:"#808080", fontSize:"32px"}} />}
        />
   
      </Box>
    ),
    [
      globalStyle.flex,
      style.homeButton,
      style.homeLogo,
    ],
  );

  useEffect(() => {
    if (location) {
      toggletoggleOpenProfileDrawerOff();
      // onCloseMapDialog();
    }
  }, [
    location,
    // onCloseMapDialog,
    toggletoggleOpenProfileDrawerOff,
  ]);

  if (tabBar) {
    return tabBarMenu;
  }

  return isMobile ? mobileMenu : desktopMenu;
};

Menu.displayName = 'Menu';

export default React.memo(Menu);
