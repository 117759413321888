import React from 'react';
import {
  AppBar,
  Box,
  Divider,
  List,
  ListItem,
  makeStyles,
  SwipeableDrawer,
  Typography,
} from '@material-ui/core';
import { useGlobalStyles } from '../../../hooks';
import { Logo, Spacer } from '../../atoms';
import { FooterMenuLink, SectionHeader } from '../../molecules';
import logo from '../../../assets/img/logo_w.png';

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const useStyle = makeStyles({
  wrapper: { width: 400, maxWidth: '80vw', height: '100%' },
});

const FooterDrawerMenu: React.FC<Props> = ({ open, onClose, onOpen }) => {
  const style = useStyle();
  const globalStyle = useGlobalStyles();

  const iOS =
    (process as any).browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      onOpen={onOpen}
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        className: globalStyle.bgSecondaryLight,
      }}
    >
      <Box
        className={[
          globalStyle.safeAreaBottom,
          globalStyle.safeAreaTop,
          globalStyle.flexColumn,
          style.wrapper,
        ].join(' ')}
        justifyContent="space-between"
      >
        <Box p={2}>
          <Box
            className={globalStyle.flex}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              className={globalStyle.flexColumn}
              justifyContent="center"
              textAlign="center"
            >
              <Logo logo={logo} width="80%" to="/home" />
            </Box>
          </Box>
          <Spacer height="16px" />
          <Divider className={globalStyle.bgLightOpaque} />
          <Spacer height="16px" />
          <Box>
            <List>
              <SectionHeader
                title="Menu"
                titleVariant="body1"
                variant="light"
              />
              <ListItem dense button>
                <FooterMenuLink title="Venues" to="/venues" />
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
              <ListItem dense button>
                <FooterMenuLink title="Organizations" to="/organizations" />{' '}
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
              <ListItem dense button>
                <FooterMenuLink title="Artists" to="/artists" />{' '}
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
              <ListItem dense button>
                <FooterMenuLink title="Contact us" to="/contact" />{' '}
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
              <ListItem dense button>
                <FooterMenuLink title="About us" to="/about" />{' '}
              </ListItem>
              <Divider
                variant="middle"
                className={globalStyle.bgLightVeryOpaque}
              />
            </List>
          </Box>
        </Box>
      </Box>
      <AppBar position="relative">
        <Spacer height="5px" />
        <Typography align="center" variant="subtitle2">
          All Rights Reserved.
        </Typography>
        <Spacer height="5px" />
      </AppBar>
    </SwipeableDrawer>
  );
};

FooterDrawerMenu.displayName = 'FooterDrawerMenu';

export default React.memo(FooterDrawerMenu);
