import React from 'react';
import { useMediaQuery } from '../../../hooks';

type Props = {
  children: React.ReactNode;
};

const Container: React.FC<Props> = ({ children }) => {
  const { isMobile } = useMediaQuery();
  return (
    <div
      className="container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: isMobile
          ? 'calc(env(safe-area-inset-bottom) + 55px)'
          : undefined,
      }}
    >
      {children}
    </div>
  );
};

Container.displayName = 'Container';

export default React.memo(Container);
