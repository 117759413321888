// @ts-ignore
import { useCallback,useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { useAuthentification } from '../auth';
import { useSendEmail } from '../email';
import { useMedia } from "../media";
import { useBlogs } from './useBlogs';
export const useBlog = (props = {}) => {
  const firestore = useFirestore();
  const { id } = props;
  const { userId,profile,profilePicture } = useAuthentification();
  const { sendEmail } = useSendEmail();
  const [loading, setLoading] = useState(false);
  const { uploadMedia, getMediaData } = useMedia();
  const {blogsList} = useBlogs();
  const blog = blogsList.filter((blog ) => blog.id === id)[0];
  const registerBlog = useCallback(
    async (isDraft,coverFile, title,body,raw,storyType ) => {
        let coverUrl = null;
      setLoading(true);
      const blogRef = await firestore
        .collection("/blogs")
        .add({ title,
          body,
          raw,
          storyType,
        userId,
        isDraft,
        timestamp:Date.now(),
        fullName: profile.fullName || "Tabaani Blogger",
        hostPhoto: profilePicture });

      if (!coverFile) {
        setLoading(false);
      }

      const coverRef = await uploadMedia({
        path: `/images/blogs/${blogRef.id}`,
        file: coverFile,
      });
      const coverMedia = await getMediaData(coverRef.path);
      coverUrl = coverMedia?.url || null;
      await firestore
        .collection("/blogs")
        .doc(blogRef.id)
        .update({ coverUrl });
        setLoading(false);
        if(!isDraft){
        const html = `
        Hi, ${profile.fullName} </br>
        Thank you for sharing your story with us , 
        We are reviewing your blog and it will be live soon !
      `;
      // @ts-ignore
  sendEmail({
    subject: 'Thanks for Sharing your story with Tabaani!',
    html,
  });
}
      return { id: blogRef.id };
      // Your Blog Article has been published Congrats!'
    },
    [firestore, getMediaData, uploadMedia,profile.fullName,profilePicture,userId,sendEmail]
  );


  
  return { loading, registerBlog,blog };
};
