import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useAuthentification } from "../auth";
import { months } from "constants/months";
export const useOrders = (props = {}) => {
  const { userId } = useAuthentification();
  const localUserId =localStorage.getItem('tabaani-user-id');
  const myOrders = useSelector(
    (state) => state.firestore?.ordered?.myorders || []
  );
  const myOrdersLoaded = isLoaded( useSelector(
    (state) => state.firestore?.data?.myorders || []
  ))
  
  const myHostOrdersLoaded = isLoaded( useSelector(
    (state) => state.firestore?.data?.myhostorders || []
  ))
  const loadingComplete = myOrdersLoaded && myHostOrdersLoaded;
  const myHostOrders = useSelector(
    (state) => state.firestore?.ordered?.myhostorders || []
  );
  const confirmedOrders = myHostOrders.filter(
    (order) => order.status === "confirmed"
  );
  const canceledOrders = myHostOrders.filter(
    (order) => order.status === "canceled"
  );
  const onHoldOrders = myHostOrders.filter(
    (order) => order.status === "onhold"
  );
  const preBookedOrders = myHostOrders.filter(
    (order) => order.status === "pending"
  );
  const haveDate = myHostOrders.filter(
    (order) => order.startDate !== undefined
  );
  // { startDate: '2021-03-08T09:45', endDate: '2021-03-08T11:00', title: 'Meeting' }
  const calendar = myHostOrders.map((order) => {
    return {
      startDate: new Date(order?.startDate?.toDate()),
      endDate: new Date(order?.endDate?.toDate()),
      title: order?.experience.name,
    };
  });
  const getHostOrdersByMonth = () => {
    let bymonths = [];
    myHostOrders.length > 0 &&
      myHostOrders.map((order, index) => {
        const month = months[new Date(order.timestamp * 1000).getMonth()];
        if (bymonths.filter((m) => m.name === month).length > 0) {
          bymonths.filter((m) => m.name === month)[0].orders = [
            ...bymonths.filter((m) => m.name === month)[0].orders,
            order,
          ];
        } else {
          bymonths.push({ name: month, orders: [order] });
        }
        return null;
      });
    return bymonths;
  };
  useFirestoreConnect({
    collection: "orders",
    orderBy: [["timestamp", "desc"]],
    storeAs: "myorders",
    where: [["userId", "==", userId || localUserId]],
  });
  useFirestoreConnect({
    collection: "orders",
    orderBy: [["timestamp", "desc"]],
    storeAs: "myhostorders",
    where: [["hostId", "==", userId || localUserId]],
  });
  return {
    myOrders,
    myHostOrders,
    myOrdersLoaded,
    myHostOrdersLoaded,
    getHostOrdersByMonth,
    confirmedOrders,
    canceledOrders,
    onHoldOrders,
    preBookedOrders,
    haveDate,
    calendar,
    loadingComplete
  };
};
