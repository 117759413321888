import { useCallback } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { useAuthentification } from '../auth';
import { useSendEmail } from '../email';
type Props = {
  id?: string;
};
export const useVerification = (props: Props = {}) => {
  const firestore = useFirestore();
  const { id } = props;
  const { updateProfile, userId }: any = useAuthentification();
  const { sendEmail } = useSendEmail();

  const verifyId = useCallback(async () => {
    try {
      const decodedId = atob(id || '');

      if (decodedId !== userId) {
        return 'Your confirmation links seems broken, please contact the administrator';
      }
      const userRef = await firestore.collection('users_meta').doc(decodedId);
      return userRef.get().then(doc => {
        if (doc.exists) {
          if (doc.data()?.verified) {
            return 'Already verified';
          } else {
            updateProfile({ verified: true });
            return 'Thank you for verifying your account';
          }
        } else {
          return 'Your confirmation links seems broken, please contact the administrator';
        }
      });
    } catch (error) {
      return 'Your confirmation links seems broken, please contact the administrator';
    }
  }, [id, userId, firestore, updateProfile]);

  const sendAccountCreationEmail = useCallback(
    (email:any, newId:any) => {
      const html = `
          Hi, thanks for registering to Tilllate,</br> please confirm your registration by clicking the following link
          <a href="${window.location.origin + '/confirm/' + btoa(newId)
        }" >verify your account now </a>
        `;
        // @ts-ignore
      sendEmail({
        to: email,
        subject: 'Please confirm your registration',
        html,
      });
    },
    [sendEmail],
  );
  const resendVerificationEmail = useCallback(() => {
    const html = `
          Hi,</br> please confirm your email by clicking the following link
          <a href="${window.location.origin + '/confirm/' + btoa(userId)
      }" >verify your account now </a>
        `;
    // @ts-ignore
    sendEmail({
      subject: 'Please confirm your email',
      html,
    });
  }, [sendEmail, userId]);
  return { verifyId, sendAccountCreationEmail, resendVerificationEmail };
};
