import React from "react";
import { connect } from "react-redux";
import { setCurrency } from "redux/actions/currencyActions";
import HeaderLinks from "./HeaderLinks";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import useStyle from "./styles";
type Props ={
  currency?:any;
  setCurrency?:any;
  currentLanguageCode?:any;
  color?:any;
  changeColorOnScroll?:any;
  leftLinks?:any;
  fixed?:any;
  absolute?:boolean;
  dispatch?:any;
}
const Header:React.FC<Props> = ({
  currency,
  setCurrency,
  currentLanguageCode,
  color,
  changeColorOnScroll,
  leftLinks,
  fixed,
  absolute,
  dispatch
}) => {

  let enabled = React.useRef(true);
  const classes = useStyle();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const headerColorChange = React.useCallback((event:any) => {
    const windowsScrollTop = event.detail.scrollTop;
    const headers = document.body.getElementsByTagName("header");
    if (headers[0]) {
      for(let i = 0; i < headers.length; i++){
        if (windowsScrollTop > changeColorOnScroll.height) {
          headers[i]
            .classList.remove(classes[color]);
            headers[i]
            .classList.add(classes[changeColorOnScroll.color]);
        } else {
          headers[i]
            .classList.add(classes[color]);
            headers[i]
            .classList.remove(classes[changeColorOnScroll.color]);
        }
      }
    }
  }, [changeColorOnScroll, classes, color]);

  if (changeColorOnScroll) {
    window.document.body.addEventListener("ionScroll", headerColorChange);
  }
  React.useEffect(() => {
    window.document.body.addEventListener("ionScroll", (event:any) => {
      if (enabled.current) {
        if (event.detail.scrollTop > 50 )
          setIsScrolled(true);
        else setIsScrolled(false);
      }
    });

    return () => {
      enabled.current = false;

      window.removeEventListener("scroll", function (event:any) {
        if (enabled.current) {
          if (event.detail.scrollTop > 50)
            setIsScrolled(true);
          else setIsScrolled(false);
        }
      });
    };
  }, [enabled]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logo =
    isScrolled || color === "black" ? (
      <Link className="navbar-brand" to="/">
        <img
          src="/images/logo3.png"
          alt="logo of FollowMe"
          width="57px"
          height="auto"
        />
      </Link>
    ) : (
      <Link className="navbar-brand" to="/">
        <img className="logo1" src="/images/logo7.png" alt="logo of FollowMe" height="auto" width="57px" />
      </Link>
    );
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.headerShadow]:!isScrolled
  });

  return (
    <AppBar
      className={appBarClasses}
    >
      <Toolbar className={classes.container}>
        {leftLinks !== undefined ? logo : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            // @ts-ignore
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            logo
          )}
        </div>
        {/* @ts-ignore */}
        <Hidden smDown implementation="css">
          <HeaderLinks
            currency={currency}
            setCurrency={setCurrency}
            mobileOpen={mobileOpen}
          />
        </Hidden>
        {/* @ts-ignore */}
        <Hidden mdUp>
          <IconButton
            color={color === "black" ? "secondary" : "inherit"}
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      {/* @ts-ignore */}
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            <HeaderLinks
              currency={currency}
              setCurrency={setCurrency}
            />
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

const mapStateToProps = (state:any) => {
  return {
    currency: state.currencyData,
  };
};

const mapDispatchToProps = (dispatch:any) => {
  return {
    setCurrency: (currencyName:string) => {
      dispatch(setCurrency(currencyName));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Header));
