import { useCallback } from "react";
import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
export const useExperiences = (props = {}) => {
  const { userId, orderBy, asc, query } = props;

  const experiences = useSelector(
    (state) => state.firestore?.data?.experiences
  );

  const experiencesList = useSelector(
    (state) =>
      state.firestore?.ordered?.experiences?.filter(
        (exp) => exp.approved === true
      ) || []
  );
  const pendingExperiences = useSelector(
    (state) =>
      state.firestore?.ordered?.experiences?.filter(
        (exp) => exp.approved === false || exp.approved === undefined
      ) || []
  );
  let allExperiences = useSelector(
    (state) => state.firestore?.ordered?.experiences || []
  );
  if (query !== "" && query !== undefined) {
    allExperiences = allExperiences.filter((exp) =>
      exp.name.toLowerCase().includes(query.toLowerCase())
    );
  }
  const loaded = isLoaded(experiences);

  const searchedExperiences = useCallback(
    (text) => {
      return (
        experiencesList?.filter((experience) => {
          if (text) {
            // return experience?.name
            //   ?.toLowerCase()
            //   ?.startsWith(text.toLowerCase());
            // includes instead of startsWith
            return experience?.name
              ?.toLowerCase()
              ?.includes(text.toLowerCase());
              
          }
          return false;
        }) ?? []
      );
    },
    [experiencesList]
  );

  const getExperiencesByCity = useCallback(
    (city) => experiencesList.filter((experience) => experience.city === city),
    [experiencesList]
  );
  const getExperiencesByTourType = useCallback(
    (tourType) =>
      experiencesList.filter((experience) =>
        experience.category.includes(tourType)
      ),
    [experiencesList]
  );
  const getExperiencesByUser = experiencesList.filter((experience) => {
    return (
      experience.users?.filter((_usr) => _usr.userId === userId).length > 0
    );
  });
  const getAllExperiencesByUser = allExperiences.filter((experience) => {
    return (
      experience.users?.filter((_usr) => _usr.userId === userId).length > 0
    );
  });
  let totalViews = 0;
  getExperiencesByUser.map((exp, index) => {
    totalViews += parseInt(exp.views?.length);
    return null;
  });
  useFirestoreConnect({
    collection: "experiences",
    where: [["approved", "==", true]],
    orderBy: [orderBy ? orderBy : "dateCreated", asc ? "asc" : "desc"],
    // where: [["name", "==", query]],
  });
  return {
    loaded,
    experiences,
    experiencesList,
    pendingExperiences,
    getExperiencesByCity,
    totalViews,
    getAllExperiencesByUser,
    allExperiences,
    searchedExperiences,
    getExperiencesByUser,
    getExperiencesByTourType,
  };
};
