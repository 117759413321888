// import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useMedia } from "../media";
import { useExperiences } from "./useExperiences";
import { useCities } from "hooks";
import { useAuthentification } from "../auth/useAuthentification";
import firebase from "firebase/app";
import { useToasts } from "react-toast-notifications";
export const useExperience = (props = {}) => {
  const { id } = props;
  const { addToast } = useToasts();
  const { userId, profile, profilePicture } = useAuthentification();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const firestore = useFirestore();
  const { uploadMedia, uploadMedias, getMediaData, deleteMedia } = useMedia();
  const { loaded } = useExperiences();
  const experience = useSelector(
    (state) =>
      id && state.firestore?.data?.experience
  );
  const { deletePhotoFromCity, addPhotosToCity } = useCities({
    name: experience?.city,
  });
  const hostId = experience?.userId;
  const approved = experience?.approved;
  const experienceId = experience?.id;
  const isOwner =
    experience?.userId === userId || experience?.users?.includes(userId);

  const registerExperience = useCallback(
    async ({ coverImage, images, city, ...values }) => {
      let coverUrl = null;
      setLoading(true);
      const experienceRef = await firestore.collection("/experiences").add({
        ...values,
        city: city.toLowerCase(),
        users: [
          {
            userId: userId,
            hostName: profile.fullName,
            hostPhoto: profilePicture,
          },
        ],
        dateCreated: new Date(),
        userId: userId,
        id: null,
        hostName: profile.fullName,
        hostPhoto: profilePicture,
      });

      if (!coverImage || !coverImage[0]) {
        setLoading(false);
      }
      const coverRef = await uploadMedia({
        path: `/images/experiences/${experienceRef.id}`,
        file: coverImage[0],
      });
      const photosRef = await uploadMedias({
        path: `/images/experiences/${experienceRef.id}/photos`,
        files: images,
      });
      let photos = [];
      photosRef.forEach((ref) => photos.push(ref.url));
      const coverMedia = await getMediaData(coverRef.path);
      coverUrl = coverMedia?.url || null;
      await firestore
        .collection("/experiences")
        .doc(experienceRef.id)
        .update({ coverUrl });
      await firestore
        .collection("/experiences")
        .doc(experienceRef.id)
        .update({ photos });
      await firestore
        .collection("/experiences")
        .doc(experienceRef.id)
        .update({ id: experienceRef.id });
      setLoading(false);
      return { id: experienceRef.id };
    },
    [
      firestore,
      getMediaData,
      uploadMedia,
      uploadMedias,
      profile.fullName,
      profilePicture,
      userId,
    ]
  );
  const addPhotos = useCallback(
    async (filesToUpload, oldPhotos) => {
      if (filesToUpload.length > 0) {
        setImageLoading(true);
        const photosRef = await uploadMedias({
          path: `/images/experiences/${experienceId}/photos`,
          files: filesToUpload,
        });
        let photos = [];
        photosRef.forEach((ref) => photos.push(ref.url));
        await firestore
          .collection("/experiences")
          .doc(experienceId)
          .update({ photos: [...oldPhotos, ...photos] })
          .then(() => {
            addToast("Images added successfuly", {
              appearance: "success",
            });
            if (experience.approved) {
              addPhotosToCity(photos, experience.city.toLowerCase());
            }
          })
          .catch(() => {
            addToast("Error uploading images", {
              appearance: "error",
            });
          });
        setImageLoading(false);
      }
    },
    [
      firestore,
      uploadMedias,
      experienceId,
      addToast,
      addPhotosToCity,
      experience,
    ]
  );
  const deletePhotoFromExperience = useCallback(
    async (fileToDelete, experienceId, city) => {
      setImageLoading(true);
      deleteMedia({ url: fileToDelete });
      await firestore
        .collection("/experiences")
        .doc(experienceId)
        .update({
          photos: firebase.firestore.FieldValue.arrayRemove(fileToDelete),
        })
        .then(() => {
          addToast("Images Deleted successfuly", {
            appearance: "success",
          });
        })
        .catch(() => {
          addToast("Error Deleting image", {
            appearance: "error",
          });
        });
      deletePhotoFromCity(fileToDelete, city);
      setImageLoading(false);
    },
    [firestore, deleteMedia, addToast, deletePhotoFromCity]
  );
  const updateExperience = useCallback(
    async ({ cover, ...entry }) => {
      if (!experienceId || !experience) {
        return null;
      }
      let coverUrl = experience.coverUrl || "";
      setLoading(true);
      await firestore
        .collection("/experiences")
        .doc(experienceId)
        .update({ ...entry, updatedAt: new Date() })
        .then(() => {
          addToast("Updated successfuly", {
            appearance: "success",
          });
        })
        .catch((err) => {
          addToast("Error saving your experience", {
            appearance: "error",
          });
        });
      if (!cover || !cover[0]) {
        setLoading(false);
        return;
      }
      if (Array.isArray(cover) && cover[0]) {
        const oldCover = coverUrl;
        const coverRef = await uploadMedia({
          path: `/images/experiences/${experienceId}/photos`,
          file: cover[0],
        });
        if (oldCover) {
          await deleteMedia({ url: oldCover });
        }
        const coverMedia = await getMediaData(coverRef.path);
        coverUrl = coverMedia?.url || null;
      }
      await firestore
        .collection("/experiences")
        .doc(experienceId)
        .update({ coverUrl })
        .then(() => {
          addToast("Cover photo saved successfuly", {
            appearance: "success",
          });
        })
        .catch(() => {
          addToast("Error saving covor photo", {
            appearance: "error",
          });
        });
      setLoading(false);
    },
    [
      experienceId,
      firestore,
      deleteMedia,
      experience,
      getMediaData,
      uploadMedia,
      addToast,
    ]
  );

  const deleteExperience = useCallback(
    (id, coverUrl) => {
      if (!id) {
        return null;
      } else {
        return deleteMedia({ url: coverUrl }).then(() => {
          firestore.collection("/experiences").doc(id).delete();
        });
      }
    },
    [firestore, deleteMedia]
  );
  const updateUniqueExperience = useCallback(
    (entry, id) => {
      if (!id) {
        return null;
      }
      return firestore.collection("/experiences").doc(id).update(entry);
    },
    [firestore]
  );
  useFirestoreConnect([{ 
    collection: "experiences",
    doc: id,
    storeAs: "experience",

}]);

  return {
    experience,
    loaded,
    loading,
    imageLoading,
    experienceId,
    approved,
    updateExperience,
    updateUniqueExperience,
    deleteExperience,
    deletePhotoFromExperience,
    registerExperience,
    addPhotos,
    hostId,
    isOwner,
  };
};
