import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  date: {
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `${theme.spacing(0.25)}px solid ${theme.palette.secondary.light}`,
    width: '100%',
    height: '100%',
    minWidth: 47,
  },
  day: {
    fontSize: 25,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    lineHeight: 1,
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
  month: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  hour: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 900,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '100%',
    textAlign: 'center',
  },
}));
