// import axios from "axios";
// export const SET_CURRENCY = "SET_CURRENCY";
// export const setCurrency = currencyName => {
//   return dispatch => {
//     axios
//       .get(`http://api.exchangeratesapi.io/v1/latest?access_key=2798f86d05af4e7b39c6125b65eb2cfc&format=1`)
//       .then(response => {
//         console.log({response});
//         const rates = response.data.rates;
//         let currencyRate = 1;
//         for (const rate in rates) {
//           if (rate === currencyName) {
//             currencyRate = rates[rate];
//           }
//         }
//         dispatch({
//           type: SET_CURRENCY,
//           payload: { currencyName, currencyRate }
//         });
//       })
//       .catch(err => {
//       });
//   };
// };




export const SET_CURRENCY = "SET_CURRENCY";

export const setCurrency = currencyName => {
  return dispatch => {

        let currencyRate = 1;
   
        dispatch({
          type: SET_CURRENCY,
          payload: { currencyName, currencyRate }
        });
  };
};
