export default {
  header: {
    hosting: "Hosting",
    hosting_profile:"My Host Profile",
    myTrip: "My Trip",
    inbox: "Inbox",
    blog: "Blog",
    addExperience: "Add Experience",
  },
  form:{
    invalidPrice:"Invalid price"
  },
  account: {
    verifyEmail: "Please verify your email or request a new verification link",
  },
  blog:{
    drafts:"My drafts",
    create:"Create Blog",
    myBlogs:"My blogs",
  },
  helpCenter:{
    help_center:"HELP CENTER",
    welcome_to:"Welcome to Tabaani's Help center",
    choose_category:"Please choose one category",
    HighlightedArticles:"Highlighted Articles",
    "What are Tabaani experience standards and requirements?":"What are Tabaani experience standards and requirements?",
    good_morning:"Hello, how can we help you today?"
  },
  general:{
    traveler:"Traveler",
    guest:"Guest",
    cityAmbassador:"City Ambassador",
    host:"Host",
    uploadCover:"Upload Cover",
    required:"Required",
    Contact:"Contact",
    search:"Search",
    searchFor:"Search results for",
    allResults:"See more",
    all:"All",
    experience:"Experience",
    blog:"Blog",
    filters:"Filters",
    copywrites:"COPYRIGHT © 2021 ALL RIGHTS RESERVED ",
    support:"Support",
    uploadLogo:"Upload Logo",
    "Ready to join us and share your passion?":"Ready to join us and share your passion?",
    "Ready to share your passion?":"Ready to share your passion?",
    reservedInformation:'Confidential information, will not be publicly visible',
    "Subscribe to our newsletter":"Subscribe to our newsletter"
  },
  experience: {
    chooseTourtype:"Please choose which type best describes your experience",
    pageTitle: "Experiences",
    duration:"Duration",
    price:"Price per person",
    maxLoad:"Max Load",
    minAge:"min Age",
    title: "Title",
    address: "Address",
    meetingPoint:"Meeting Point",
    region: "Region",
    country: "Country",
    city: "City",
    admins: "Admins",
    description: "Description",
    goLive: "Go Live!",
    dragAndDrop:"Drag And Drop",
    edit: "Edit",
    linkFacebook: "Link to Facebook page",
    linkInstagram: "Link to Instagram page",
    website: "Website",
    phone: "Phone number",
    email: "Email address",
    followUs: "Follow us on social networks",
    musicStyles: "Music styles",
    direction: "Get Direction",
    noClubs: "No clubs",
    noClubsFound: "No clubs found",
    verified: "Certified Club",
    reservedInformation:
      "Confidential information, will not be publicly visible",
    importConsent: {
      "import-events": "Import events",
      "import-albums": "Import albums",
    },
    importConsentLabel:
      "By activating the automatic import functions, you consent to Tabaani.co to automatically import events and / or photos from your facebook page to increase the range of free advertising for your events.",
    home: "Home",
    nextEvents: "Next Events",
    pastEvents: "Past Events",
    search: "Search for Clubs",
    nameVenue: "Nome club...",
    register: "Register your club now!",
  },
  home: "Home",
  what_is: "What is",
  free_platform: " It's a free platform that empower Passionate locals ",
  by_hosting: "by hosting a private tour or experience in their city .",
  hosting: "Hosting",
  our_mission_is: "Our mission is :",
  connect_people: "Connect people with cultures by breaking down barriers",
  connect_host: "Connect with our Top rated Hosts all over the MENA Region:",
  between_travelers_locals: "between traverlers and locals worldwide",
  with_this_in_mind:
    " With this in mind, Tabaani platform was created as a marketplace which facilitates the connection between local Ambasadors and travellers all around the world! ",
  our_experiences: "Our experiences",
  are_always:"are always",
  Private_Personal: " Private & Personal",
  private_description:
    " No strangers, just you. Fully customize your trip to your needs.",
  with_a_Pasionate_Local: "With a Passionate Local",
  pasionate_description:
    "From historical facts to the most up-to-date health regulations.",
  without_crowds: "Without crowds ",
  crowds_description:
    " Experiences designed to take you away from the crowds towards authentic local spots.",
  discover: "Discover New Destinations and cities with",
  explore: "Explore with",
  shop: "Shop",
  collection: "Collection",
  pages: "Pages",
  cart: "Cart",
  checkout: "Checkout",
  wishlist: "Wishlist",
  help: "Help",
  help_center: "Help Center",
  terms_conditions: "Terms and Conditions",
  community: "Community",
  support: "Support",
  compare: "Compare",
  about: "About",
  about_us: "ABOUT US",
  become_a_partner: "Become A Partner",
  sign_in: "Sign in",
  sign_up: "Sign up",
  forgot_password: "Forgot password? ",
  no_account: "Don't have an account? ",
  login: "Login",
  my_account: "My account",
  dashbord: "Dashboard",
  contact_us: "Contact Us",
  edit_profile: "Edit Profile",
  login_register: "Login / Register",
  "404_page": "404 Page",
  book_authentic: "Book Authentic",
  unique_experiences: "Unique Experiences",
  children: "Children",
  people: "People",
  adults: "Adults",
  search: "Search",
  with: "With",
  IsOurPriority: "is our Priority",
  yourSafety: "your Safety",
  privateToursAndActivitiesWithLocals:
    "Private tours and activities with Locals",
  where_are_you_going: "Where are you going !",
  meeting_point: "Meeting Point",
  contact_me: "Contact me",
  what_is_waiting_for_you: "What is waiting for you",
  active_experiences: "Active Experiences",
  add_photos: "Add Photos",
  make_money: "Make Money doing What you love",
  meet_our_ambassadors: "Meet our ambassadors",
  all_of_our_experiences_are: "All of our experiences are",
  authentic: "Authentic",
  personalized: "Personalized",
  with_the_local_you_choose: "With the local you choose",
  tabaani_description:
    "Vacations are great and being a tourist is fine. But what if we want to go deeper? what if we shake off the limitations of retail tourism and experience a place as the locals do? how do you travel somewhere and allow yourself to be absorbed into the culture as much as possible, while still being polite and respectful?",
  finding_friendly_locals: "Finding Friendly Locals",
  finding_friendly_locals_description:
    "The best way to learn about what to do and see in a place is to Connect with locals. they have a far better idea of what's fun to do, what's a tourist trap, where there's good food, and more. They'll share insights into their culture in a way that might not become apparent if you're just visiting a place,especially if you travel with them",
  good_news: "Good news!",
  friendly_locals: "There are friendly locals everywhere with Tabaani",
  tabaani_descp: [
    "Experiences of every kind, like cooking, Sketching, Camping and more. ",
    "There's no limit to Passion and what you can do. ",
    "Explore these featured categories",
  ],
  activities: ["Culture & History", "Food & Drink", "Nature & Outdoor"],
  activities_descp: [
    "Share the story behind famous landmarks in your city.",
    "Host a food tour, cooking class, dining experience, and more.",
    "Lead nature hikes, water sports, mountain activities, and more.",
  ],
  experience_descp:
    " It’s an activity that goes beyond the typical tour or class, designed and led by locals like you. Show off your city, craft, cause, or culture by hosting an experience.",
  share: [
    " Share what you know",
    " Experiences of every kind, like cooking, Sketching, Camping, and more. There is no limit to Passion and what you can do. Explore these featured categories.",
  ],
  benefits_title: "Benefits of being a city ambassador",
  benefits: [
    "Earn money doing what you love",
    "Networking with people like you",
    "Live your passion, and share it!",
  ],
  benefits_descp: [
    "If you love your city why not share it with the rest of the world? Even better, why not make some money while doing so. Here, you set up your own price.",
    "Meet people from all over the world that share the same passions and interests as you. Create memories for a lifetime and new connections.",
    "Create a one of a kind experience, from a cooking class to an off the beaten path tour or a dance workshop. The choice is yours!",
  ],
  host: "Host an experience ",
  host_descp:
    "  From an online service to an offline one-of-a-kind experience, designed by you. disconnecting from the digital and reconnecting with Travelers through your City",
  start: "Let's get started!",
  start_now: "Let's start Now!",
  passion_share: "Share your passion and love for your city with",
  create_steps: [
    "Create your Tabaani account",
    "Answer a simple questionnaire",
    "We’ll be in touch",
  ],
  create_descp: [
    "Sign up and set up your profile. Already have an account? Then, let’s get started with your host application.",
    "We want to know more from you. It will help us get an idea of what you are really passionate about.",
    "Submit your questionnaire and that’s it! Once we review your application, our team will reach out to you",
  ],
  users: "Users",
  more_orders: "See more orders",
  _questions: "commentaire",
  how_to_be_partner: "How to be a partner",
  forbidden: "Forbidden",
  access_denied: "Oh oh! You can't access this page.",
  not_found: ["We can't seem to find the ", " page you're looking for."],
  instead: "Here are some helpful links instead:",
  error: "Error",
  sorry: "Sorry! We just encountered an unexpected error.",
  traveling_with: "Traveling with",
  hosting_in: "Hosting on",
  privacy_policy: "Privacy Policy",
  privacy_descp: [
    "Your privacy is important to us. It is Tabaani's policy to respect your privacy regarding any information we may collect from you across our website, http://www.tabaani.com, and other sites we own and operate.",
    "We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.",
    " We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access,disclosure, copying, use or modification.",
    "We don’t share any personally identifying information publicly or with third-parties, except when required to by law.",
    "Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.",
    " You are free to refuse our request for your personal information, with the understanding that we may be unable to  provide you with some of your desired services.",
    "Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.",
    "This policy is effective as of 1 January 2021",
    "By accessing the website at http://www.tabaani.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.",
    "Permission is granted to temporarily download one copy of the materials (information or software) on Tabaani's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:",
    "modify or copy the materials",
    "use the materials for any commercial purpose, or for any public display (commercial or non- commercial)",
    "attempt to decompile or reverse engineer any software contained on Tabaani's website",
    "remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or &quot;mirror&quot; the materials on any other server.",
    "This license shall automatically terminate if you violate any of these restrictions and may be terminated by Tabaani at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.",
    "The materials on Tabaani's website are provided on an 'as is' basis. Tabaani makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non- infringement of intellectual property or other violation of rights.",
    "Further, Tabaani does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.",
    "In no event shall Tabaani or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Tabaani's website, even if Tabaani or a Tabaani authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.",
    "The materials appearing on Tabaani's website could include technical, typographical, or photographic errors. Tabaani does not warrant that any of the materials on its website are accurate, complete or current. Tabaani may make changes to the materials contained on its website at any time without notice. However Tabaani does not make any commitment to update the materials.",
    "Tabaani has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Tabaani of the site. Use of any such linked website is at the user's own risk.",
    "Tabaani may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.",
    "These terms and conditions are governed byz and construed in accordance with the laws of Tunisia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.",
  ],
  terms: "Terms",
  and: " and ",
  you: "You",
  interpretation: "Interpretation",
  definitions: "Definitions",
  affiliate: "Affiliate",
  country: "Country",
  company: "Company",
  device: "Device",
  service: "Service",
  generator: "Generator",
  refers_to: "refers to:",
  third_party: "Third-party Social Media Service",
  website: "Website",
  accessible_from: "accessible from",
  acknowledgment: "Acknowledgment",
  tunisia: "Tunisia",
  termination: "Termination",
  limitation_liability: "Limitation of Liability",
  as_is: "'AS IS' and 'AS AVAILABLE' Disclaimer",
  terms_descp: [
    "Last updated: January 12, 2021",
    "Please read these terms and conditions carefully before using Our Service.",
    " The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.",
    "For the purposes of these Terms and Conditions:",
    "means an entity that controls, is controlled by or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.",
    "(referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement)",
    "means any device that can access the Service such as a computer, a cellphone or a digital tablet.",
    "(also referred as 'Terms') mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the",
    "means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.",
    " means the individual accessing or using the Service, or the company, or other legal entity on behalf of which    such individual is accessing or using the Service, as applicable.",
    "These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.",
    "Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.",
    "By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.",
    "You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.",
    " Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.",
    "Links to Other Websites",
    "Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.",
    "The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.",
    "We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.",
    "We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.",
    "Upon termination, Your right to use the Service will cease immediately.",
    "Not with standing any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.",
    "To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.",
    "Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.",
    "The Service is provided to You 'AS IS' and 'AS AVAILABLE' and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.",
    "Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.",
    "Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.",
    "The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.",
    "If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.",
    "If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.",
    "You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a 'terrorist supporting' country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.",
    "If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.",
    "Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.",
    "These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.",
    "We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.",
    "By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.",
    "If you have any questions about these Terms and Conditions, You can contact us:",
  ],
  use_license: "Use License",
  disclaimer: "Disclaimer",
  limitations: "Limitations",
  accuracy_materials: "Accuracy of materials",
  links: "Links",
  modifications: "Modifications",
  governing_law: "Governing Law",
  disputes: "Disputes Resolution",
  european_union: "For European Union (EU) Users",
  united_states: "United States Legal Compliance",
  severability: "Severability",
  waiver: "Waiver",
  translation_interp: "Translation Interpretation",
  changes_to_these: "Changes to These ",
  by_email: "By email: ",
  become_a_host: "Become A Host",
  please_apply: "Please apply to become a host first",
  click: "click here to apply",
  more_details: "More Details : ",
  max_load: "Max Load : ",
  welcome: "Welcome",
  price_person: " Price Per Person",
  back: "Back",
  finish: "Finish",
  add_more: "Add more",
  add_experience: "Add Experience",
  next: "Next",
  steps_completed: "All steps completed - you have finished",
  reset: "Reset",
  please_choose: "Please choose which type best describes your experience",
  food_tour: "Food Tours",
  walking_tour: "Walking tour",
  day_trips: "Day trips",
  family_friendly: "Family Friendly",
  night_tour: "Night tour",
  bike_tour: "Bike tour",
  add_reasons: "Add at least 3 reasons to book this experience",
  included: " What's included in the experience ?",
  upload_cover_photo: "Upload Cover Photo",
  accept_terms: "Accept terms",
  create_exp: "Create Experience ",
  when_using: "When using ",
  you_accept: " you accept our ",
  Explore_with_a_local_of_your_choice: "Explore with a local of your choice",
  apply_host: "Please apply to become a host first",
  apply: "click here to apply",
  i_live: "I live in",
  i_speak: "I speak",
  verified: "Verified",
  local_host: "Local Host",
  response_time: "Response time",
  send_message: "Send a message",
  cancel: "Cancel",
  send: "Send",
  upload_video: "Upload Or Edit Your Cover Video ",
  save: "Save",
  book_offer: "Book one of my offers",
  Travellers: "Travellers",
  orders: "Orders",
  edit_your_description: "Edit your description",
  "Food tours": "Food tours",
  "Walking tours": "Walking tours",
  "Road trip": "Road trip",
  "Family Friendly": "Family Friendly",
  "Bike tour": "Bike tour",
  "Night life": "Night life",
  "Share the story behind famous landmarks in your":
    "Share the story behind famous landmarks in your",
  city: "city",
  "Host a food tour, cooking class, dining":
    "Host a food tour, cooking class, dining",
  "experience, and more.": "experience, and more.",
  "Lead nature hikes, water sports, mountain":
    "Lead nature hikes, water sports, mountain",
  "activities, and more.": "activities, and more.",
  "Become a Host Now": "Become a Host Now",
  "Make money sharing": "Make money sharing",
  "your passion": "your passion",
  overview: "Overview",
  Bookings: "Bookings",
  experiences: "Experiences",
  my_reviews: "My reviews",
  Settings: "Settings",
  achievements: "Achievements",

  countries:{
Algeria : "Algérie",
Angola : "Angola",
Benin : "Bénin",
Botswana : "Botswana",
Burkina : "Burkina Faso",
Burundi : "Burundi",
Cameroon : "Cameroun",
'Cape Verde' : "Cap vert",
'Central African Republic' : "République centrafricaine",
Chad : "Tchad",
Comoros : "Comores",
Congo : "Congo",
'Congo, Democratic Republic of' : "République démocratique du Congo",
Djibouti : "Djibouti",
Egypt : "Egypte",
'Equatorial Guinea' : "Guinée équatoriale",
Eritrea : "Erythree",
Ethiopia : "Ethiopie",
Gabon : "Gabon",
Gambia : "Gambie",
Ghana : "Ghana",
Guinea : "Guinée",
'Guinea-Bissau' : "Guinée-Bissau",
'Ivory Coast' : "Côte d’Ivoire",
Kenya : "Kenya",
Lesotho : "Lesotho",
Liberia : "Liberia",
Libya : "Libye",
Madagascar : "Madagascar",
Malawi : "Malawi",
Mali : "Mali",
Mauritania : "Mauritanie",
Mauritius : "Ile Maurice",
Morocco : "Maroc",
Mozambique : "Mozambique",
Namibia : "Namibie",
Niger : "Niger",
Nigeria : "Nigeria",
Rwanda : "Rwanda",
'Sao Tome and Principe' : "Sao Tomé-et-Principe",
Senegal : "Sénégal",
Seychelles : "Seychelles",
'Sierra Leone': "Sierra Leone",
Somalia : "Somalie",
'South Africa': "Afrique du Sud",
'South Sudan': "Soudan du Sud",
Sudan : "Soudan",
Swaziland : "Swaziland",
Tanzania : "Tanzanie",
Togo : "Togo",
Tunisia : "Tunisie",
Uganda : "Ouganda",
Zambia : "Zambie",
Zimbabwe : "Zimbabwe",
  }
};
