import React from 'react';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useGlobalStyles } from '../../../hooks';
import { Spacer } from '../../atoms';
import { useCoverStyle, useLogoStyle } from './styles';

type Props = {
  cover: File[] | string;
  setCover: (files: File[]) => void;
  logo?: File[] | string;
  setLogo?: (files: File[]) => void;
};

const Form: React.FC<Props> = ({ logo, cover, setLogo, setCover }) => {
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const coverUrl =
    typeof cover === 'string'
      ? cover
      : cover[0] && URL.createObjectURL(cover[0]);
  const logoUrl = logo
    ? typeof logo === 'string'
      ? logo
      : logo[0] && URL.createObjectURL(logo[0])
    : null;

  const dropzoneCoverClasses = useCoverStyle({
    bg: coverUrl || undefined,
  });
  const dropzoneLogoClasses = useLogoStyle({
    bg: logoUrl || undefined,
  });

  return (
    <Grid item md={12} className={globalStyles.relative}>
      <DropzoneAreaBase
        classes={dropzoneCoverClasses}
        fileObjects={[]}
        maxFileSize={30000000}
        filesLimit={1}
        acceptedFiles={['image/*']}
        dropzoneText={t('general.uploadCover')}
        onDrop={(files: any) => setCover(files)}
      />
      {setLogo && (
        <DropzoneAreaBase
          classes={dropzoneLogoClasses}
          fileObjects={[]}
          filesLimit={1}
          maxFileSize={30000000}
          acceptedFiles={['image/*']}
          dropzoneText={t('general.uploadLogo')}
          onDrop={(files: any) => setLogo(files)}
        />
      )}
      <Spacer height="30px" />
    </Grid>
  );
};

Form.displayName = 'PageCoverAndLogoForm';

export default React.memo(Form);
