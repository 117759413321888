import React from "react";

const Hamsa = (props) => {
  const hamsat = parseInt(props.rate);
  const rest = props.rate - hamsat;
  let arr = [];
  for (let i = 0; i < hamsat; i++) {
    arr.push(i);
  }
  return (
    <div className="hamsa">
      {arr.map((ze, index) => (
        <div style={{ display: "inline" }} key={index}>
          <svg
            width="22"
            height="21"
            viewBox="0 0 32 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M27.3758 14.9552C27.3529 14.9552 27.3403 14.9552 27.3403 14.9552C24.7293 14.8176 23.4722 15.9907 22.8824 16.9628C22.7471 17.1857 22.3889 17.0956 22.3889 16.8383V5.07298C22.3889 4.04586 21.5348 3.18131 20.4523 3.16074C19.3418 3.13964 18.4347 3.98732 18.4347 5.03594V2.78915C18.4347 1.73683 17.5212 0.886931 16.4054 0.914181C15.2897 0.886931 14.3761 1.73677 14.3761 2.78915V5.03594C14.3761 3.98732 13.469 3.13964 12.3585 3.16074C11.276 3.18131 10.4219 4.04586 10.4219 5.07298V16.8383C10.4219 17.0956 10.0636 17.1857 9.92838 16.9628C9.33857 15.9907 8.08152 14.8176 5.47044 14.9552C5.47044 14.9552 5.45789 14.9552 5.43498 14.9552C4.86558 14.9552 4.54156 15.5709 4.88215 16.0038C5.37924 16.6356 5.80628 17.3175 6.14474 18.0363C7.18663 20.2491 6.97926 22.5437 7.81549 24.8167C8.59524 26.9363 10.0681 28.704 12.3144 29.5372C13.5005 29.9772 15.1594 30.0389 16.4054 30.0387C17.6514 30.0389 19.3103 29.9772 20.4964 29.5372C22.7426 28.704 24.2156 26.9363 24.9953 24.8167C25.8315 22.5437 25.6242 20.2491 26.6661 18.0363C27.0045 17.3175 27.4315 16.6356 27.9287 16.0038C28.2692 15.5709 27.9452 14.9552 27.3758 14.9552Z"
                fill="url(#paint0_linear)"
              />
              <path
                d="M22.3887 16.8379C21.694 16.8381 21.1307 16.2963 21.1307 15.628V5.07269C21.1307 4.23937 20.5684 3.51329 19.7783 3.25779C19.9897 3.19098 20.2163 3.15582 20.4521 3.16051C21.5346 3.18102 22.3887 4.0454 22.3887 5.07269V16.8379Z"
                fill="#C4C4C4"
              />
              <path
                d="M14.376 5.0361V16.5601C13.6809 16.5601 13.118 16.0181 13.118 15.35V4.89722C13.118 4.16235 12.6813 3.51129 12.0361 3.19249C12.1403 3.17374 12.2481 3.16261 12.3584 3.16085C13.4689 3.13975 14.376 3.98713 14.376 5.0361Z"
                fill="#C4C4C4"
              />
              <path
                d="M18.4275 2.67995V16.5603C17.733 16.5609 17.1695 16.0188 17.1695 15.3508V2.67995C17.1695 1.91813 16.6999 1.24596 16.0151 0.941235C16.1418 0.920138 16.2722 0.911348 16.4056 0.914278C17.4595 0.889079 18.3325 1.64504 18.4263 2.61549C18.4269 2.63653 18.4275 2.65827 18.4275 2.67995V2.67995Z"
                fill="#C4C4C4"
              />
              <path
                d="M27.9285 16.0035C27.4314 16.6358 27.0044 17.3173 26.6657 18.0364C25.624 20.2492 25.8311 22.5434 24.9953 24.8166C24.2155 26.9362 22.7425 28.7042 20.4965 29.537C19.3098 29.9771 17.651 30.0386 16.4052 30.0386C16.2066 30.0386 15.997 30.0368 15.7808 30.0321C16.9199 30.0087 18.2504 29.9056 19.2476 29.5311C21.4937 28.6878 22.9667 26.8987 23.7465 24.7539C24.5823 22.4532 24.3751 20.1308 25.4168 17.8916C25.7555 17.1638 26.1826 16.4734 26.6797 15.8341C26.9063 15.5423 26.8387 15.1684 26.5993 14.9516C26.8332 14.9404 27.0799 14.9416 27.34 14.9551H27.3754C27.9449 14.9551 28.269 15.571 27.9285 16.0035Z"
                fill="#C4C4C4"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="16.4054"
                y1="0.913544"
                x2="16.4054"
                y2="30.0387"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: "#FFAF20", stopOpacity: "0" }}
                />
                <stop offset="1" stopColor="#FFAF20" />
              </linearGradient>
              <clipPath id="clip0">
                <rect
                  width="31.1902"
                  height="30.0041"
                  fill="white"
                  transform="translate(0.810059 0.473724)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      ))}
      <div style={{ display: "inline" }}>
        <svg
          width="22"
          height="21"
          viewBox="0 0 32 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip00)">
            <path
              d="M27.3758 14.9552C27.3529 14.9552 27.3403 14.9552 27.3403 14.9552C24.7293 14.8176 23.4722 15.9907 22.8824 16.9628C22.7471 17.1857 22.3889 17.0956 22.3889 16.8383V5.07298C22.3889 4.04586 21.5348 3.18131 20.4523 3.16074C19.3418 3.13964 18.4347 3.98732 18.4347 5.03594V2.78915C18.4347 1.73683 17.5212 0.886931 16.4054 0.914181C15.2897 0.886931 14.3761 1.73677 14.3761 2.78915V5.03594C14.3761 3.98732 13.469 3.13964 12.3585 3.16074C11.276 3.18131 10.4219 4.04586 10.4219 5.07298V16.8383C10.4219 17.0956 10.0636 17.1857 9.92838 16.9628C9.33857 15.9907 8.08152 14.8176 5.47044 14.9552C5.47044 14.9552 5.45789 14.9552 5.43498 14.9552C4.86558 14.9552 4.54156 15.5709 4.88215 16.0038C5.37924 16.6356 5.80628 17.3175 6.14474 18.0363C7.18663 20.2491 6.97926 22.5437 7.81549 24.8167C8.59524 26.9363 10.0681 28.704 12.3144 29.5372C13.5005 29.9772 15.1594 30.0389 16.4054 30.0387C17.6514 30.0389 19.3103 29.9772 20.4964 29.5372C22.7426 28.704 24.2156 26.9363 24.9953 24.8167C25.8315 22.5437 25.6242 20.2491 26.6661 18.0363C27.0045 17.3175 27.4315 16.6356 27.9287 16.0038C28.2692 15.5709 27.9452 14.9552 27.3758 14.9552Z"
              fill="url(#paint0_linear1)"
            />
            <path
              d="M22.3887 16.8379C21.694 16.8381 21.1307 16.2963 21.1307 15.628V5.07269C21.1307 4.23937 20.5684 3.51329 19.7783 3.25779C19.9897 3.19098 20.2163 3.15582 20.4521 3.16051C21.5346 3.18102 22.3887 4.0454 22.3887 5.07269V16.8379Z"
              fill="#C4C4C4"
            />
            <path
              d="M14.376 5.0361V16.5601C13.6809 16.5601 13.118 16.0181 13.118 15.35V4.89722C13.118 4.16235 12.6813 3.51129 12.0361 3.19249C12.1403 3.17374 12.2481 3.16261 12.3584 3.16085C13.4689 3.13975 14.376 3.98713 14.376 5.0361Z"
              fill="#C4C4C4"
            />
            <path
              d="M18.4275 2.67995V16.5603C17.733 16.5609 17.1695 16.0188 17.1695 15.3508V2.67995C17.1695 1.91813 16.6999 1.24596 16.0151 0.941235C16.1418 0.920138 16.2722 0.911348 16.4056 0.914278C17.4595 0.889079 18.3325 1.64504 18.4263 2.61549C18.4269 2.63653 18.4275 2.65827 18.4275 2.67995V2.67995Z"
              fill="#C4C4C4"
            />
            <path
              d="M27.9285 16.0035C27.4314 16.6358 27.0044 17.3173 26.6657 18.0364C25.624 20.2492 25.8311 22.5434 24.9953 24.8166C24.2155 26.9362 22.7425 28.7042 20.4965 29.537C19.3098 29.9771 17.651 30.0386 16.4052 30.0386C16.2066 30.0386 15.997 30.0368 15.7808 30.0321C16.9199 30.0087 18.2504 29.9056 19.2476 29.5311C21.4937 28.6878 22.9667 26.8987 23.7465 24.7539C24.5823 22.4532 24.3751 20.1308 25.4168 17.8916C25.7555 17.1638 26.1826 16.4734 26.6797 15.8341C26.9063 15.5423 26.8387 15.1684 26.5993 14.9516C26.8332 14.9404 27.0799 14.9416 27.34 14.9551H27.3754C27.9449 14.9551 28.269 15.571 27.9285 16.0035Z"
              fill="#C4C4C4"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear1"
              x1="16.4054"
              y1="0.913544"
              x2="16.4054"
              y2="30.0387"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                offset={isNaN(1 - rest) ? "" : 1 - rest}
                stopColor="#FFAF20"
                stopOpacity="0"
              />
              <stop offset="1" stopColor="#FFAF20" />
            </linearGradient>
            <clipPath id="clip00">
              <rect
                width="31.1902"
                height="30.0041"
                fill="white"
                transform="translate(0.810059 0.473724)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default Hamsa;
