import { Box, Paper, Button, Divider, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { SocialLoginButton } from '..';
import { useMediaQuery } from '../../../hooks';
import { authErrorSelector } from '../../../redux/selectors';
import { Spacer, Flex } from '../../atoms';
import FormField from '../../molecules/FormField';

type Props = {
  showRegister?: boolean;
  width?: string | number;
  onLoginCallback?: () => void;
  small?: boolean;
};

type Form = {
  email: string;
  password: string;
};

const LoginBox: React.FC<Props> = ({
  showRegister = true,
  width = 500,
  small = false,
  onLoginCallback,
}) => {
  const { handleSubmit, errors, register } = useForm<Form>();
  const authError = useSelector(authErrorSelector);
  const firebase = useFirebase();
  const { isMobile } = useMediaQuery();
  // const { t } = useTranslation();
  const history = useHistory();
  const goToRegister = useCallback(() => history.push('/signup'), [history]);
  const onSubmit = (values: any) => {
    const { email, password } = values;
    firebase.login({ email, password }).then(() => {
      if (onLoginCallback) {
        onLoginCallback();
        history.push('/home');
      }
    });
  };
  return (
    <Box width={width} maxWidth="100%" margin="auto">
      <Paper variant="outlined">
        <Box padding={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField
              name="email"
              label="Email"
              type="email"
              register={register}
              error={errors.email?.message}
            />
            <Spacer height="10px" />
            <FormField
              name="password"
              label="Password"
              type="password"
              register={register}
            />
            <Spacer height="10px" />
            <Typography variant="body2" color="error">
              {authError && authError.message}
            </Typography>
            <Spacer height="10px" />
            <Button variant="outlined" color="primary" type="submit" fullWidth>
              Login Now
            </Button>
          </form>
          <Spacer height="10px" />
          <Flex flexDirection={isMobile || small ? 'column' : 'row'}>
            <SocialLoginButton
              provider="facebook"
              scopes={['email']}
              onLoginCallback={onLoginCallback}
            />
            {/* <Spacer width="10px" height="10px" />
            <SocialLoginButton
              provider="twitter"
              onLoginCallback={onLoginCallback}
            /> */}
            <Spacer width="10px" height="10px" />
            <SocialLoginButton
              provider="google"
              onLoginCallback={onLoginCallback}
            />
          </Flex>
          {showRegister && (
            <>
              <Spacer height="20px" />
              <Divider variant="middle" />
              <Spacer height="20px" />
              <Button variant="outlined" fullWidth onClick={goToRegister}>
                First time? Register here
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

LoginBox.displayName = 'LoginBox';

export default React.memo(LoginBox);
