export const TourTypes = {
    foodTour:{
        background:"/images/tourtypes/foodtour.webp",
        displayName:"Food Tour",
        description:"Count memories, not calories! Unexpected encounters with delicious food all while you’re visiting marvellous destinations is the dream. And what’s a better way to bring bliss to your soul than to discover exotic food and satisfy your curiosity about its history? Not only that, but you’ll be offered to taste a variety of food that represents the culture and shows the kindness and generosity of locals for free. Good food, good mood, right?"
    },
    walkingTour:{
        background:"/images/tourtypes/walkingtour.webp",
        displayName:"Walking Tour",
        description:"Feel part of the city and discover its hidden gems by having a memorable walking tour,noticing the fine details of architectural styles,houses, fields, towns and castles, instead of just glancing at sweeping scenery out of a coach window. Walking tours put you in the centre of the picture and give you heightened access to the world,allowing you to go off the beaten track as you start to feel joy through street music ,scents, sensibility and the smell of cities but also a great way to wander through fields, rugged countryside, quaint villages and forests,Walking tours will help you realise just how local you’re starting to become."
    },
    dayTrips:{
        background:"/images/tourtypes/daytrips.webp",
        displayName:"Day trips",
        description:"Are you an adventurer with a wanderlust for visiting new places? Do you have an intense and irresistible desire to get away and get to watch ceremonial sunsets and sunrises, all while you’re on the road? Tabaani’s Road trip tour is the option we recommend you to choose! Your host will be waiting for you to get you to your next destination, while you’ll be enjoying the mesmerizing view of the road along the way, and you’ll be having an authentic travel experience from one city to another feeling the magic of freedom and making great memories!"

    },
    familyFriendly:{
        background:"/images/tourtypes/familyfriendly.webp",
        displayName:"Family Friendly",
        description:"Family is precious and so are the days you spend with them. It’s high time you and your family take a break from the daily pressure and enjoy a thrilling getaway with Tabaani in your favorite destination. How long has it been since you all went scuba diving at the beach or shared a different culture’s delicious meal together? Well, you don’t have to wait any longer. We provide you with endless fun activities to enjoy with your family while meeting with locals and discovering new cities. Good times and travel experiences make the most beautiful family memories. What are you waiting for? Your next travel experience with Tabaani is one click away!"

    },
    nightTour:{
        background:"/images/tourtypes/nighttour.webp",
        displayName:"Night Tour",
        description:"If you’re eager to discover a new place every night, our Night Tour is the ultimate opportunity for you to have the nights of your life. How long has it been since you’ve explored a new city by night? Tabaani offers adventure seekers like you a series of events to explore your destination in the still of the night, from the best nightclubs and beach parties to the finest restaurants to the most popular night markets and fun fairs. Even sightseeing during a peaceful stroll one night has a beautiful and unique aspect. You don’t want to miss out on this in a million nights! What are you waiting for? Your next travel experience with Tabaani is one click away!"
    },
    bikeTour:{
        background:"/images/tourtypes/biketour.webp",
        displayName:"Bike Tour",
        description:"Wanna instantly lift your mood ,feel healthier and happier? Then Bike tours are a great choice for you! While riding a bike, you pay more attention to the world around you, you interact more with the environment and you enjoy an authentic travel experience with new and old friends while taking in the sights and smells of the streets and towns that will surely stick with you forever,all of this while feeling recharged and refreshed! Cycling touring stands out in our minds as a truly amazing experience, where all your senses are engaged."
    },
}
export const TourTypesArray = [
        "Food Tour",
        "Walking Tour",
        "Day trips",
        "Family Friendly",
        "Night Tour",
        "Bike Tour",
]