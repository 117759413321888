import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  InstapaperIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from 'react-share';

import { Flex } from '../../atoms';
import { useGlobalStyles } from '../../../hooks';
import { Icons } from './ShareButton';
import CopyToClipboard from './CopyToClipboard';

type Props = {
  url?: string;
  iconSize?: number;
  icons?: Icons[];
  anchorEl?: HTMLElement | null;
  closePopOver?: () => void;
};

const Share: React.FC<Props> = ({
  url = window.location.href,
  iconSize = 35,
  icons = [
    'email',
    'messenger',
    'facebook',
    'telegram',
    'twitter',
    'whatsapp',
    'copyUrl',
  ],
}) => {
  const globalStyles = useGlobalStyles();

  return (
    <Flex wrap>
      {(icons.includes('email') || icons.includes('all')) && (
        <EmailShareButton url={url}>
          <EmailIcon size={iconSize} round={true} className={globalStyles.m1} />
        </EmailShareButton>
      )}
      {(icons.includes('messenger') || icons.includes('all')) && (
        <FacebookMessengerShareButton
          url={url}
          appId="need-valid-facebook-app-id"
        >
          <FacebookMessengerIcon
            size={iconSize}
            round={true}
            className={globalStyles.m1}
          />
        </FacebookMessengerShareButton>
      )}
      {(icons.includes('facebook') || icons.includes('all')) && (
        <FacebookShareButton url={url}>
          <FacebookIcon
            size={iconSize}
            round={true}
            className={globalStyles.m1}
          />
        </FacebookShareButton>
      )}
      {(icons.includes('instapaper') || icons.includes('all')) && (
        <InstapaperShareButton url={url}>
          <InstapaperIcon
            size={iconSize}
            round={true}
            className={globalStyles.m1}
          />
        </InstapaperShareButton>
      )}
      {(icons.includes('telegram') || icons.includes('all')) && (
        <TelegramShareButton url={url}>
          <TelegramIcon
            size={iconSize}
            round={true}
            className={globalStyles.m1}
          />
        </TelegramShareButton>
      )}
      {(icons.includes('twitter') || icons.includes('all')) && (
        <TwitterShareButton url={url}>
          <TwitterIcon
            size={iconSize}
            round={true}
            className={globalStyles.m1}
          />
        </TwitterShareButton>
      )}
      {(icons.includes('viber') || icons.includes('all')) && (
        <ViberShareButton url={url}>
          <ViberIcon size={iconSize} round={true} className={globalStyles.m1} />
        </ViberShareButton>
      )}
      {(icons.includes('whatsapp') || icons.includes('all')) && (
        <WhatsappShareButton url={url}>
          <WhatsappIcon
            size={iconSize}
            round={true}
            className={globalStyles.m1}
          />
        </WhatsappShareButton>
      )}
      {(icons.includes('copyUrl') || icons.includes('all')) && (
        <CopyToClipboard url={url} iconSize={iconSize} />
      )}
    </Flex>
  );
};

Share.displayName = 'Share';

export default Share;
