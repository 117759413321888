import { createSelector } from 'reselect';
import { AppStore } from 'types';

export function firebaseState(state: AppStore): AppStore['firebase'] {
  return state.firebase;
}

export function firestoreState(state: AppStore): AppStore['firestore'] {
  return state.firestore;
}

export const firestoreSelector = createSelector(firestoreState, state => state);

export const authSelector = createSelector(firebaseState, state => state.auth);

export const profileSelector = createSelector(
  firebaseState,
  state => state.profile,
);

export const authErrorSelector = createSelector(
  firebaseState,
  (state): { message: string; code: string } | null => state.authError,
);
