// import { composeWithDevTools } from 'redux-devtools-extension';
// import 'regenerator-runtime/runtime';
// import reducer from './redux/reducers/rootReducer';
import { save, load } from "redux-localstorage-simple";
// import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// let middleware ;
// if(process.env.NODE_ENV === "development"){
//     middleware = composeWithDevTools(applyMiddleware( thunk,save()));
// }else{
//     middleware = applyMiddleware( thunk,save({ignoreStates: ["firestore"]}))
// }
// export default createStore(reducer,load(),middleware);


import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import 'regenerator-runtime/runtime';
import reducer from './redux/reducers/rootReducer';
import { rootSagas } from './redux/sagas/rootSagas';

const sagaMiddleware = createSagaMiddleware();

let middleware;

if(process.env.NODE_ENV === "development"){
    middleware = composeWithDevTools(applyMiddleware(thunk,save({ignoreStates: ["firestore"]}),sagaMiddleware));
}else{
    middleware = applyMiddleware(thunk,save({ignoreStates: ["firestore"]}),sagaMiddleware)
}
export default createStore(reducer,load(),middleware);


sagaMiddleware.run(rootSagas);
