import React from 'react';
import { Typography } from '@material-ui/core';
import { Flex } from '..';
import { useGlobalStyles } from '../../../hooks';

type Props = {
  unbox?: boolean;
};

const LoadingCover: React.FC<Props> = props => {
  const { absolute, bgLightOpaque, toTop, unbox, trans550 } = useGlobalStyles();
  return (
    <Flex
      className={[absolute, bgLightOpaque, toTop, props.unbox && unbox].join(
        ' ',
      )}
    >
      <Flex className={[trans550].join(' ')}>
        <Typography color="secondary" variant="h2" display="block">
          Loading...
        </Typography>
      </Flex>
    </Flex>
  );
};

LoadingCover.displayName = 'LoadingCover';

export default React.memo(LoadingCover);
