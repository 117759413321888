import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { rrfProps } from "./rrf.config";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { theme } from "./theme";
import { fetchProducts } from "./redux/actions/productActions";

import { Preloader } from "components/molecules";
import App from "./App";
import {render } from "react-dom";
const rootElement = document.getElementById("root");
store.dispatch(fetchProducts());
  render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Preloader />}>
            <App />
          </Suspense>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>,
    rootElement
  );


serviceWorker.unregister();
