
import React from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import useStyle from "./style";
type ButtonProps = {
  color?:
    | "primary"
    | "info"
    | "success"
    | "warning"
    | "danger"
    | "rose"
    | "white"
    | "facebook"
    | "twitter"
    | "google"
    | "github"
    | "transparent";
  round?: any;
  children?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  simple?: any;
  size?: "sm" | "lg";
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  icon?: React.ReactNode;
  className?: any;
  [x:string]: any;

};
const RegularButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      color="primary",
      round,
      children,
      fullWidth,
      disabled,
      simple,
      size="lg",
      block,
      link,
      justIcon,
      icon,
      className,
      ...rest
    } = props;

    const classes = useStyle();

    const btnClasses = classNames({
      [classes.button]: true,
      [classes[size]]: size,
      [classes[color]]: color,
      [classes.round]: round,
      [classes.icon]: icon,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className]: className,
    });
    if(icon){
      return(
        <Button {...rest} ref={ref}>
          <span className={classes.icon}>{icon}</span>
          <span className={classes.text}>{children}</span>
        </Button>
      )
    }
    return (
      <Button {...rest} ref={ref} className={btnClasses}>
        {children}
      </Button>
    );
  }
);
RegularButton.defaultProps={
  color:"primary",
  size:"lg",
  fullWidth:false,
  disabled:false, 
}


export default RegularButton;
