import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { useGlobalStyles } from '../../../hooks';

type Props = {
  active?: boolean;
  activeLabel?: string;
  label?: string;
  icon: React.ReactElement;
};

const CardWidgetIcon: React.FC<Props> = ({
  active,
  activeLabel,
  label,
  icon,
}) => {
  const globalStyles = useGlobalStyles();
  return (
    <Box
      width={30}
      height={30}
      justifyContent="center"
      alignItems="center"
      component="span"
      className={[
        active ? globalStyles.bgPrimary : globalStyles.borderPrimary,
        globalStyles.flex,
        globalStyles.rounded,
        active ? 'active' : '',
      ].join(' ')}
    >
      {label ? (
        <Tooltip
          title={active && activeLabel ? activeLabel : label}
          placement="bottom-end"
          arrow
        >
          {icon}
        </Tooltip>
      ) : (
          icon
        )}
    </Box>
  );
};

CardWidgetIcon.displayName = 'CardWidgetIcon';

export default CardWidgetIcon;
