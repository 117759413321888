import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: {
    height: ({ isMobile }: any) =>
      `calc( env(safe-area-inset-top) + ${isMobile ? 55 : 55}px)`,
    backgroundColor: "transparent",
  },
  mobileHeader: {
    position: 'fixed',
    zIndex: 100,
  },
  header: {
    top: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 100,
  },
}));
