import firebase from 'firebase/app'

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

export function fetchProducts() {
  console.log("fetch productss action")
  return async function(dispatch) {
    console.log("fetch productss")
    dispatch({type: "FETCH_PRODUCTS"});
    const db = firebase.firestore();
    let experiences=[];
    // const Ref = db.collection('/experiences');
    // get only approved experiences 
    const Ref = db.collection('/experiences').where("approved", "==", true);
    Ref.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        experiences.push(doc.data());
      });
      console.log("experiences",experiences)
    })

  dispatch({type: "FETCH_PRODUCTS_FULFILLED", payload:experiences});
  }
}