import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useAuthentification } from "../auth";
import { RootState } from 'redux/reducers/rootReducer';
import { useCallback } from "react";

export const useNotifications = () => {
  const { userId } = useAuthentification();
  const firestore = useFirestore();

  const notifications = useSelector<RootState>(
    (state) => state.firestore?.ordered.notifications
  );
  const addNotification = useCallback(
    async (text:any,title:any,userId:any) => {
      const notifRef =  firestore.collection('/notifications').doc();
      const notif = {
        id:notifRef.id,
        body:text,
        title,
        userId
      }
      notifRef.set(notif);
    },
    [firestore],
  )
  /**
   * TODO
   * Add Notification callback
   * Delete Notification callback
   */
  let data = {};
  // getting data from fake collection to prevent permission warning whenever the user is not logged in because we can't use hooks conditionally
  if (userId) {
    data = {
      collection: "notifications",
      storeAs: "notifications",
      where: [["userId", "==", userId || null]],
    };
  } else {
    data = { collection: "notificationss", storeAs: "notifications" };
  }
  useFirestoreConnect([data]);

  return {
    notifications,
    addNotification
  };
};
