import React from "react";
import {
  Box,
  TextField,
  InputAdornment,
  useTheme,
  InputProps as TInputProps,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

type Props = {
  label: string;
  searchText?: string;
  setSearchText: (searchText: string) => void;
  InputProps?: Partial<TInputProps>;
};

const SearchBar: React.FC<Props> = ({
  label,
  searchText,
  setSearchText,
  InputProps,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Box padding={0} style={{ backgroundColor: theme.palette.common.white }}>
        <TextField
          fullWidth
          placeholder={label}
          color="primary"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            ...InputProps,
          }}
        />
      </Box>
    </Box>
  );
};

SearchBar.displayName = "SearchBar";

export default React.memo(SearchBar);
