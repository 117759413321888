import React from "react";

function Separator() {
  return (
    <hr
      style={{
        color: "#f8b235",
        backgroundColor: "#f8b235",
      }}
    />
  );
}

export default Separator;
