import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { RootState } from "../../redux/reducers/rootReducer";
import { useAuthentification } from "../auth";
type Props = {
  id?: string;
};
export const useBlogs = (props: Props = {}) => {
  const { id } = props;
  const { userId }: any = useAuthentification();
  const blogs = useSelector<RootState>((state) => state.firestore?.data?.blogs);
  const loaded = isLoaded(blogs);
  const blogsList = useSelector<RootState>(
    (state) => state.firestore?.ordered?.blogs || []
  ) as [];

  const searchedBlogs = useCallback(
    (text:any) => {
      return (
        (blogsList as [])?.filter((blog: any) => {
          if (text) {
            // return blog?.title?.toLowerCase()?.startsWith(text.toLowerCase());
            return blog?.title?.toLowerCase()?.includes(text.toLowerCase());
          }
          return false;
        }) ?? []
      );
    },
    [blogsList]
  );

  const travellerBlogs =
    (blogsList as any).filter(
      (blog: any) =>
        blog.storyType.toLowerCase() === "traveller" && blog.isDraft === false
    ) || [];
  const myBlogs =
    (blogsList as any).filter((blog: any) => blog.userId === userId) || [];

  const hostBlogs =
    (blogsList as any).filter(
      (blog: any) =>
        blog.storyType.toLowerCase() === "host" && blog.isDraft === false
    ) || [];
  const blog = useSelector<RootState>(
    (state) =>
      id && state.firestore?.ordered?.blogs?.find((art) => art.id === id)
  );

  let data = {};
  // getting data from fake collection to prevent permission warning whenever the user is not logged in because we can't use hooks conditionally

  data = {
  collection: "blogs", 
  storeAs: "blogs" ,
  orderBy: [["timestamp", "desc"]],
};
  useFirestoreConnect([data]);

  return {
    blog,
    blogsList,
    loaded,
    travellerBlogs,
    hostBlogs,
    searchedBlogs,
    myBlogs,
  };
};
