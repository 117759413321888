import productReducer from "./productReducer";
import chatReducer from './chatReducer';
import cartReducer from "./cartReducer";
import chatsReducer from "./chatSlice";
import currencyReducer from "./currencyReducer";
import wishlistReducer from "./wishlistReducer";
import { combineReducers } from "redux";
import {
  FirebaseReducer,
  firebaseReducer,
  FirestoreReducer,
} from 'react-redux-firebase';
import { configurationReducer } from './configurations/rootReducer';
import { firestoreReducer } from 'redux-firestore';

export type RootState = {
  firebase: FirebaseReducer.Reducer;
  firestore: FirestoreReducer.Reducer;
  configuration: {
    region:
    | {
      id: string;
      name?:string;
      photos?:string[];
      userIds?:string[]
      coords?: string;
      img?: string;
    }
    | null
    | undefined;
    inAppHistory: string[];
  };
};
const rootReducer = combineReducers({
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: cartReducer,
  newMessageCounter : chatReducer,
  chats : chatsReducer,
  wishlistData: wishlistReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  configuration: configurationReducer,
});

export default rootReducer;
