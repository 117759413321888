import React from 'react'
import { ProgressBar } from 'react-rainbow-components';

function ExperienceChallenge({nbrOfExperiences,milestone}) {

    return (
        <div>
        <div>
            <h4 style={{color:"green"}}>
                Create {milestone} approved Experiences
            </h4>
            <span aria-hidden="true">
                <strong style={{display:"flex",flexDirection: "row-reverse"}}>
                    {
                        nbrOfExperiences / milestone >= 1 ? "100% Complete" : ((nbrOfExperiences / milestone) *100).toFixed(2) +" % Complete"
                    }
                </strong>
            </span>
        </div>
        <ProgressBar value={(nbrOfExperiences / milestone) *100} />
    </div>
    )
}

export default ExperienceChallenge
