import { Avatar, Box, makeStyles } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import React, { useRef, useState } from 'react';
import { useGlobalStyles } from '../../../hooks';

type StyleProps = {
  iconSize: number;
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: -4,
  },
  avatar: (props: StyleProps) => ({
    width: props.iconSize,
    height: props.iconSize,
    backgroundColor: theme.palette.secondary.main,
    cursor: 'pointer',
  }),
  icon: (props: StyleProps) => ({
    fontSize: props.iconSize - 14,
  }),
  hiddenInput: {
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
  },
}));

type Props = {
  url: string;
  iconSize: number;
};

const CopyToClipboard: React.FC<Props> = ({ url, iconSize }) => {
  const globalStyles = useGlobalStyles();
  const urlRef = useRef<HTMLInputElement>(null);
  const style = useStyles({ iconSize });
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    if (copied) {
      return;
    }
    if (urlRef && urlRef?.current) {
      urlRef.current.select();
      document.execCommand('copy');
      setCopied(true);
    }
  };

  return (
    <>
      {document.queryCommandSupported('copy') && (
        <Box className={style.container}>
          <Avatar
            className={[style.avatar, globalStyles.m1].join(' ')}
            onClick={copyToClipboard}
          >
            {!copied ? (
              <FileCopyIcon className={style.icon} />
            ) : (
              <DoneIcon className={style.icon} />
            )}
          </Avatar>
          <input
            className={style.hiddenInput}
            ref={urlRef}
            value={url}
            readOnly
          />
        </Box>
      )}
    </>
  );
};

CopyToClipboard.displayName = 'CopyToClipboard';

export default CopyToClipboard;
