// import { fork, takeEvery } from 'redux-saga/effects';
// import { populateSaga } from './populateSaga/populateSaga';

// function* populateMainSaga(action: any) {
//   if (action.meta.collection === 'events') {
//     yield fork(populateSaga, action, 'clubs', 'venueId');
//   }
//   if (action.meta.collection === 'photoAlbums') {
//     yield fork(populateSaga, action, 'users_meta', 'created_by');
//     yield fork(populateSaga, action, 'events', 'eventId');
//   }
// }

export function* rootSagas() {
  // yield takeEvery('@@reduxFirestore/LISTENER_RESPONSE', action =>
  //   populateMainSaga(action),
  // );
  // yield takeEvery('@@reduxFirestore/GET_SUCCESS', action =>
  //   populateMainSaga(action),
  // );
}
